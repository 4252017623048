/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftStatus_enum = "Auction" | "Listed" | "Owned" | "%future added value";
export type Nft_constraint = "Nft_metadataId_key" | "Nft_mint_key" | "Nft_pkey" | "%future added value";
export type Nft_update_column = "creatorId" | "hasBeenSold" | "id" | "isMasterEdition" | "masterEdition" | "metadataId" | "mint" | "ownerId" | "priceInLamports" | "standardEdition" | "status" | "timeCreated" | "%future added value";
export type Photo_constraint = "Photo_pkey" | "%future added value";
export type Photo_update_column = "description" | "id" | "photoUrl" | "timeCreated" | "title" | "userId" | "%future added value";
export type Tag_constraint = "Tag_pkey" | "Tag_value_key" | "%future added value";
export type Tag_update_column = "id" | "value" | "%future added value";
export type User_constraint = "User_coverPhotoId_key" | "User_email_key" | "User_pkey" | "User_profilePhotoId_key" | "User_username_key" | "%future added value";
export type User_update_column = "bio" | "coverPhotoId" | "discordHandle" | "email" | "id" | "instagramName" | "profilePhotoId" | "timeCreated" | "twitterName" | "username" | "websiteUrl" | "%future added value";
export type NftToTag_insert_input = {
    Nft?: Nft_obj_rel_insert_input | null | undefined;
    Tag?: Tag_obj_rel_insert_input | null | undefined;
    nftId?: string | null | undefined;
    tagId?: unknown | null | undefined;
};
export type Nft_obj_rel_insert_input = {
    data: Nft_insert_input;
    on_conflict?: Nft_on_conflict | null | undefined;
};
export type Nft_insert_input = {
    Creator?: User_obj_rel_insert_input | null | undefined;
    creatorId?: string | null | undefined;
    hasBeenSold?: boolean | null | undefined;
    id?: string | null | undefined;
    isMasterEdition?: boolean | null | undefined;
    masterEdition?: string | null | undefined;
    metadataId?: string | null | undefined;
    mint?: string | null | undefined;
    ownerId?: string | null | undefined;
    priceInLamports?: unknown | null | undefined;
    standardEdition?: string | null | undefined;
    status?: NftStatus_enum | null | undefined;
    timeCreated?: unknown | null | undefined;
};
export type User_obj_rel_insert_input = {
    data: User_insert_input;
    on_conflict?: User_on_conflict | null | undefined;
};
export type User_insert_input = {
    CoverPhoto?: Photo_obj_rel_insert_input | null | undefined;
    ProfilePhoto?: Photo_obj_rel_insert_input | null | undefined;
    bio?: string | null | undefined;
    coverPhotoId?: unknown | null | undefined;
    discordHandle?: string | null | undefined;
    email?: string | null | undefined;
    id?: string | null | undefined;
    instagramName?: string | null | undefined;
    profilePhotoId?: unknown | null | undefined;
    timeCreated?: unknown | null | undefined;
    twitterName?: string | null | undefined;
    username?: string | null | undefined;
    websiteUrl?: string | null | undefined;
};
export type Photo_obj_rel_insert_input = {
    data: Photo_insert_input;
    on_conflict?: Photo_on_conflict | null | undefined;
};
export type Photo_insert_input = {
    description?: string | null | undefined;
    id?: unknown | null | undefined;
    photoUrl?: string | null | undefined;
    timeCreated?: unknown | null | undefined;
    title?: string | null | undefined;
    userId?: string | null | undefined;
};
export type Photo_on_conflict = {
    constraint: Photo_constraint;
    update_columns: Array<Photo_update_column>;
    where?: Photo_bool_exp | null | undefined;
};
export type Photo_bool_exp = {
    _and?: Array<Photo_bool_exp> | null | undefined;
    _not?: Photo_bool_exp | null | undefined;
    _or?: Array<Photo_bool_exp> | null | undefined;
    description?: String_comparison_exp | null | undefined;
    id?: uuid_comparison_exp | null | undefined;
    photoUrl?: String_comparison_exp | null | undefined;
    timeCreated?: timestamptz_comparison_exp | null | undefined;
    title?: String_comparison_exp | null | undefined;
    userId?: String_comparison_exp | null | undefined;
};
export type String_comparison_exp = {
    _eq?: string | null | undefined;
    _gt?: string | null | undefined;
    _gte?: string | null | undefined;
    _ilike?: string | null | undefined;
    _in?: Array<string> | null | undefined;
    _iregex?: string | null | undefined;
    _is_null?: boolean | null | undefined;
    _like?: string | null | undefined;
    _lt?: string | null | undefined;
    _lte?: string | null | undefined;
    _neq?: string | null | undefined;
    _nilike?: string | null | undefined;
    _nin?: Array<string> | null | undefined;
    _niregex?: string | null | undefined;
    _nlike?: string | null | undefined;
    _nregex?: string | null | undefined;
    _nsimilar?: string | null | undefined;
    _regex?: string | null | undefined;
    _similar?: string | null | undefined;
};
export type uuid_comparison_exp = {
    _eq?: unknown | null | undefined;
    _gt?: unknown | null | undefined;
    _gte?: unknown | null | undefined;
    _in?: Array<unknown> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: unknown | null | undefined;
    _lte?: unknown | null | undefined;
    _neq?: unknown | null | undefined;
    _nin?: Array<unknown> | null | undefined;
};
export type timestamptz_comparison_exp = {
    _eq?: unknown | null | undefined;
    _gt?: unknown | null | undefined;
    _gte?: unknown | null | undefined;
    _in?: Array<unknown> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: unknown | null | undefined;
    _lte?: unknown | null | undefined;
    _neq?: unknown | null | undefined;
    _nin?: Array<unknown> | null | undefined;
};
export type User_on_conflict = {
    constraint: User_constraint;
    update_columns: Array<User_update_column>;
    where?: User_bool_exp | null | undefined;
};
export type User_bool_exp = {
    CoverPhoto?: Photo_bool_exp | null | undefined;
    ProfilePhoto?: Photo_bool_exp | null | undefined;
    _and?: Array<User_bool_exp> | null | undefined;
    _not?: User_bool_exp | null | undefined;
    _or?: Array<User_bool_exp> | null | undefined;
    bio?: String_comparison_exp | null | undefined;
    coverPhotoId?: uuid_comparison_exp | null | undefined;
    discordHandle?: String_comparison_exp | null | undefined;
    email?: String_comparison_exp | null | undefined;
    id?: String_comparison_exp | null | undefined;
    instagramName?: String_comparison_exp | null | undefined;
    profilePhotoId?: uuid_comparison_exp | null | undefined;
    timeCreated?: timestamptz_comparison_exp | null | undefined;
    twitterName?: String_comparison_exp | null | undefined;
    username?: String_comparison_exp | null | undefined;
    websiteUrl?: String_comparison_exp | null | undefined;
};
export type Nft_on_conflict = {
    constraint: Nft_constraint;
    update_columns: Array<Nft_update_column>;
    where?: Nft_bool_exp | null | undefined;
};
export type Nft_bool_exp = {
    Creator?: User_bool_exp | null | undefined;
    _and?: Array<Nft_bool_exp> | null | undefined;
    _not?: Nft_bool_exp | null | undefined;
    _or?: Array<Nft_bool_exp> | null | undefined;
    creatorId?: String_comparison_exp | null | undefined;
    hasBeenSold?: Boolean_comparison_exp | null | undefined;
    id?: String_comparison_exp | null | undefined;
    isMasterEdition?: Boolean_comparison_exp | null | undefined;
    masterEdition?: String_comparison_exp | null | undefined;
    metadataId?: String_comparison_exp | null | undefined;
    mint?: String_comparison_exp | null | undefined;
    ownerId?: String_comparison_exp | null | undefined;
    priceInLamports?: bigint_comparison_exp | null | undefined;
    standardEdition?: String_comparison_exp | null | undefined;
    status?: NftStatus_enum_comparison_exp | null | undefined;
    timeCreated?: timestamptz_comparison_exp | null | undefined;
};
export type Boolean_comparison_exp = {
    _eq?: boolean | null | undefined;
    _gt?: boolean | null | undefined;
    _gte?: boolean | null | undefined;
    _in?: Array<boolean> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: boolean | null | undefined;
    _lte?: boolean | null | undefined;
    _neq?: boolean | null | undefined;
    _nin?: Array<boolean> | null | undefined;
};
export type bigint_comparison_exp = {
    _eq?: unknown | null | undefined;
    _gt?: unknown | null | undefined;
    _gte?: unknown | null | undefined;
    _in?: Array<unknown> | null | undefined;
    _is_null?: boolean | null | undefined;
    _lt?: unknown | null | undefined;
    _lte?: unknown | null | undefined;
    _neq?: unknown | null | undefined;
    _nin?: Array<unknown> | null | undefined;
};
export type NftStatus_enum_comparison_exp = {
    _eq?: NftStatus_enum | null | undefined;
    _in?: Array<NftStatus_enum> | null | undefined;
    _is_null?: boolean | null | undefined;
    _neq?: NftStatus_enum | null | undefined;
    _nin?: Array<NftStatus_enum> | null | undefined;
};
export type Tag_obj_rel_insert_input = {
    data: Tag_insert_input;
    on_conflict?: Tag_on_conflict | null | undefined;
};
export type Tag_insert_input = {
    id?: unknown | null | undefined;
    value?: string | null | undefined;
};
export type Tag_on_conflict = {
    constraint: Tag_constraint;
    update_columns: Array<Tag_update_column>;
    where?: Tag_bool_exp | null | undefined;
};
export type Tag_bool_exp = {
    _and?: Array<Tag_bool_exp> | null | undefined;
    _not?: Tag_bool_exp | null | undefined;
    _or?: Array<Tag_bool_exp> | null | undefined;
    id?: uuid_comparison_exp | null | undefined;
    value?: String_comparison_exp | null | undefined;
};
export type NftToTag_bool_exp = {
    Nft?: Nft_bool_exp | null | undefined;
    Tag?: Tag_bool_exp | null | undefined;
    _and?: Array<NftToTag_bool_exp> | null | undefined;
    _not?: NftToTag_bool_exp | null | undefined;
    _or?: Array<NftToTag_bool_exp> | null | undefined;
    nftId?: String_comparison_exp | null | undefined;
    tagId?: uuid_comparison_exp | null | undefined;
};
export type ListNftModalMutationVariables = {
    creator: string;
    lister: string;
    mint: string;
    nftToTagObjects: Array<NftToTag_insert_input>;
    price: unknown;
    txid: string;
    deleteFilter: NftToTag_bool_exp;
};
export type ListNftModalMutationResponse = {
    readonly update_Nft_by_pk: {
        readonly id: string;
        readonly creatorId: string;
        readonly mint: string;
        readonly priceInLamports: unknown | null;
        readonly status: NftStatus_enum | null;
    } | null;
    readonly insertNftTransaction: {
        readonly " $fragmentRefs": FragmentRefs<"NftTransaction_NftTransactionExpress">;
    };
    readonly delete_NftToTag: {
        readonly returning: ReadonlyArray<{
            readonly nftId: string;
            readonly tagId: unknown;
        }>;
    } | null;
    readonly insert_NftToTag: {
        readonly returning: ReadonlyArray<{
            readonly nftId: string;
            readonly tagId: unknown;
        }>;
    } | null;
};
export type ListNftModalMutation = {
    readonly response: ListNftModalMutationResponse;
    readonly variables: ListNftModalMutationVariables;
};



/*
mutation ListNftModalMutation(
  $creator: String!
  $lister: String!
  $mint: String!
  $nftToTagObjects: [NftToTag_insert_input!]!
  $price: bigint!
  $txid: String!
  $deleteFilter: NftToTag_bool_exp!
) {
  update_Nft_by_pk(pk_columns: {id: $mint}, _set: {priceInLamports: $price, status: Listed}) {
    id
    creatorId
    mint
    priceInLamports
    status
  }
  insertNftTransaction(input: {creatorId: $creator, fromUserId: $lister, toUserId: $lister, mint: $mint, price: $price, txid: $txid, type: Listed}) {
    ...NftTransaction_NftTransactionExpress
  }
  delete_NftToTag(where: $deleteFilter) {
    returning {
      nftId
      tagId
    }
  }
  insert_NftToTag(objects: $nftToTagObjects) {
    returning {
      nftId
      tagId
    }
  }
}

fragment NftTransaction_NftTransactionExpress on NftTransactionExpress {
  id
  fromAddress
  mint
  priceInLamports
  timeCreated
  toAddress
  txid
  type
  From {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
  To {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lister"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mint"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nftToTagObjects"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "price"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "txid"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mint",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceInLamports",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "priceInLamports",
          "variableName": "price"
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": "Listed"
        }
      ],
      "kind": "ObjectValue",
      "name": "_set"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "mint"
        }
      ],
      "kind": "ObjectValue",
      "name": "pk_columns"
    }
  ],
  "concreteType": "Nft",
  "kind": "LinkedField",
  "name": "update_Nft_by_pk",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorId",
      "storageKey": null
    },
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "creatorId",
        "variableName": "creator"
      },
      {
        "kind": "Variable",
        "name": "fromUserId",
        "variableName": "lister"
      },
      {
        "kind": "Variable",
        "name": "mint",
        "variableName": "mint"
      },
      {
        "kind": "Variable",
        "name": "price",
        "variableName": "price"
      },
      {
        "kind": "Variable",
        "name": "toUserId",
        "variableName": "lister"
      },
      {
        "kind": "Variable",
        "name": "txid",
        "variableName": "txid"
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": "Listed"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NftToTag",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nftId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tagId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "deleteFilter"
    }
  ],
  "concreteType": "NftToTag_mutation_response",
  "kind": "LinkedField",
  "name": "delete_NftToTag",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "objects",
      "variableName": "nftToTagObjects"
    }
  ],
  "concreteType": "NftToTag_mutation_response",
  "kind": "LinkedField",
  "name": "insert_NftToTag",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v15 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Photo",
    "kind": "LinkedField",
    "name": "ProfilePhoto",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ListNftModalMutation",
    "selections": [
      (v10/*: any*/),
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "NftTransactionExpress",
        "kind": "LinkedField",
        "name": "insertNftTransaction",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NftTransaction_NftTransactionExpress"
          }
        ],
        "storageKey": null
      },
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ListNftModalMutation",
    "selections": [
      (v10/*: any*/),
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "NftTransactionExpress",
        "kind": "LinkedField",
        "name": "insertNftTransaction",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromAddress",
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "txid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "From",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "To",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v13/*: any*/),
      (v14/*: any*/)
    ]
  },
  "params": {
    "cacheID": "0ccd588dbbda10b60b09a1642293a1ad",
    "id": null,
    "metadata": {},
    "name": "ListNftModalMutation",
    "operationKind": "mutation",
    "text": "mutation ListNftModalMutation(\n  $creator: String!\n  $lister: String!\n  $mint: String!\n  $nftToTagObjects: [NftToTag_insert_input!]!\n  $price: bigint!\n  $txid: String!\n  $deleteFilter: NftToTag_bool_exp!\n) {\n  update_Nft_by_pk(pk_columns: {id: $mint}, _set: {priceInLamports: $price, status: Listed}) {\n    id\n    creatorId\n    mint\n    priceInLamports\n    status\n  }\n  insertNftTransaction(input: {creatorId: $creator, fromUserId: $lister, toUserId: $lister, mint: $mint, price: $price, txid: $txid, type: Listed}) {\n    ...NftTransaction_NftTransactionExpress\n  }\n  delete_NftToTag(where: $deleteFilter) {\n    returning {\n      nftId\n      tagId\n    }\n  }\n  insert_NftToTag(objects: $nftToTagObjects) {\n    returning {\n      nftId\n      tagId\n    }\n  }\n}\n\nfragment NftTransaction_NftTransactionExpress on NftTransactionExpress {\n  id\n  fromAddress\n  mint\n  priceInLamports\n  timeCreated\n  toAddress\n  txid\n  type\n  From {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n  To {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5518c700f0adb8ad6522059c35773098';
export default node;
