import BidCard from "components/auction/BidCard";
import ListingCardLanding from "components/auction/ListingCardLanding";
import LandingPreHowGeneric from "components/pages/landing/pre/LandingPreHowGeneric";
import ARTIST_PILL_BUTTON_EXAMPLE from "constants/ArtistPillButtonExample";
import styles from "css/pages/landing/pre/LandingPreHow2.module.css";
import useWindowDimensions from "hooks/useWindowDimensions";
import dayjs from "utils/dates/dayjsex";

type Props = {
  isHidden: boolean;
  onNext: () => void;
  onPrev: () => void;
};

export default function LandingPreHow2({
  isHidden,
  onNext,
  onPrev,
}: Props): JSX.Element {
  // TODO: swap out images
  const { width } = useWindowDimensions();

  const bids = [
    <BidCard
      artistName="cococo"
      artistSrc="images/landing/coco.png"
      date={dayjs().subtract(dayjs.duration({ hours: 1 }))}
      key={0}
      price={1.2}
    />,
    <BidCard
      artistName="peufff"
      artistSrc="images/landing/peuff.png"
      date={dayjs().subtract(dayjs.duration({ hours: 2 }))}
      key={1}
      price={0.8}
    />,
    <BidCard
      artistName="moofie"
      artistSrc="images/landing/moofie.png"
      date={dayjs().subtract(dayjs.duration({ hours: 3 }))}
      key={2}
      price={0.6}
    />,
    <BidCard
      artistName="peufff"
      artistSrc="images/landing/peuff.png"
      date={dayjs().subtract(dayjs.duration({ hours: 4 }))}
      key={3}
      price={0.5}
    />,
    <BidCard
      artistName="petrichorate"
      artistSrc="images/landing/katherine-profile.jpg"
      date={dayjs().subtract(dayjs.duration({ hours: 5 }))}
      isListing
      key={4}
      price={0.5}
    />,
  ];

  const bidsSliced = width > 1230 ? bids : bids.slice(0, 2);

  return (
    <LandingPreHowGeneric
      description="List your piece for sale and set a minimum bid price. When the first bid over the minimum price is placed, a 24-hour auction begins."
      isHidden={isHidden}
      onNext={onNext}
      onPrev={onPrev}
      title="2. List your art for auction"
    >
      <div className={styles.body}>
        <ListingCardLanding
          artistPillButton={ARTIST_PILL_BUTTON_EXAMPLE}
          endDate={dayjs().add(
            dayjs.duration({ hours: 23, minutes: 23, seconds: 23 })
          )}
          // TODO: specify width
          imageSrc="images/landing/strawberries.png"
          price={1.2}
          title="Strawberry Afternoon"
        />
        <div className={styles.bids}>{bidsSliced}</div>
      </div>
    </LandingPreHowGeneric>
  );
}
