import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/pages/landing/pre/LandingPreJoin.module.css";
import ImageCloudflare from "components/images/ImageCloudflare";
import Header2 from "components/text/Header2";
import ButtonWithText from "components/buttons/ButtonWithText";
import FontClass from "types/enums/FontClass";
import ButtonTheme from "types/enums/ButtonTheme";
import ArrowRightIcon from "components/icons/ArrowRightIcon";
import ColorValue from "types/enums/ColorValue";
import FORM_LINK from "constants/FormLink";

function Gradient(): JSX.Element {
  return (
    <div className={styles.gradient}>
      <ImageCloudflare
        className={styles.blur}
        src="images/landing/bottom-blur.png"
        height={1307}
        width={1660}
      />
    </div>
  );
}

export default function LandingPreJoin(): JSX.Element {
  return (
    <ResponsiveContainer className={styles.container}>
      <div className={styles.containerInner}>
        <Header2 colorClass={ColorClass.Primary} textAlign="center">
          Join as an artist
        </Header2>
        <Body1
          className={styles.description}
          colorClass={ColorClass.Primary}
          textAlign="center"
        >
          We’re currently in early-access mode, onboarding a few artists at a
          time as we get ready to open up FormFn to collectors. If you’re
          interested in joining FormFn as an artist, let us know by filling out
          the form below so we can review your profile!
        </Body1>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.button}
          fontClass={FontClass.NavLink}
          href={FORM_LINK}
          icon={<ArrowRightIcon colorValue={ColorValue.White} />}
          type="link_external"
        >
          Submit artist profile
        </ButtonWithText>
      </div>
      <Gradient />
    </ResponsiveContainer>
  );
}
