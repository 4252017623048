/* eslint-disable no-restricted-syntax */
import { programs } from "@metaplex/js";
import * as anchor from "@project-serum/anchor";
import FormData from "form-data";
import { Connection } from "@solana/web3.js";
import { ARWEAVE_PAYMENT_WALLET } from "constants/SolanaConstants";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import getFileExt from "utils/getFileExt";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { notify } from "components/toast/notifications";
import ExternalLink from "components/link/ExternalLink";
import logIfNotProd from "utils/logIfNotProd";
import sendTransactionWithWallet from "utils/solana/misc/sendTransactionWithWallet";
import getAssetCostToStore from "utils/solana/metaplex/getAssetCostToStore";

const ARWEAVE_UPLOAD_ENDPOINT =
  "https://us-central1-metaplex-studios.cloudfunctions.net/uploadFile";

// Determined heuristically... stuff was failing below this number
const MIN_LAMPORTS = 22000;

async function upload(data: FormData) {
  return (
    await fetch(ARWEAVE_UPLOAD_ENDPOINT, {
      method: "POST",
      // @ts-ignore
      body: data,
    })
  ).json();
}

function estimateManifestSize(filenames: string[]) {
  const paths: { [key: string]: any } = {};

  for (const name of filenames) {
    paths[name] = {
      // TODO: no idea what this does
      id: "artestaC_testsEaEmAGFtestEGtestmMGmgMGAV438",
      ext: name.split(".")[1],
    };
  }

  const manifest = {
    manifest: "arweave/paths",
    version: "0.1.0",
    paths,
    index: {
      path: "metadata.json",
    },
  };

  const data = Buffer.from(JSON.stringify(manifest), "utf8");
  return data.length;
}

export default async function arweaveUpload(
  wallet: AnchorWallet,
  connection: Connection,
  env: WalletAdapterNetwork,
  image: File,
  metadataFile: File
) {
  const estimatedManifestSize = estimateManifestSize([
    "image.png",
    "metadata.json",
  ]);
  const storageCost =
    (await getAssetCostToStore([
      image.size,
      metadataFile.size,
      estimatedManifestSize,
    ])) * 2;
  logIfNotProd(`lamport cost to store files: ${storageCost}`);

  const instructions = [
    anchor.web3.SystemProgram.transfer({
      fromPubkey: wallet.publicKey,
      toPubkey: ARWEAVE_PAYMENT_WALLET,
      lamports: storageCost < MIN_LAMPORTS ? MIN_LAMPORTS : storageCost,
    }),
  ];

  const tx = new programs.Transaction();
  tx.add(...instructions);
  const txid = await sendTransactionWithWallet({
    connection,
    wallet,
    txs: [tx],
    signers: [],
    options: { preflightCommitment: "max" },
  });
  if (txid == null) {
    throw new Error("Failed to transfer SOL for Arweave upload");
  }
  logIfNotProd(`solana transaction (${env}) for arweave payment: ${txid}`);
  notify({ message: "Paid SOL for Arweave upload", txid });
  await connection.getParsedConfirmedTransaction(txid, "confirmed");

  const data = new FormData();
  data.append("transaction", txid);
  data.append("env", env);
  data.append(
    "file[]",
    new File([image], `image.${getFileExt(image)}`, { type: image.type })
  );
  data.append("file[]", metadataFile);

  const result = await upload(data);
  logIfNotProd("upload result", result);

  const manifestResult = result.messages?.find(
    (m: any) => m.filename === "manifest.json"
  );

  if (manifestResult?.transactionId) {
    const link = `https://arweave.net/${manifestResult.transactionId}`;
    logIfNotProd(`File uploaded: ${link}`);

    const description = <ExternalLink href={link}>{link}</ExternalLink>;
    notify({ message: "Uploaded files to Arweave", description });
    return link;
  }

  notify({ message: "Failed uploading files to Arweave", type: "error" });
  throw new Error("No transaction ID for upload");
}
