import Header from "components/header/pre/Header";
import LandingPreEco from "components/pages/landing/pre/LandingPreEco";
import LandingPreFooter from "components/pages/landing/pre/LandingPreFooter";
import LandingPreHero from "components/pages/landing/pre/LandingPreHero";
import LandingPreHow from "components/pages/landing/pre/LandingPreHow";
import LandingPreJoin from "components/pages/landing/pre/LandingPreJoin";
import LandingPreMadeFor from "components/pages/landing/pre/LandingPreMadeFor";

export default function LandingPrePage(): JSX.Element {
  return (
    <div>
      <Header />
      <LandingPreHero />
      <LandingPreMadeFor />
      <LandingPreEco />
      <LandingPreHow />
      <LandingPreJoin />
      <LandingPreFooter />
    </div>
  );
}
