import {
  ProfilePageForUser_User,
  ProfilePageForUser_User$key,
} from "components/pages/profile/__generated__/ProfilePageForUser_User.graphql";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import HeaderAndCoverPhoto from "components/header/HeaderAndCoverPhoto";
import styles from "css/pages/profile/ProfilePageForUser.module.css";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Header2 from "components/text/Header2";
import ShadowButton from "components/buttons/ShadowButton";
import Twitter20Icon from "components/icons/Twitter20Icon";
import ColorValue from "types/enums/ColorValue";
import Instagram20Icon from "components/icons/Instagram20Icon";
import CopyAddressButton from "components/buttons/CopyAddressButton";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import { Maybe } from "types/UtilityTypes";
import LandingPreFooter from "components/pages/landing/pre/LandingPreFooter";
import ProfileNfts from "components/pages/profile/ProfileNfts";
import useSolanaContext from "hooks/useSolanaContext";

const fragment = graphql`
  fragment ProfilePageForUser_User on User {
    id

    bio
    discordHandle
    instagramName
    twitterName
    username
    websiteUrl

    CoverPhoto {
      id
      photoUrl
    }

    ProfilePhoto {
      id
      photoUrl
    }

    ...ProfileNfts_User
  }
`;

function Row1({
  userData,
}: {
  userData: ProfilePageForUser_User;
}): JSX.Element {
  const { anchorWallet } = useSolanaContext();

  return (
    <div className={styles.row1}>
      <div className={styles.row1Right}>
        <Header2>{userData.username}</Header2>
        <div className={styles.shadowButtons}>
          {userData.twitterName != null && (
            <ShadowButton
              className={styles.shadowButtonSocial}
              href={`https://twitter.com/${userData.twitterName}`}
              type="link_external"
            >
              <Twitter20Icon colorValue={ColorValue.Secondary} />
            </ShadowButton>
          )}
          {userData.instagramName != null && (
            <ShadowButton
              className={styles.shadowButtonSocial}
              href={`https://instagram.com/${userData.instagramName}`}
              type="link_external"
            >
              <Instagram20Icon colorValue={ColorValue.Secondary} />
            </ShadowButton>
          )}
          <CopyAddressButton address={userData.id} />
        </div>
      </div>
      {userData.id === anchorWallet?.publicKey.toString() && (
        <ButtonWithText
          buttonTheme={ButtonTheme.BrightPurpleOutline}
          fontClass={FontClass.NavLink}
          href="/profile/edit"
          type="link_internal"
        >
          Edit Profile
        </ButtonWithText>
      )}
    </div>
  );
}

function Row2({
  userData,
}: {
  userData: ProfilePageForUser_User;
}): Maybe<JSX.Element> {
  return userData.bio != null ? (
    <Body1 className={styles.row2} colorClass={ColorClass.Secondary}>
      {userData.bio}
    </Body1>
  ) : null;
}

type Props = {
  user: ProfilePageForUser_User$key;
};

export default function ProfilePageForUser({ user }: Props): JSX.Element {
  const userData = useFragment(fragment, user);

  return (
    <div className={styles.container}>
      <HeaderAndCoverPhoto src={userData.CoverPhoto?.photoUrl} />
      <div className={styles.containerInner}>
        <ResponsiveContainer>
          <div className={styles.profilePhotoContainer}>
            {userData.ProfilePhoto?.photoUrl == null ? (
              <div className={styles.profilePhoto} />
            ) : (
              <img
                className={styles.profilePhoto}
                src={userData.ProfilePhoto.photoUrl}
              />
            )}
          </div>
          <Row1 userData={userData} />
          <Row2 userData={userData} />
          <div className={styles.nfts}>
            <ProfileNfts user={userData} />
          </div>
        </ResponsiveContainer>
      </div>
      <LandingPreFooter />
    </div>
  );
}
