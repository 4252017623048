import ButtonWithText from "components/buttons/ButtonWithText";
import ConnectWalletModal from "components/modal/ConnectWalletModal";
import useSolanaContext from "hooks/useSolanaContext";
import { useState } from "react";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import { message } from "components/toast/messages";
import AccountSetupModalContainer from "components/modal/AccountSetupModalContainer";
import getSignature from "utils/local-storage/getSignature";

export default function ConnectWalletButton(): JSX.Element {
  const { anchorWallet, setAnchorWallet } = useSolanaContext();
  const [isModalShown, setIsModalShown] = useState(false);
  const existingSignature =
    anchorWallet == null
      ? null
      : getSignature(anchorWallet.publicKey.toString());

  if (anchorWallet != null && existingSignature != null) {
    return (
      <>
        <AccountSetupModalContainer />
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          fontClass={FontClass.NavLink}
          onClick={() => {
            anchorWallet.disconnect();
            setAnchorWallet(null);
            message({ content: "Wallet disconnected" });
          }}
        >
          Disconnect
        </ButtonWithText>
      </>
    );
  }

  // return <WalletDialogButton>Connect Wallet</WalletDialogButton>;
  return (
    <>
      <ConnectWalletModal
        isShown={isModalShown}
        onHide={() => setIsModalShown(false)}
      />
      <ButtonWithText
        buttonTheme={ButtonTheme.PurpleGradient}
        fontClass={FontClass.NavLink}
        onClick={() => setIsModalShown(true)}
      >
        Sign In
      </ButtonWithText>
    </>
  );
}
