import TextButton from "components/buttons/TextButton";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Body1 from "components/text/Body1";
import Header2 from "components/text/Header2";
import styles from "css/pages/landing/pre/LandingPreEco.module.css";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";
import Marquee from "react-fast-marquee";
import ImageCloudflare from "components/images/ImageCloudflare";
import joinClasses from "utils/joinClasses";
import GlobalClass from "types/enums/GlobalClass";
import { range } from "utils/range";

const SRCS = range(12).map((num) => `images/landing/marquee/${num + 1}.png`);

function MarqueeSection(): JSX.Element {
  return (
    <Marquee gradient={false} speed={55}>
      {/* Repeat for large screens (to avoid empty space) */}
      {[...SRCS, ...SRCS].map((value, index) => (
        <ImageCloudflare
          className={joinClasses(styles.image, GlobalClass.HideText)}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          // src={`${CDN_CGI}/width=600,f=auto/${value}`}
          src={value}
        />
      ))}
    </Marquee>
  );
}

export default function LandingPreEco(): JSX.Element {
  return (
    <div>
      <ResponsiveContainer>
        <div className={styles.info}>
          <Header2 colorClass={ColorClass.Primary} textAlign="center">
            The best parts of digital art, without the environmental footprint
          </Header2>
          <Body1
            className={styles.description}
            colorClass={ColorClass.Primary}
            textAlign="center"
          >
            FormFn was created specifically for the Solana ecosystem. One
            transaction on Solana uses less energy than{" "}
            <TextButton
              buttonTheme={TextButtonTheme.Primary}
              display="inline"
              fontClass={FontClass.Body1}
              textDecoration="underline"
            >
              2 Google searches
            </TextButton>
            —so you can focus on the art, instead of your carbon footprint.
          </Body1>
        </div>
      </ResponsiveContainer>
      <MarqueeSection />
    </div>
  );
}
