/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActivityTransactionsInput = {
    creator?: unknown | null | undefined;
    from?: unknown | null | undefined;
    to?: unknown | null | undefined;
};
export type ActivityTransactionsQueryVariables = {
    input: ActivityTransactionsInput;
};
export type ActivityTransactionsQueryResponse = {
    readonly activityTransactions: ReadonlyArray<{
        readonly id: unknown;
        readonly " $fragmentRefs": FragmentRefs<"ActivityTransaction_ActivityTransaction">;
    }>;
};
export type ActivityTransactionsQuery = {
    readonly response: ActivityTransactionsQueryResponse;
    readonly variables: ActivityTransactionsQueryVariables;
};



/*
query ActivityTransactionsQuery(
  $input: ActivityTransactionsInput!
) {
  activityTransactions(input: $input) {
    id
    ...ActivityTransaction_ActivityTransaction
  }
}

fragment ActivityTransaction_ActivityTransaction on ActivityTransaction {
  id
  price
  timeCreated
  txid
  type
  metadataAccount {
    id
    mint
    data {
      name
    }
    offchainData {
      description
      image
    }
  }
  From {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
  To {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Photo",
    "kind": "LinkedField",
    "name": "ProfilePhoto",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityTransactionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivityTransaction",
        "kind": "LinkedField",
        "name": "activityTransactions",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActivityTransaction_ActivityTransaction"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivityTransactionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivityTransaction",
        "kind": "LinkedField",
        "name": "activityTransactions",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "txid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MetadataAccount",
            "kind": "LinkedField",
            "name": "metadataAccount",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MetadataAccountData",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MetadataOffchain",
                "kind": "LinkedField",
                "name": "offchainData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "From",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "To",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6246bb61f5c5ad6ea15b65d8e5b29fac",
    "id": null,
    "metadata": {},
    "name": "ActivityTransactionsQuery",
    "operationKind": "query",
    "text": "query ActivityTransactionsQuery(\n  $input: ActivityTransactionsInput!\n) {\n  activityTransactions(input: $input) {\n    id\n    ...ActivityTransaction_ActivityTransaction\n  }\n}\n\nfragment ActivityTransaction_ActivityTransaction on ActivityTransaction {\n  id\n  price\n  timeCreated\n  txid\n  type\n  metadataAccount {\n    id\n    mint\n    data {\n      name\n    }\n    offchainData {\n      description\n      image\n    }\n  }\n  From {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n  To {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '500a0981f9dbebe7ed7208e1b988b4e8';
export default node;
