import { web3 } from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";
import {
  AUCTION_HOUSE_AUTHORITY,
  AUCTION_HOUSE_FORMFN,
} from "constants/SolanaConstants";
import AuctionHouseProgram from "types/AuctionHouseProgram";
import logIfNotProd from "utils/logIfNotProd";
import getSellerFreeTradeState from "utils/solana/auction-house/getSellerFreeTradeState";
import getTradeState from "utils/solana/auction-house/getTradeState";
import findAuctionHouseFeeAccount from "utils/solana/pdas/findAuctionHouseFeeAccount";
import findAuctionHouseProgramAsSigner from "utils/solana/pdas/findAuctionHouseProgramAsSigner";
import findTokenMetadata from "utils/solana/pdas/findTokenMetadata";

export default async function auctionHouseSell(
  program: AuctionHouseProgram,
  walletSeller: PublicKey,
  tokenAccount: PublicKey,
  tokenMint: PublicKey,
  priceInLamports: number
) {
  const [tradeState, tradeBump, buyPriceAdjusted] = await getTradeState(
    walletSeller,
    tokenAccount,
    tokenMint,
    priceInLamports
  );
  const [programAsSigner, programAsSignerBump] =
    await findAuctionHouseProgramAsSigner();
  const [feeAccount] = await findAuctionHouseFeeAccount(AUCTION_HOUSE_FORMFN);
  const [metadata] = await findTokenMetadata(tokenMint);
  const [freeTradeState, freeTradeBump] = await getSellerFreeTradeState(
    walletSeller,
    tokenAccount,
    tokenMint
  );

  logIfNotProd("calling program.rpc.sell...");
  const txid = await program.rpc.sell(
    tradeBump,
    freeTradeBump,
    programAsSignerBump,
    buyPriceAdjusted,
    new BN(1),
    {
      accounts: {
        wallet: walletSeller,
        metadata,
        tokenAccount,
        authority: AUCTION_HOUSE_AUTHORITY,
        auctionHouse: AUCTION_HOUSE_FORMFN,
        auctionHouseFeeAccount: feeAccount,
        sellerTradeState: tradeState,
        freeSellerTradeState: freeTradeState,
        tokenMint,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: web3.SystemProgram.programId,
        programAsSigner,
        rent: web3.SYSVAR_RENT_PUBKEY,
      },
      signers: [],
    }
  );
  logIfNotProd("finished calling program.rpc.sell!");

  return txid;
}
