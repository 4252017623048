/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfilePageDynamicQueryVariables = {
    username: string;
};
export type ProfilePageDynamicQueryResponse = {
    readonly User: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"ProfilePageForUser_User">;
    }>;
};
export type ProfilePageDynamicQuery = {
    readonly response: ProfilePageDynamicQueryResponse;
    readonly variables: ProfilePageDynamicQueryVariables;
};



/*
query ProfilePageDynamicQuery(
  $username: String!
) {
  User(where: {username: {_eq: $username}}) {
    id
    ...ProfilePageForUser_User
  }
}

fragment NftsForAddress_User on User {
  id
}

fragment ProfileNfts_User on User {
  id
  ...NftsForAddress_User
}

fragment ProfilePageForUser_User on User {
  id
  bio
  discordHandle
  instagramName
  twitterName
  username
  websiteUrl
  CoverPhoto {
    id
    photoUrl
  }
  ProfilePhoto {
    id
    photoUrl
  }
  ...ProfileNfts_User
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "username"
          }
        ],
        "kind": "ObjectValue",
        "name": "username"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "photoUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfilePageDynamicQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "User",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfilePageForUser_User"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfilePageDynamicQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "User",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discordHandle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instagramName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "twitterName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "websiteUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Photo",
            "kind": "LinkedField",
            "name": "CoverPhoto",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Photo",
            "kind": "LinkedField",
            "name": "ProfilePhoto",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38d40a9b4e55c4ce19b2fc704599b8a5",
    "id": null,
    "metadata": {},
    "name": "ProfilePageDynamicQuery",
    "operationKind": "query",
    "text": "query ProfilePageDynamicQuery(\n  $username: String!\n) {\n  User(where: {username: {_eq: $username}}) {\n    id\n    ...ProfilePageForUser_User\n  }\n}\n\nfragment NftsForAddress_User on User {\n  id\n}\n\nfragment ProfileNfts_User on User {\n  id\n  ...NftsForAddress_User\n}\n\nfragment ProfilePageForUser_User on User {\n  id\n  bio\n  discordHandle\n  instagramName\n  twitterName\n  username\n  websiteUrl\n  CoverPhoto {\n    id\n    photoUrl\n  }\n  ProfilePhoto {\n    id\n    photoUrl\n  }\n  ...ProfileNfts_User\n}\n"
  }
};
})();
(node as any).hash = 'c9f59fae61bae95f1bce3a9e89a7d1f6';
export default node;
