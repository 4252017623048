/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftStatus_enum = "Auction" | "Listed" | "Owned" | "%future added value";
export type NftActionButton_MetadataAccount = {
    readonly id: string;
    readonly nft: {
        readonly id: string;
        readonly isOffPlatform: boolean;
        readonly ownerId: string;
        readonly status: NftStatus_enum;
    };
    readonly " $fragmentRefs": FragmentRefs<"BidButton_MetadataAccount" | "ListNftButton_MetadataAccount">;
    readonly " $refType": "NftActionButton_MetadataAccount";
};
export type NftActionButton_MetadataAccount$data = NftActionButton_MetadataAccount;
export type NftActionButton_MetadataAccount$key = {
    readonly " $data"?: NftActionButton_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NftActionButton_MetadataAccount">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NftActionButton_MetadataAccount",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NftExpress",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOffPlatform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BidButton_MetadataAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ListNftButton_MetadataAccount"
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
})();
(node as any).hash = 'a1fef6441ee54b7d3996602e2ea4e651';
export default node;
