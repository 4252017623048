import ImageCloudflare from "components/images/ImageCloudflare";
import TextInputForDisplay from "components/input/TextInputForDisplay";
import LandingPreHowGeneric from "components/pages/landing/pre/LandingPreHowGeneric";
import styles from "css/pages/landing/pre/LandingPreHow1.module.css";

type Props = {
  isHidden: boolean;
  onNext: () => void;
};

export default function LandingPreHow1({
  isHidden,
  onNext,
}: Props): JSX.Element {
  return (
    <LandingPreHowGeneric
      // TODO: may want to adjust this copy if we decide to eat rent fees
      description="When you mint a new piece of art on FormFn, you can easily add all the details right on our platform. Since we use the Solana blockchain, minting an NFT only costs a couple of dollars."
      isHidden={isHidden}
      onNext={onNext}
      title="1. Mint your artwork"
    >
      <div className={styles.body}>
        <ImageCloudflare
          className={styles.image}
          // TODO: specify width
          src="images/landing/strawberries.png"
        />
        <div className={styles.inputs}>
          <TextInputForDisplay label="Name" value="Strawberry Afternoon" />
          <TextInputForDisplay
            label="Description"
            value="This piece inspired by sunny afternoons, berry-picking in the summer, and the feeling that all of time stops for a while when you’re outdoors."
          />
          <TextInputForDisplay label="Royalties" value="10%" />
        </div>
      </div>
    </LandingPreHowGeneric>
  );
}
