import CDN_CGI from "constants/CdnCgi";
import { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import GlobalClass from "types/enums/GlobalClass";
import deleteProperty from "utils/deleteProperty";
import joinClasses from "utils/joinClasses";

type Props = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  mediaAndSrcSet?: Array<{
    media: string;
    srcSet: string;
  }>;
  pictureClassName?: string;
};

export default function ImageCloudflare(props: Props): JSX.Element {
  if (!props.src?.includes(CDN_CGI)) {
    return (
      <img
        className={joinClasses(props.className, GlobalClass.HideText)}
        src={props.src}
      />
    );
  }

  // Image resizing doesn't work on localhost
  const prefix = "https://formfn.xyz/";

  return (
    <picture className={props.pictureClassName}>
      {(props.mediaAndSrcSet ?? []).map(({ media, srcSet }) => (
        <source key={srcSet} media={media} srcSet={prefix + srcSet} />
      ))}
      <img
        {...deleteProperty(props, "mediaAndSrcSet")}
        className={joinClasses(props.className, GlobalClass.HideText)}
        src={prefix + props.src}
      />
    </picture>
  );
}
