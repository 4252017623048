import TextButton from "components/buttons/TextButton";
import ArrowRightIcon from "components/icons/ArrowRightIcon";
import LandingPreMadeForGeneric from "components/pages/landing/pre/LandingPreMadeForGeneric";
import CDN_CGI from "constants/CdnCgi";
import FORM_LINK from "constants/FormLink";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";

export default function LandingPreMadeForArtists(): JSX.Element {
  const description1 =
    "FormFn is a platform for creatives, by creatives. We believe that your art deserves the same attention from collectors as big generative NFT projects, and we want FormFn to be the best NFT marketplace on Solana for independent artists and creators.";
  const description2 =
    "To join FormFn, we ask for a short artist profile submission—this helps us keep FormFn safe, trustworthy, and high-quality. Once you join FormFn, you’ll be able to mint NFTs, put pieces up for auction, and connect with other artists and collectors.";
  const src = `${CDN_CGI}/width=1500px,f=auto/images/landing/creator-screen.jpg`;
  const title1 = "What is FormFn?";
  const title2 = "Joining FormFn";

  return (
    <LandingPreMadeForGeneric
      ctaButton={
        <TextButton
          buttonTheme={TextButtonTheme.PurpleGradient}
          fontClass={FontClass.NavLink}
          href={FORM_LINK}
          icon={<ArrowRightIcon colorValue={ColorValue.BrightPurple} />}
          iconPosition="right"
          type="link_external"
        >
          Submit your artist profile
        </TextButton>
      }
      description1={description1}
      description2={description2}
      src={src}
      title1={title1}
      title2={title2}
    />
  );
}
