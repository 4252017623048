import ShadowButton from "components/buttons/ShadowButton";
import Copy20Icon from "components/icons/Copy20Icon";
import Body2 from "components/text/Body2";
import { message } from "components/toast/messages";
import styles from "css/buttons/CopyAddressButton.module.css";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import shortenAddress from "utils/shortenAddress";

type Props = {
  address: string;
};

export default function CopyAddressButton({ address }: Props): JSX.Element {
  return (
    <ShadowButton
      className={styles.button}
      onClick={() => {
        // See https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
        navigator.clipboard.writeText(address);
        message({ content: "Copied to clipboard" });
      }}
      type="button"
    >
      <Body2 colorClass={ColorClass.Secondary}>{shortenAddress(address)}</Body2>
      <Copy20Icon colorValue={ColorValue.Secondary} />
    </ShadowButton>
  );
}
