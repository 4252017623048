import ButtonWithText from "components/buttons/ButtonWithText";
import InputLabel from "components/input/InputLabel";
import InputWithLabel from "components/input/InputWithLabel";
import TextInput from "components/input/TextInput";
import GenericModal from "components/modal/GenericModal";
import Body1 from "components/text/Body1";
import Header2 from "components/text/Header2";
import styles from "css/modal/AccountSetupModal.module.css";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import { useState } from "react";
import isValidUsername from "utils/validation/isValidUsername";
import WEBSITE_URL from "constants/WebsiteUrl";
import graphql from "babel-plugin-relay/macro";
import useSolanaContext from "hooks/useSolanaContext";
import { useMutation } from "react-relay";
import { AccountSetupModalUserMutation } from "components/modal/__generated__/AccountSetupModalUserMutation.graphql";
import ErrorMessage from "components/text/ErrorMessage";
import useErrorMessage from "hooks/useErrorMessage";
import ErrorMessageMsg from "types/enums/ErrorMessageMsg";
import isValidEmail from "utils/validation/isValidEmail";
import { MAX_EMAIL_LENGTH, MAX_USERNAME_LENGTH } from "constants/MaxLengths";
import { EMAIL_SUB_LABEL, USERNAME_SUB_LABEL } from "constants/InputSubLabels";

const mutation = graphql`
  mutation AccountSetupModalUserMutation(
    $email: String!
    $id: String!
    $username: String!
  ) {
    insert_User_one(object: { email: $email, id: $id, username: $username }) {
      email
      id
      username
    }
  }
`;

type Props = {
  isShown: boolean;
  onHide: () => void;
};

export default function AccountSetupModal({
  isShown,
  onHide,
}: Props): JSX.Element {
  const [errorMessage, setErrorMessage] = useErrorMessage();
  const [email, setEmail] = useState("");
  const [touched, setTouched] = useState<boolean>(false);
  const [username, setUsername] = useState("");
  const { anchorWallet } = useSolanaContext();
  const [commit] = useMutation<AccountSetupModalUserMutation>(mutation);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <GenericModal hideCloseButton isShown={isShown} onHide={onHide}>
      <div className={styles.body}>
        <Header2 colorClass={ColorClass.Primary} textAlign="center">
          Welcome to FormFn!
        </Header2>
        <Body1
          className={styles.description}
          colorClass={ColorClass.Secondary}
          textAlign="center"
        >
          To set up your account, we just need you to choose a username and
          enter your email. You can always edit this info later.
        </Body1>
        <div className={styles.inputs}>
          <InputWithLabel
            input={
              <TextInput
                className={styles.usernameInput}
                hasError={touched && !isValidUsername(username)}
                value={username}
                maxLength={MAX_USERNAME_LENGTH}
                onChange={(val) => {
                  setUsername(val);
                  setErrorMessage(null);
                }}
                permaPlaceholder={WEBSITE_URL}
              />
            }
            label={
              <InputLabel
                label="Username"
                subLabel={USERNAME_SUB_LABEL}
                required
              />
            }
          />
          <InputWithLabel
            input={
              <TextInput
                hasError={touched && !isValidEmail(email)}
                value={email}
                maxLength={MAX_EMAIL_LENGTH}
                maxLengthIndicator={false}
                onChange={(val) => {
                  setEmail(val);
                  setErrorMessage(null);
                }}
              />
            }
            label={
              <InputLabel label="Email" subLabel={EMAIL_SUB_LABEL} required />
            }
          />
        </div>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.button}
          fontClass={FontClass.NavLink}
          showLoadingSpinner={isLoading}
          onClick={() => {
            setTouched(true);

            if (!isValidEmail(email)) {
              setErrorMessage(ErrorMessageMsg.InvalidEmail);
              return;
            }

            if (!isValidUsername(username)) {
              setErrorMessage(ErrorMessageMsg.InvalidUsername);
              return;
            }

            setIsLoading(true);
            // TODO: modal should disappear on success. Use updater?
            commit({
              variables: {
                id: anchorWallet!.publicKey.toString(),
                email,
                username,
              },
              onCompleted: () => {
                setIsLoading(false);
              },
              onError: () => {
                setIsLoading(false);
                setErrorMessage(ErrorMessageMsg.UnexpectedError);
              },
              updater: (store) => {
                const publicKey = anchorWallet!.publicKey.toString();
                const record = store.get(publicKey);
                const root = store.getRoot();
                if (record != null) {
                  root.setLinkedRecord(record, "User_by_pk", {
                    id: publicKey,
                  });
                }
              },
            });
          }}
        >
          Start exploring FormFn
        </ButtonWithText>
        {errorMessage && (
          <ErrorMessage fontClass={FontClass.Body1}>
            {errorMessage}
          </ErrorMessage>
        )}
      </div>
    </GenericModal>
  );
}
