import graphql from "babel-plugin-relay/macro";
import LoadingSpinner from "components/loading/LoadingSpinner";
import NftsForAddress from "components/pages/profile/NftsForAddress";
import { ProfileNfts_User$key } from "components/pages/profile/__generated__/ProfileNfts_User.graphql";
import styles from "css/pages/profile/ProfileNfts.module.css";
import { Suspense } from "react";
import { useFragment } from "react-relay";
import ColorValue from "types/enums/ColorValue";

const fragment = graphql`
  fragment ProfileNfts_User on User {
    id

    ...NftsForAddress_User
  }
`;

type Props = {
  user: ProfileNfts_User$key;
};

export default function ProfileNfts({ user }: Props): JSX.Element {
  const userData = useFragment(fragment, user);

  return (
    <div>
      <div className={styles.grid}>
        <Suspense
          fallback={
            <LoadingSpinner
              colorValue={ColorValue.BrightPurple}
              height={40}
              width={40}
            />
          }
        >
          <NftsForAddress address={userData.id} user={userData} />
        </Suspense>
      </div>
    </div>
  );
}
