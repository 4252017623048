import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import graphql from "babel-plugin-relay/macro";
import ButtonWithText from "components/buttons/ButtonWithText";
import InputLabel from "components/input/InputLabel";
import InputWithLabel from "components/input/InputWithLabel";
import PriceInput from "components/input/PriceInput";
import GenericModal from "components/modal/GenericModal";
import { ChangePriceModalMutation } from "components/modal/__generated__/ChangePriceModalMutation.graphql";
import { ChangePriceModal_MetadataAccount$key } from "components/modal/__generated__/ChangePriceModal_MetadataAccount.graphql";
import { notify } from "components/toast/notifications";
import styles from "css/modal/ChangePriceModal.module.css";
import useSolanaContext from "hooks/useSolanaContext";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import invariant from "tiny-invariant";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import logIfNotProd from "utils/logIfNotProd";
import getNftExpressDataId from "utils/relay/getNftExpressDataId";
import addNftTransaction from "utils/relay/updaters/addNftTransaction";
import auctionHouseSell from "utils/solana/auction-house/auctionHouseSell";
import findAta from "utils/solana/pdas/findAta";

const mutation = graphql`
  mutation ChangePriceModalMutation(
    $creator: String!
    $lister: String!
    $mint: String!
    $price: bigint!
    $txid: String!
  ) {
    update_Nft_by_pk(
      pk_columns: { id: $mint }
      _set: { priceInLamports: $price, status: Listed }
    ) {
      id
      creatorId
      mint
      priceInLamports
      status
    }

    insertNftTransaction(
      input: {
        creatorId: $creator
        fromUserId: $lister
        toUserId: $lister
        mint: $mint
        price: $price
        txid: $txid
        type: Listed
      }
    ) {
      ...NftTransaction_NftTransactionExpress
    }
  }
`;

const fragment = graphql`
  fragment ChangePriceModal_MetadataAccount on MetadataAccount {
    id
    mint

    nft {
      creatorId
    }
  }
`;

type Props = {
  isShown: boolean;
  metadataAccount: ChangePriceModal_MetadataAccount$key;
  onHide: () => void;
};

export default function ChangePriceModal({
  isShown,
  metadataAccount,
  onHide,
}: Props): JSX.Element {
  const [commit] = useMutation<ChangePriceModalMutation>(mutation);
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const [price, setPrice] = useState("");
  const { anchorWallet, auctionHouseProgram } = useSolanaContext();
  const [isLoading, setIsLoading] = useState(false);

  const priceInput = (
    <InputWithLabel
      label={<InputLabel label="Reserve price" />}
      input={
        <PriceInput
          placeholder="Enter the new price (in SOL)"
          priceInSol={price}
          setPrice={setPrice}
        />
      }
    />
  );

  return (
    <GenericModal isShown={isShown} onHide={onHide} title="Change price">
      <div className={styles.body}>
        {priceInput}
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.submitButton}
          fontClass={FontClass.NavLink}
          showLoadingSpinner={isLoading}
          onClick={async () => {
            invariant(anchorWallet != null);
            invariant(auctionHouseProgram != null);

            setIsLoading(true);
            const mintKey = new PublicKey(metadataAccountData.mint as string);
            const [ata] = await findAta(anchorWallet.publicKey, mintKey);
            const priceInLamports = Number(price) * LAMPORTS_PER_SOL;
            try {
              // TODO: should also cancel existing listing to get funds back
              const txid = await auctionHouseSell(
                auctionHouseProgram,
                anchorWallet.publicKey,
                ata,
                mintKey,
                priceInLamports
              );

              commit({
                variables: {
                  creator: metadataAccountData.nft.creatorId,
                  lister: anchorWallet.publicKey.toString(),
                  mint: mintKey.toString(),
                  price: priceInLamports,
                  txid,
                },
                onCompleted: () => {
                  notify({ message: "Successfully changed price!", txid });
                  setIsLoading(false);
                  setPrice("");
                  onHide();
                },
                onError: (e) => {
                  logIfNotProd("error changing price (graphql)", e);
                  notify({
                    message:
                      "An unexpected error occurred while changing the price",
                    type: "error",
                  });
                },
                updater: (store) => {
                  addNftTransaction(store, mintKey.toString());

                  const nftRecord = store.get(
                    getNftExpressDataId(mintKey.toString())
                  );
                  nftRecord?.setValue(priceInLamports, "price");
                },
              });
            } catch (e) {
              logIfNotProd("error changing price (solana)", e);
              notify({
                message:
                  "An unexpected error occurred while changing the price",
                type: "error",
              });
              setIsLoading(false);
            }
          }}
        >
          Save
        </ButtonWithText>
      </div>
    </GenericModal>
  );
}
