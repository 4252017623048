import useSolanaContext from "hooks/useSolanaContext";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { ProfilePageQuery } from "components/pages/profile/__generated__/ProfilePageQuery.graphql";
import ProfilePageForUser from "components/pages/profile/ProfilePageForUser";
import DisconnectedPage from "components/pages/misc/DisconnectedPage";
import { Maybe } from "types/UtilityTypes";
import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";
import { Suspense } from "react";
import MessagePage from "components/pages/common/MessagePage";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ColorValue from "types/enums/ColorValue";

const query = graphql`
  query ProfilePageQuery($userId: String!) {
    User_by_pk(id: $userId) {
      id

      ...ProfilePageForUser_User
    }
  }
`;

function Connected() {
  const { anchorWallet } = useSolanaContext();
  const data = useLazyLoadQuery<ProfilePageQuery>(query, {
    userId: anchorWallet!.publicKey.toString(),
  });

  if (data.User_by_pk == null) {
    return <DisconnectedPage />;
  }

  return <ProfilePageForUser user={data.User_by_pk} />;
}

export default function ProfilePage(): Maybe<JSX.Element> {
  return (
    <DisconnectedPageContainer>
      <Suspense
        fallback={
          <MessagePage>
            <LoadingSpinner colorValue={ColorValue.BrightPurple} />
          </MessagePage>
        }
      >
        <Connected />
      </Suspense>
    </DisconnectedPageContainer>
  );
}
