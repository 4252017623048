import styles from "css/pages/common/nft/NftInfo.module.css";
import graphql from "babel-plugin-relay/macro";
import ArtistPillButton from "components/buttons/ArtistPillButton";
import NftLabelAndContent from "components/pages/common/nft/NftLabelAndContent";
import { NftInfo_MetadataAccount$key } from "components/pages/common/nft/__generated__/NftInfo_MetadataAccount.graphql";
import Header2 from "components/text/Header2";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import ColorClass from "types/enums/ColorClass";
import Body1 from "components/text/Body1";
import NftActionButton from "components/buttons/NftActionButton";
import NftPrice from "components/pages/common/nft/NftPrice";
import NftEllipsisShadowButton from "components/buttons/NftEllipsisShadowButton";
import useSolanaContext from "hooks/useSolanaContext";
import { NftPageTxsQuery } from "components/pages/common/nft/__generated__/NftPageTxsQuery.graphql";
import { txsQuery } from "components/pages/common/nft/NftPage";
import NftTransactions from "components/pages/common/nft/NftTransactions";
import { Suspense } from "react";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ColorValue from "types/enums/ColorValue";
import shortenAddress from "utils/shortenAddress";

const fragment = graphql`
  fragment NftInfo_MetadataAccount on MetadataAccount {
    id
    mint

    nft {
      id
      creatorId
      isOffPlatform
      price
      Creator {
        id
        username

        ProfilePhoto {
          id
          photoUrl
        }
      }
      Owner {
        id
        username

        ProfilePhoto {
          id
          photoUrl
        }
      }
      ownerId
      status
    }

    data {
      name
    }

    offchainData {
      description
      image
    }

    ...NftEllipsisShadowButton_MetadataAccount
    ...NftPrice_MetadataAccount
    ...NftActionButton_MetadataAccount
  }
`;

function Transactions({
  transactionsQueryRef,
}: {
  transactionsQueryRef: PreloadedQuery<NftPageTxsQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<NftPageTxsQuery>(
    txsQuery,
    transactionsQueryRef
  );

  return <NftTransactions nftTransactions={data.nftTransactions} />;
}

type Props = {
  metadataAccount: NftInfo_MetadataAccount$key;
  // transactions: JSX.Element;
  transactionsQueryRef: PreloadedQuery<NftPageTxsQuery>;
};

export default function NftInfo({
  metadataAccount,
  transactionsQueryRef,
}: Props): JSX.Element {
  const { anchorWallet } = useSolanaContext();
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const { nft } = metadataAccountData;
  const { description } = metadataAccountData.offchainData;
  const isOwner = anchorWallet?.publicKey.toString() === nft.ownerId;

  return (
    <div>
      <div className={styles.nameAndButton}>
        <Header2 colorClass={ColorClass.Primary}>
          {metadataAccountData.data.name}
        </Header2>
        {isOwner && metadataAccountData.nft.status === "Listed" && (
          <NftEllipsisShadowButton metadataAccount={metadataAccountData} />
        )}
      </div>
      <div className={styles.creatorAndOwner}>
        <NftLabelAndContent className={styles.creator} label="Creator">
          <ArtistPillButton
            disableLink={nft.Creator == null}
            name={nft.Creator?.username ?? shortenAddress(nft.creatorId)}
            src={nft.Creator?.ProfilePhoto?.photoUrl ?? null}
          />
        </NftLabelAndContent>
        {nft.ownerId !== nft.creatorId && (
          <NftLabelAndContent className={styles.creator} label="Owner">
            <ArtistPillButton
              disableLink={nft.Owner == null}
              name={nft.Owner?.username ?? shortenAddress(nft.ownerId)}
              src={nft.Owner?.ProfilePhoto?.photoUrl ?? null}
            />
          </NftLabelAndContent>
        )}
      </div>
      {description != null && description.length > 0 && (
        <NftLabelAndContent className={styles.description} label="Description">
          <Body1 colorClass={ColorClass.Primary}>{description}</Body1>
        </NftLabelAndContent>
      )}
      <div className={styles.price}>
        <NftPrice metadataAccount={metadataAccountData} />
      </div>
      <div className={styles.actionButton}>
        <NftActionButton metadataAccount={metadataAccountData} />
      </div>
      <NftLabelAndContent className={styles.activity} label="Activity">
        <Suspense
          fallback={
            <LoadingSpinner
              colorValue={ColorValue.BrightPurple}
              style={{ display: "inline-block", marginTop: 16 }}
            />
          }
        >
          <Transactions transactionsQueryRef={transactionsQueryRef} />
        </Suspense>
      </NftLabelAndContent>
    </div>
  );
}
