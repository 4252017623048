/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftTags_MetadataAccount = {
    readonly tags: ReadonlyArray<string>;
    readonly " $refType": "NftTags_MetadataAccount";
};
export type NftTags_MetadataAccount$data = NftTags_MetadataAccount;
export type NftTags_MetadataAccount$key = {
    readonly " $data"?: NftTags_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NftTags_MetadataAccount">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NftTags_MetadataAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
(node as any).hash = '6cdc60d17c5858d58621fb4ee88135f7';
export default node;
