/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftTransactionType_enum = "Bid" | "Listed" | "ListingCancelled" | "Minted" | "Sold" | "Transferred" | "%future added value";
export type NftTransaction_NftTransactionExpress = {
    readonly id: unknown;
    readonly fromAddress: string;
    readonly mint: unknown;
    readonly priceInLamports: unknown | null;
    readonly timeCreated: unknown;
    readonly toAddress: string;
    readonly txid: string;
    readonly type: NftTransactionType_enum;
    readonly From: {
        readonly id: string;
        readonly username: string;
        readonly ProfilePhoto: {
            readonly id: unknown;
            readonly photoUrl: string;
        } | null;
    } | null;
    readonly To: {
        readonly id: string;
        readonly username: string;
        readonly ProfilePhoto: {
            readonly id: unknown;
            readonly photoUrl: string;
        } | null;
    } | null;
    readonly " $refType": "NftTransaction_NftTransactionExpress";
};
export type NftTransaction_NftTransactionExpress$data = NftTransaction_NftTransactionExpress;
export type NftTransaction_NftTransactionExpress$key = {
    readonly " $data"?: NftTransaction_NftTransactionExpress$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NftTransaction_NftTransactionExpress">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Photo",
    "kind": "LinkedField",
    "name": "ProfilePhoto",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NftTransaction_NftTransactionExpress",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fromAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceInLamports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeCreated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "txid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "From",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "To",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "NftTransactionExpress",
  "abstractKey": null
};
})();
(node as any).hash = '702dd3b858d87071c588656748ac26dc';
export default node;
