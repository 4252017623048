import styles from "css/buttons/TabButton.module.css";
import PlainButton from "components/buttons/PlainButton";
import joinClasses from "utils/joinClasses";
import Body1Medium from "components/text/Body1Medium";
import ColorClass from "types/enums/ColorClass";

export default function TabButton({
  isActive = false,
  label,
  name,
  onClick,
}: {
  isActive: boolean;
  label: string;
  name: string;
  onClick: () => void;
}) {
  return (
    <PlainButton
      className={joinClasses(styles.tab, isActive ? styles.tabActive : null)}
      onClick={onClick}
    >
      <Body1Medium colorClass={ColorClass.Primary}>{name}</Body1Medium>
      <Body1Medium colorClass={ColorClass.Secondary}>{label}</Body1Medium>
    </PlainButton>
  );
}
