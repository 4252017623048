/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CancelListingModal_MetadataAccount = {
    readonly id: string;
    readonly mint: unknown;
    readonly nft: {
        readonly creatorId: string;
        readonly price: unknown | null;
    };
    readonly " $refType": "CancelListingModal_MetadataAccount";
};
export type CancelListingModal_MetadataAccount$data = CancelListingModal_MetadataAccount;
export type CancelListingModal_MetadataAccount$key = {
    readonly " $data"?: CancelListingModal_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CancelListingModal_MetadataAccount">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancelListingModal_MetadataAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NftExpress",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
(node as any).hash = 'd1a0c06fb08ec61e553a3da1ed01bfdc';
export default node;
