import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

/**
 * Uploads a file to Firebase storage.
 *
 * @param file the file to upload.
 * @param path the path to upload it at.
 * @return the download URL
 */
export default async function uploadFile(
  file: File,
  path: string
): Promise<string> {
  const storageRef = ref(getStorage(), path);
  const uploadResult = await uploadBytes(storageRef, file);
  return getDownloadURL(uploadResult.ref);
}
