/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditProfileFormUserMutationVariables = {
    bio?: string | null | undefined;
    coverPhotoId?: unknown | null | undefined;
    email: string;
    id: string;
    instagramName?: string | null | undefined;
    profilePhotoId?: unknown | null | undefined;
    twitterName?: string | null | undefined;
    username: string;
    websiteUrl?: string | null | undefined;
};
export type EditProfileFormUserMutationResponse = {
    readonly update_User_by_pk: {
        readonly " $fragmentRefs": FragmentRefs<"EditProfileForm_User">;
    } | null;
};
export type EditProfileFormUserMutation = {
    readonly response: EditProfileFormUserMutationResponse;
    readonly variables: EditProfileFormUserMutationVariables;
};



/*
mutation EditProfileFormUserMutation(
  $bio: String
  $coverPhotoId: uuid
  $email: String!
  $id: String!
  $instagramName: String
  $profilePhotoId: uuid
  $twitterName: String
  $username: String!
  $websiteUrl: String
) {
  update_User_by_pk(_set: {bio: $bio, coverPhotoId: $coverPhotoId, email: $email, instagramName: $instagramName, profilePhotoId: $profilePhotoId, twitterName: $twitterName, username: $username, websiteUrl: $websiteUrl}, pk_columns: {id: $id}) {
    ...EditProfileForm_User
  }
}

fragment EditProfileForm_User on User {
  id
  bio
  discordHandle
  email
  instagramName
  twitterName
  username
  websiteUrl
  CoverPhoto {
    id
    photoUrl
  }
  ProfilePhoto {
    id
    photoUrl
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bio"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "coverPhotoId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "instagramName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profilePhotoId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "twitterName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "websiteUrl"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "bio",
        "variableName": "bio"
      },
      {
        "kind": "Variable",
        "name": "coverPhotoId",
        "variableName": "coverPhotoId"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "instagramName",
        "variableName": "instagramName"
      },
      {
        "kind": "Variable",
        "name": "profilePhotoId",
        "variableName": "profilePhotoId"
      },
      {
        "kind": "Variable",
        "name": "twitterName",
        "variableName": "twitterName"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      },
      {
        "kind": "Variable",
        "name": "websiteUrl",
        "variableName": "websiteUrl"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "photoUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProfileFormUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "update_User_by_pk",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditProfileForm_User"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProfileFormUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "update_User_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discordHandle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instagramName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "twitterName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "websiteUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Photo",
            "kind": "LinkedField",
            "name": "CoverPhoto",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Photo",
            "kind": "LinkedField",
            "name": "ProfilePhoto",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4684eb5ccbfd733366fcbd16d933a8bb",
    "id": null,
    "metadata": {},
    "name": "EditProfileFormUserMutation",
    "operationKind": "mutation",
    "text": "mutation EditProfileFormUserMutation(\n  $bio: String\n  $coverPhotoId: uuid\n  $email: String!\n  $id: String!\n  $instagramName: String\n  $profilePhotoId: uuid\n  $twitterName: String\n  $username: String!\n  $websiteUrl: String\n) {\n  update_User_by_pk(_set: {bio: $bio, coverPhotoId: $coverPhotoId, email: $email, instagramName: $instagramName, profilePhotoId: $profilePhotoId, twitterName: $twitterName, username: $username, websiteUrl: $websiteUrl}, pk_columns: {id: $id}) {\n    ...EditProfileForm_User\n  }\n}\n\nfragment EditProfileForm_User on User {\n  id\n  bio\n  discordHandle\n  email\n  instagramName\n  twitterName\n  username\n  websiteUrl\n  CoverPhoto {\n    id\n    photoUrl\n  }\n  ProfilePhoto {\n    id\n    photoUrl\n  }\n}\n"
  }
};
})();
(node as any).hash = '6455fd45cd19021009de297c98c4ed84';
export default node;
