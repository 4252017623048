import TabButton from "components/buttons/TabButton";
import styles from "css/pages/profile/ProfileTabs.module.css";
import ProfileTabType from "types/enums/ProfileTabType";
import { MaybeUndef } from "types/UtilityTypes";

function Tab({
  isActive = false,
  num,
  profileTabType,
  setTab,
}: {
  isActive: boolean;
  num: MaybeUndef<number>;
  profileTabType: ProfileTabType;
  setTab: (val: ProfileTabType) => void;
}) {
  return (
    <TabButton
      isActive={isActive}
      label={num?.toString() ?? ""}
      name={profileTabType}
      onClick={() => setTab(profileTabType)}
    />
  );
}

type Props = {
  numCreated: MaybeUndef<number>;
  numCollected: MaybeUndef<number>;
  numWalletViewer: MaybeUndef<number>;
  tab: ProfileTabType;
  setTab: (val: ProfileTabType) => void;
};

export default function ProfileTabs({
  numCreated,
  numCollected,
  numWalletViewer,
  tab,
  setTab,
}: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Tab
          isActive={tab === ProfileTabType.Created}
          num={numCreated}
          profileTabType={ProfileTabType.Created}
          setTab={setTab}
        />
        <Tab
          isActive={tab === ProfileTabType.Collected}
          num={numCollected}
          profileTabType={ProfileTabType.Collected}
          setTab={setTab}
        />
      </div>
      <Tab
        isActive={tab === ProfileTabType.WalletViewer}
        num={numWalletViewer}
        profileTabType={ProfileTabType.WalletViewer}
        setTab={setTab}
      />
    </div>
  );
}
