import LoadingSpinner from "components/loading/LoadingSpinner";
import MessagePage from "components/pages/common/MessagePage";
import DisconnectedPage from "components/pages/misc/DisconnectedPage";
import useSolanaContext from "hooks/useSolanaContext";
import useUserContext from "hooks/useUserContext";
import ColorValue from "types/enums/ColorValue";
import { Maybe } from "types/UtilityTypes";

type Props = {
  children: any;
};

export default function DisconnectedPageContainer({
  children,
}: Props): Maybe<JSX.Element> {
  const { anchorWallet } = useSolanaContext();
  const { userId } = useUserContext();

  if (anchorWallet === undefined || userId === undefined) {
    return (
      <MessagePage>
        <LoadingSpinner colorValue={ColorValue.BrightPurple} />
      </MessagePage>
    );
  }

  if (anchorWallet == null || userId == null) {
    return <DisconnectedPage />;
  }

  return children;
}
