enum ButtonTheme {
  BrightPurpleOutline = "BrightPurpleOutline",
  LightPinkOutline = "LightPinkOutline",
  Navy = "Navy",
  NavyOutlineSquare = "NavyOutlineSquare",
  PurpleGradient = "PurpleGradient",
  PurpleOutline = "PurpleOutline",
  Red = "Red",
  RedOutline = "RedOutline",
  SecondaryOutline = "SecondaryOutline",
}

export default ButtonTheme;
