import Profile20Icon from "components/icons/Profile20Icon";
import ImageCloudflare from "components/images/ImageCloudflare";
import Body1Medium from "components/text/Body1Medium";
import CDN_CGI from "constants/CdnCgi";
import styles from "css/buttons/ArtistPillButton.module.css";
import { Link } from "react-router-dom";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import { MaybeUndef } from "types/UtilityTypes";

type Props = {
  disableLink?: boolean;
  name: string;
  src?: MaybeUndef<string>;
};

export default function ArtistPillButton({
  disableLink = false,
  name,
  src,
}: Props): JSX.Element {
  let image;

  if (src == null) {
    image = (
      <div className={styles.image}>
        <Profile20Icon colorValue={ColorValue.White} />
      </div>
    );
  } else if (src.includes("http")) {
    image = <img className={styles.image} src={src} />;
  } else {
    image = (
      <ImageCloudflare
        className={styles.image}
        src={`${CDN_CGI}/width=128,f=auto/${src}`}
      />
    );
  }

  const children = (
    <>
      {image}
      <Body1Medium colorClass={ColorClass.Primary}>@{name}</Body1Medium>
    </>
  );

  if (disableLink) {
    return <div className={styles.button}>{children}</div>;
  }

  return (
    <Link className={styles.button} to={`/@${name}`}>
      {children}
    </Link>
  );
}
