/**
 * These should be ordered in the order they appear in the UI.
 */
enum CreateStep {
  Media = "Media",
  Details = "Details",
  Mint = "Mint",
  Listed = "Listed",
}

export default CreateStep;
