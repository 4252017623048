/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BidButton_MetadataAccount = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"BidModal_MetadataAccount">;
    readonly " $refType": "BidButton_MetadataAccount";
};
export type BidButton_MetadataAccount$data = BidButton_MetadataAccount;
export type BidButton_MetadataAccount$key = {
    readonly " $data"?: BidButton_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BidButton_MetadataAccount">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BidButton_MetadataAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BidModal_MetadataAccount"
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
(node as any).hash = '930c39a9582b1187426fe736ab85c66f';
export default node;
