import ButtonWithText from "components/buttons/ButtonWithText";
import ArrowRight20Icon from "components/icons/ArrowRight20Icon";
import InputLabel from "components/input/InputLabel";
import InputWithLabel from "components/input/InputWithLabel";
import TextInput from "components/input/TextInput";
import CreateBodyContainer from "components/pages/create/CreateBodyContainer";
import CreateListingPreview from "components/pages/create/CreateListingPreview";
import Body1 from "components/text/Body1";
import Header2 from "components/text/Header2";
import styles from "css/pages/create/CreateDetails.module.css";
import useCreateContext from "hooks/useCreateContext";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import CreateStep from "types/enums/CreateStep";
import FontClass from "types/enums/FontClass";
import { Maybe } from "types/UtilityTypes";

export default function CreateDetails(): Maybe<JSX.Element> {
  const { description, step, title, setDescription, setStep, setTitle } =
    useCreateContext();

  if (step !== CreateStep.Details) {
    return null;
  }

  return (
    <CreateBodyContainer>
      <div className={styles.container}>
        <div className={styles.preview}>
          <CreateListingPreview />
        </div>
        <div className={styles.inputsAndTitle}>
          <Header2 colorClass={ColorClass.Primary}>NFT details</Header2>
          <Body1
            className={styles.description}
            colorClass={ColorClass.Secondary}
          >
            Double check all these details—once your NFT has been minted on the
            blockchain, you will not be able to edit this information.
          </Body1>
          <div className={styles.inputs}>
            <InputWithLabel
              input={
                <TextInput
                  value={title}
                  maxLength={75}
                  onChange={setTitle}
                  placeholder="Title of your piece"
                />
              }
              label={<InputLabel label="Title" />}
            />
            <InputWithLabel
              input={
                <TextInput
                  value={description}
                  maxLength={300}
                  onChange={setDescription}
                  placeholder="(Optional) Add a description about your piece"
                />
              }
              label={<InputLabel label="Description" />}
            />
          </div>
          <ButtonWithText
            buttonTheme={ButtonTheme.PurpleGradient}
            className={styles.nextButton}
            disabled={title.length === 0}
            fontClass={FontClass.NavLink}
            icon={<ArrowRight20Icon colorValue={ColorValue.White} />}
            onClick={() => setStep(CreateStep.Mint)}
          >
            Next
          </ButtonWithText>
        </div>
      </div>
    </CreateBodyContainer>
  );
}
