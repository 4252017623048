import styles from "css/auction/NftGrid.module.css";
import joinClasses from "utils/joinClasses";

type Props = {
  children: any;
  className?: string;
};

export default function NftGrid({ children, className }: Props): JSX.Element {
  return <div className={joinClasses(styles.grid, className)}>{children}</div>;
}
