import FiltersButton from "components/buttons/FiltersButton";
import SortButton from "components/buttons/SortButton";
import TabButton from "components/buttons/TabButton";
import styles from "css/pages/explore/ExploreControlBar.module.css";
import useExploreContext from "hooks/useExploreContext";
import ExploreTab from "types/enums/ExploreTab";

export default function ExploreControlBar(): JSX.Element {
  const { tab, setTab } = useExploreContext();

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <TabButton
          isActive={tab === ExploreTab.Artwork}
          label=""
          name={ExploreTab.Artwork}
          onClick={() => setTab(ExploreTab.Artwork)}
        />
        <TabButton
          isActive={tab === ExploreTab.Creators}
          label=""
          name={ExploreTab.Creators}
          onClick={() => setTab(ExploreTab.Creators)}
        />
      </div>
      <div className={styles.buttons}>
        <FiltersButton />
        <SortButton />
      </div>
    </div>
  );
}
