/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ListingCardForMetadata_MetadataAccount = {
    readonly id: string;
    readonly mint: unknown;
    readonly data: {
        readonly name: string;
    };
    readonly offchainData: {
        readonly image: string;
    };
    readonly nft: {
        readonly creatorId: string;
        readonly Creator: {
            readonly username: string;
            readonly " $fragmentRefs": FragmentRefs<"ArtistPillButtonForUser_User">;
        } | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"NftOtherInfo_MetadataAccount">;
    readonly " $refType": "ListingCardForMetadata_MetadataAccount";
};
export type ListingCardForMetadata_MetadataAccount$data = ListingCardForMetadata_MetadataAccount;
export type ListingCardForMetadata_MetadataAccount$key = {
    readonly " $data"?: ListingCardForMetadata_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ListingCardForMetadata_MetadataAccount">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListingCardForMetadata_MetadataAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetadataAccountData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetadataOffchain",
      "kind": "LinkedField",
      "name": "offchainData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NftExpress",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "Creator",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ArtistPillButtonForUser_User"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NftOtherInfo_MetadataAccount"
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
(node as any).hash = '85932ca0e9caa66e1b175ede28dd4a49';
export default node;
