import graphql from "babel-plugin-relay/macro";
import ButtonWithText from "components/buttons/ButtonWithText";
import CopyAddressButton from "components/buttons/CopyAddressButton";
import CreateBodyContainer from "components/pages/create/CreateBodyContainer";
import CreateListingPreview from "components/pages/create/CreateListingPreview";
import { CreateMintMutation } from "components/pages/create/__generated__/CreateMintMutation.graphql";
import Body1 from "components/text/Body1";
import Header2 from "components/text/Header2";
import TinyLabel from "components/text/TinyLabel";
import { notify } from "components/toast/notifications";
import styles from "css/pages/create/CreateMint.module.css";
import useCreateContext from "hooks/useCreateContext";
import useSolanaContext from "hooks/useSolanaContext";
import { useState } from "react";
import { useMutation } from "react-relay";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import CreateStep from "types/enums/CreateStep";
import FontClass from "types/enums/FontClass";
import { Maybe } from "types/UtilityTypes";
import getFileExt from "utils/getFileExt";
import mintMasterEdition from "utils/solana/metaplex/mintMasterEdition";

const mutation = graphql`
  mutation CreateMintMutation(
    $creatorId: String!
    $id: String!
    $mint: String!
    $txid: String!
  ) {
    insert_Nft_one(
      object: {
        creatorId: $creatorId
        id: $id
        mint: $mint
        ownerId: $creatorId
        status: Owned
      }
    ) {
      id
      creatorId
      mint
      status
    }

    insertNftTransaction(
      input: {
        creatorId: $creatorId
        fromUserId: $creatorId
        toUserId: $creatorId
        mint: $mint
        txid: $txid
        type: Minted
      }
    ) {
      ...NftTransaction_NftTransactionExpress
    }
  }
`;

export default function CreateMint(): Maybe<JSX.Element> {
  const [commit] = useMutation<CreateMintMutation>(mutation);
  const { description, file, step, title, setMint, setStep } =
    useCreateContext();
  const { anchorWallet, connection } = useSolanaContext();
  const [isLoading, setIsLoading] = useState(false);

  if (step !== CreateStep.Mint) {
    return null;
  }

  return (
    <CreateBodyContainer>
      <div className={styles.container}>
        <div className={styles.preview}>
          <CreateListingPreview />
        </div>
        <div className={styles.right}>
          <Header2 colorClass={ColorClass.Primary}>
            Ready to <span className={styles.gradientText}>mint</span>?
          </Header2>
          <Body1
            className={styles.description}
            colorClass={ColorClass.Secondary}
          >
            After you approve the transaction with your wallet, your NFT will be
            minted and your wallet will be recorded as the creator of this NFT.
          </Body1>
          <div className={styles.mintingWith}>
            <TinyLabel
              colorClass={ColorClass.Secondary}
              textTransform="uppercase"
            >
              Minting with wallet
            </TinyLabel>
            <CopyAddressButton address={anchorWallet!.publicKey.toString()} />
          </div>
          <ButtonWithText
            buttonTheme={ButtonTheme.PurpleGradient}
            className={styles.mintButton}
            disabled={title.length === 0}
            fontClass={FontClass.NavLink}
            showLoadingSpinner={isLoading}
            onClick={async () => {
              setIsLoading(true);
              const results = await mintMasterEdition(
                connection,
                anchorWallet!,
                file!,
                {
                  creators: [
                    {
                      address: anchorWallet!.publicKey.toString(),
                      share: 100,
                    },
                  ],
                  description,
                  image: `image.${getFileExt(file!)}`,
                  name: title,
                  // TODO: somehow, need to prevent users from
                  // setting this to whatever they want
                  sellerFeeBasisPoints: 500,
                  // TODO: let users control symbol?
                  symbol: "FORM",
                },
                0
              );

              if (results == null) {
                setIsLoading(false);
                return;
              }

              commit({
                variables: {
                  id: results.mintAccount.toString(),
                  creatorId: anchorWallet!.publicKey.toString(),
                  mint: results.mintAccount.toString(),
                  txid: results.mintNftTxid,
                },
                onCompleted: () => {
                  setTimeout(() => {
                    notify({
                      message: "Processing, please wait...",
                      duration: 8,
                    });
                  }, 2000);
                  // Delay this a bit, otherwise going to the NFT might result in "Could not find NFT"
                  setTimeout(() => {
                    setIsLoading(false);
                    setMint(results.mintAccount.toString());
                    setStep(CreateStep.Listed);
                  }, 10000);
                },
                onError: () => {
                  setIsLoading(false);
                  // TODO: make this nicer
                  notify({ type: "error", message: "Failed to insert NFT" });
                },
              });
            }}
          >
            Mint NFT 😍
          </ButtonWithText>
        </div>
      </div>
    </CreateBodyContainer>
  );
}
