import { MaybeUndef } from "types/UtilityTypes";
import styles from "css/auction/ListingCardImage.module.css";
import { useState } from "react";

type Props = {
  src: MaybeUndef<string>;
};

export default function ListingCardImage({ src }: Props): JSX.Element {
  const [isError, setIsError] = useState(false);

  return src == null || isError ? (
    <div className={styles.image} />
  ) : (
    <img className={styles.image} onError={() => setIsError(true)} src={src} />
  );
}
