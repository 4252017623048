import graphql from "babel-plugin-relay/macro";
import { NftOtherInfo_MetadataAccount$key } from "components/auction/__generated__/NftOtherInfo_MetadataAccount.graphql";
import NftLabelAndContent from "components/pages/common/nft/NftLabelAndContent";
import NavLink from "components/text/NavLink";
import SOL_SYMBOL from "constants/SolSymbol";
import styles from "css/auction/NftOtherInfo.module.css";
import { useFragment } from "react-relay";
import ColorClass from "types/enums/ColorClass";
import { Maybe } from "types/UtilityTypes";
import formatLamports from "utils/formatLamports";

function WithSeparator({ children }: { children: any }): JSX.Element {
  return <div className={styles.withSeparator}>{children}</div>;
}

const fragment = graphql`
  fragment NftOtherInfo_MetadataAccount on MetadataAccount {
    id

    nft {
      id
      isOffPlatform
      price
      status
    }
  }
`;

type Props = {
  metadataAccount: NftOtherInfo_MetadataAccount$key;
};

export default function NftOtherInfo({
  metadataAccount,
}: Props): Maybe<JSX.Element> {
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const { nft } = metadataAccountData;

  if (nft.isOffPlatform) {
    return (
      <WithSeparator>
        <NftLabelAndContent label="Off Platform">
          <NavLink colorClass={ColorClass.Primary}>---</NavLink>
        </NftLabelAndContent>
      </WithSeparator>
    );
  }

  const { price, status } = nft;

  if (status === "Owned") {
    if (price == null) {
      // Has not been sold
      return (
        <WithSeparator>
          <NftLabelAndContent label="Not Listed">
            <NavLink colorClass={ColorClass.Primary}>---</NavLink>
          </NftLabelAndContent>
        </WithSeparator>
      );
    }

    // Has been sold
    return (
      <WithSeparator>
        <NftLabelAndContent label="Last Sold For">
          <NavLink colorClass={ColorClass.Primary}>
            {formatLamports(Number(price))} {SOL_SYMBOL}
          </NavLink>
        </NftLabelAndContent>
      </WithSeparator>
    );
  }

  if (status === "Listed") {
    return (
      <WithSeparator>
        <NftLabelAndContent label="Reserve Price">
          <NavLink colorClass={ColorClass.Primary}>
            {formatLamports(Number(price))} {SOL_SYMBOL}
          </NavLink>
        </NftLabelAndContent>
      </WithSeparator>
    );
  }

  if (status === "Auction") {
    return (
      <div className={styles.auction}>
        <NftLabelAndContent
          label="Current Bid"
          labelColorClass={ColorClass.White}
        >
          <NavLink colorClass={ColorClass.White}>
            {formatLamports(Number(price))} {SOL_SYMBOL}
          </NavLink>
        </NftLabelAndContent>
        <NftLabelAndContent label="Ends In" labelColorClass={ColorClass.White}>
          {/* TODO: implement */}
          <NavLink colorClass={ColorClass.White}>TODO...</NavLink>
        </NftLabelAndContent>
      </div>
    );
  }

  return <div>Hi</div>;
}
