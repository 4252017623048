import ButtonWithText from "components/buttons/ButtonWithText";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import ArrowRightIcon from "components/icons/ArrowRightIcon";
import ImageCloudflare from "components/images/ImageCloudflare";
import ImageWithArtistPillButton from "components/images/ImageWithArtistPillButton";
import Header1 from "components/text/Header1";
import Subheader from "components/text/Subheader";
import ARTIST_PILL_BUTTON_EXAMPLE from "constants/ArtistPillButtonExample";
import CDN_CGI from "constants/CdnCgi";
import FORM_LINK from "constants/FormLink";
import styles from "css/pages/landing/pre/LandingPreHero.module.css";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";

function Left(): JSX.Element {
  return (
    <div className={styles.left}>
      <Header1 colorClass={ColorClass.Primary}>
        The 1/1 NFT marketplace for Solana
      </Header1>
      <Subheader colorClass={ColorClass.Secondary}>
        Create and collect amazing digital art, without the negative
        environmental impact.
      </Subheader>
      <ButtonWithText
        buttonTheme={ButtonTheme.PurpleGradient}
        fontClass={FontClass.NavLink}
        href={FORM_LINK}
        icon={<ArrowRightIcon colorValue={ColorValue.White} />}
        type="link_external"
      >
        Submit artist profile
      </ButtonWithText>
    </div>
  );
}

function Right(): JSX.Element {
  const image = (
    <ImageCloudflare
      className={styles.image}
      src={`${CDN_CGI}/width=900,f=auto/images/landing/art.jpg`}
    />
  );

  return (
    <ImageWithArtistPillButton
      artistPillButton={ARTIST_PILL_BUTTON_EXAMPLE}
      image={image}
    />
  );
}

export default function LandingPreHero(): JSX.Element {
  return (
    <ResponsiveContainer className={styles.container}>
      <Left />
      <Right />
    </ResponsiveContainer>
  );
}
