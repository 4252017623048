import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import {
  AUCTION_HOUSE,
  AUCTION_HOUSE_PROGRAM_ID,
} from "constants/SolanaConstants";

export default async function findAuctionHouseProgramAsSigner(): Promise<
  [PublicKey, number]
> {
  return anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(AUCTION_HOUSE), Buffer.from("signer")],
    AUCTION_HOUSE_PROGRAM_ID
  );
}
