import GenericModal from "components/modal/GenericModal";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import styles from "css/modal/ListNftModal.module.css";
import InputWithLabel from "components/input/InputWithLabel";
import InputLabel from "components/input/InputLabel";
import { useState } from "react";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import ReactTagInput from "@pathofdev/react-tag-input";
import useSolanaContext from "hooks/useSolanaContext";
import auctionHouseSell from "utils/solana/auction-house/auctionHouseSell";
import invariant from "tiny-invariant";
import findAta from "utils/solana/pdas/findAta";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import logIfNotProd from "utils/logIfNotProd";
import { notify } from "components/toast/notifications";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { ListNftModalMutation } from "components/modal/__generated__/ListNftModalMutation.graphql";
import { ListNftModal_MetadataAccount$key } from "components/modal/__generated__/ListNftModal_MetadataAccount.graphql";
import PriceInput from "components/input/PriceInput";
import addTransaction from "utils/relay/updaters/addNftTransaction";
import updateNftRecord from "utils/relay/updaters/updateNftRecord";

const mutation = graphql`
  mutation ListNftModalMutation(
    $creator: String!
    $lister: String!
    $mint: String!
    $nftToTagObjects: [NftToTag_insert_input!]!
    $price: bigint!
    $txid: String!
    $deleteFilter: NftToTag_bool_exp!
  ) {
    update_Nft_by_pk(
      pk_columns: { id: $mint }
      _set: { priceInLamports: $price, status: Listed }
    ) {
      id
      creatorId
      mint
      priceInLamports
      status
    }

    insertNftTransaction(
      input: {
        creatorId: $creator
        fromUserId: $lister
        toUserId: $lister
        mint: $mint
        price: $price
        txid: $txid
        type: Listed
      }
    ) {
      ...NftTransaction_NftTransactionExpress
    }

    delete_NftToTag(where: $deleteFilter) {
      returning {
        nftId
        tagId
      }
    }

    insert_NftToTag(objects: $nftToTagObjects) {
      returning {
        nftId
        tagId
      }
    }
  }
`;

const fragment = graphql`
  fragment ListNftModal_MetadataAccount on MetadataAccount {
    id
    mint

    nft {
      creatorId
    }

    tags
  }
`;

type Props = {
  isShown: boolean;
  metadataAccount: ListNftModal_MetadataAccount$key;
  onHide: () => void;
};

export default function ListNftModal({
  isShown,
  metadataAccount,
  onHide,
}: Props): JSX.Element {
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const [commit] = useMutation<ListNftModalMutation>(mutation);
  const [price, setPrice] = useState("");
  const originalTags = [...metadataAccountData.tags];
  const [tags, setTags] = useState<Array<string>>(originalTags);

  const { anchorWallet, auctionHouseProgram } = useSolanaContext();
  const [isLoading, setIsLoading] = useState(false);

  const priceInput = (
    <InputWithLabel
      label={<InputLabel label="Reserve price" />}
      input={<PriceInput priceInSol={price} setPrice={setPrice} />}
    />
  );

  const tagsInput = (
    <InputWithLabel
      label={
        <InputLabel
          label="Tags"
          subLabel="You can add up to 10 tags describing your work to help collectors discover your art."
        />
      }
      input={
        <ReactTagInput
          maxTags={10}
          onChange={(newTags) => setTags(newTags)}
          removeOnBackspace
          tags={tags}
        />
      }
    />
  );

  return (
    <GenericModal
      isShown={isShown}
      onHide={() => {
        onHide();
        setTags(originalTags);
      }}
      title="Set reserve price"
    >
      <div className={styles.body}>
        <Body1 colorClass={ColorClass.Secondary} textAlign="center">
          Set the minimum bid price for your piece. Once someone bids at least
          this amount, a 24-hour auction for this piece will begin, and the
          highest bid at the end will win.
        </Body1>
        <div className={styles.inputs}>
          {priceInput}
          {tagsInput}
        </div>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.listButton}
          fontClass={FontClass.NavLink}
          showLoadingSpinner={isLoading}
          onClick={async () => {
            invariant(anchorWallet != null);
            invariant(auctionHouseProgram != null);

            setIsLoading(true);
            const newTags = tags.filter((tag) => !originalTags.includes(tag));
            const deletedTags = originalTags.filter(
              (tag) => !tags.includes(tag)
            );

            const mintKey = new PublicKey(metadataAccountData.mint as string);
            const [ata] = await findAta(anchorWallet.publicKey, mintKey);
            const priceInLamports = Number(price) * LAMPORTS_PER_SOL;
            try {
              const txid = await auctionHouseSell(
                auctionHouseProgram,
                anchorWallet.publicKey,
                ata,
                mintKey,
                priceInLamports
              );

              commit({
                variables: {
                  // TODO: should check more carefully. Only NFTs created on our platform should
                  // be able to be listed tho...
                  creator: metadataAccountData.nft.creatorId,
                  lister: anchorWallet.publicKey.toString(),
                  mint: mintKey.toString(),
                  nftToTagObjects: newTags.map((tag) => ({
                    nftId: mintKey.toString(),
                    Tag: {
                      data: { value: tag.trim() },
                      on_conflict: {
                        constraint: "Tag_value_key",
                        update_columns: ["value"],
                      },
                    },
                  })),
                  price: priceInLamports,
                  txid,
                  deleteFilter: {
                    _or: deletedTags.map((tag) => ({
                      Tag: { value: { _eq: tag } },
                    })),
                  },
                },
                onCompleted: () => {
                  notify({ message: "Successfully listed!", txid });
                  setIsLoading(false);
                  setTags([]);
                  setPrice("");
                  onHide();
                },
                onError: (e) => {
                  logIfNotProd("error listing (graphql)", e);
                  notify({
                    message: "An unexpected error occurred while listing",
                    type: "error",
                  });
                },
                updater: (store) => {
                  addTransaction(store, mintKey.toString());
                  updateNftRecord(
                    store,
                    mintKey.toString(),
                    priceInLamports,
                    "Listed"
                  );

                  const metadataAccountRecord = store.get(
                    metadataAccountData.id
                  );
                  metadataAccountRecord?.setValue(tags, "tags");
                },
              });
            } catch (e) {
              logIfNotProd("error listing (solana)", e);
              notify({
                message: "An unexpected error occurred while listing",
                type: "error",
              });
              setIsLoading(false);
            }
          }}
        >
          List on the market
        </ButtonWithText>
      </div>
    </GenericModal>
  );
}
