import graphql from "babel-plugin-relay/macro";
import ListingCardGeneric from "components/auction/ListingCardGeneric";
import ListingCardImage from "components/auction/ListingCardImage";
import NftOtherInfo from "components/auction/NftOtherInfo";
import { ListingCardForMetadata_MetadataAccount$key } from "components/auction/__generated__/ListingCardForMetadata_MetadataAccount.graphql";
import ArtistPillButton from "components/buttons/ArtistPillButton";
import ArtistPillButtonForUser from "components/buttons/ArtistPillButtonForUser";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";
import shortenAddress from "utils/shortenAddress";

const fragment = graphql`
  fragment ListingCardForMetadata_MetadataAccount on MetadataAccount {
    id
    mint

    data {
      name
    }

    offchainData {
      image
    }

    nft {
      creatorId
      Creator {
        username
        ...ArtistPillButtonForUser_User
      }
    }

    ...NftOtherInfo_MetadataAccount
  }
`;

type Props = {
  metadataAccount: ListingCardForMetadata_MetadataAccount$key;
};

export default function ListingCardForMetadata({
  metadataAccount,
}: Props): JSX.Element {
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const creator = metadataAccountData.nft.Creator;
  const artistPillButton =
    creator == null ? (
      <ArtistPillButton
        disableLink
        name={shortenAddress(metadataAccountData.nft.creatorId as string)}
      />
    ) : (
      <ArtistPillButtonForUser user={creator} />
    );
  const image = (
    <ListingCardImage src={metadataAccountData.offchainData.image} />
  );

  return (
    <Link
      style={{ maxWidth: 348, width: "100%" }}
      to={`/@${creator?.username ?? ""}/${metadataAccountData.mint}`}
    >
      <ListingCardGeneric
        artistPillButton={artistPillButton}
        image={image}
        otherInfo={<NftOtherInfo metadataAccount={metadataAccountData} />}
        title={metadataAccountData.data.name}
      />
    </Link>
  );
}
