import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import stringLast from "utils/stringLast";

export default function formatLamports(lamports: number): string {
  let str = (lamports / LAMPORTS_PER_SOL).toFixed(2);
  if (stringLast(str) === "0") {
    str = str.slice(0, str.length - 1);

    if (stringLast(str) === "0") {
      str = str.slice(0, str.length - 1);

      if (stringLast(str) === ".") {
        str = str.slice(0, str.length - 1);
      }
    }
  }

  return str;
}
