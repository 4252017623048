/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfilePageQueryVariables = {
    userId: string;
};
export type ProfilePageQueryResponse = {
    readonly User_by_pk: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"ProfilePageForUser_User">;
    } | null;
};
export type ProfilePageQuery = {
    readonly response: ProfilePageQueryResponse;
    readonly variables: ProfilePageQueryVariables;
};



/*
query ProfilePageQuery(
  $userId: String!
) {
  User_by_pk(id: $userId) {
    id
    ...ProfilePageForUser_User
  }
}

fragment NftsForAddress_User on User {
  id
}

fragment ProfileNfts_User on User {
  id
  ...NftsForAddress_User
}

fragment ProfilePageForUser_User on User {
  id
  bio
  discordHandle
  instagramName
  twitterName
  username
  websiteUrl
  CoverPhoto {
    id
    photoUrl
  }
  ProfilePhoto {
    id
    photoUrl
  }
  ...ProfileNfts_User
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "photoUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "User_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfilePageForUser_User"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "User_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discordHandle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instagramName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "twitterName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "websiteUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Photo",
            "kind": "LinkedField",
            "name": "CoverPhoto",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Photo",
            "kind": "LinkedField",
            "name": "ProfilePhoto",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86a8d9af4ba321ac56724fd88a836133",
    "id": null,
    "metadata": {},
    "name": "ProfilePageQuery",
    "operationKind": "query",
    "text": "query ProfilePageQuery(\n  $userId: String!\n) {\n  User_by_pk(id: $userId) {\n    id\n    ...ProfilePageForUser_User\n  }\n}\n\nfragment NftsForAddress_User on User {\n  id\n}\n\nfragment ProfileNfts_User on User {\n  id\n  ...NftsForAddress_User\n}\n\nfragment ProfilePageForUser_User on User {\n  id\n  bio\n  discordHandle\n  instagramName\n  twitterName\n  username\n  websiteUrl\n  CoverPhoto {\n    id\n    photoUrl\n  }\n  ProfilePhoto {\n    id\n    photoUrl\n  }\n  ...ProfileNfts_User\n}\n"
  }
};
})();
(node as any).hash = '3a578c9c9fce8c2bdfe8f87bbd4865bc';
export default node;
