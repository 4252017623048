/* eslint-disable react/jsx-no-constructed-context-values */
import { Context, createContext, useState } from "react";

import ExploreAvailability from "types/enums/ExploreAvailability";
import ExploreSortOrder from "types/enums/ExploreSortOrder";
import ExploreTab from "types/enums/ExploreTab";
import { Maybe } from "types/UtilityTypes";
import emptyFunction from "utils/emptyFunction";

export type ExploreContextData = {
  availability: ExploreAvailability;
  highPriceInSol: Maybe<number>;
  lowPriceInSol: Maybe<number>;
  primaryMarket: boolean;
  secondaryMarket: boolean;
  sortOrder: ExploreSortOrder;
  tab: ExploreTab;

  setAvailability: (val: ExploreAvailability) => void;
  setHighPriceInSol: (val: Maybe<number>) => void;
  setLowPriceInSol: (val: Maybe<number>) => void;
  setPrimaryMarket: (val: boolean) => void;
  setSecondaryMarket: (val: boolean) => void;
  setSortOrder: (val: ExploreSortOrder) => void;
  setTab: (val: ExploreTab) => void;
};

export const ExploreContext: Context<ExploreContextData> =
  createContext<ExploreContextData>({
    availability: ExploreAvailability.All,
    highPriceInSol: null,
    lowPriceInSol: null,
    primaryMarket: true,
    secondaryMarket: true,
    sortOrder: ExploreSortOrder.Newest,
    tab: ExploreTab.Artwork,

    setAvailability: emptyFunction,
    setHighPriceInSol: emptyFunction,
    setLowPriceInSol: emptyFunction,
    setPrimaryMarket: emptyFunction,
    setSecondaryMarket: emptyFunction,
    setSortOrder: emptyFunction,
    setTab: emptyFunction,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function ExploreContextProvider(props: ProviderProps): JSX.Element {
  const [availability, setAvailability] = useState(ExploreAvailability.All);
  const [lowPriceInSol, setLowPriceInSol] = useState<Maybe<number>>(null);
  const [highPriceInSol, setHighPriceInSol] = useState<Maybe<number>>(null);
  const [primaryMarket, setPrimaryMarket] = useState(true);
  const [secondaryMarket, setSecondaryMarket] = useState(true);
  const [sortOrder, setSortOrder] = useState<ExploreSortOrder>(
    ExploreSortOrder.Newest
  );
  const [tab, setTab] = useState<ExploreTab>(ExploreTab.Artwork);

  return (
    <ExploreContext.Provider
      value={{
        availability,
        highPriceInSol,
        lowPriceInSol,
        primaryMarket,
        secondaryMarket,
        sortOrder,
        tab,

        setAvailability,
        setHighPriceInSol,
        setLowPriceInSol,
        setPrimaryMarket,
        setSecondaryMarket,
        setSortOrder,
        setTab,
      }}
    >
      {props.children}
    </ExploreContext.Provider>
  );
}
