import ButtonWithText from "components/buttons/ButtonWithText";
import ArrowRight20Icon from "components/icons/ArrowRight20Icon";
import GenericDropzone from "components/input/GenericDropzone";
import CreateBodyContainer from "components/pages/create/CreateBodyContainer";
import ArtName from "components/text/ArtName";
import Body2 from "components/text/Body2";
import TinyLabel from "components/text/TinyLabel";
import styles from "css/pages/create/CreateMedia.module.css";
import useCreateContext from "hooks/useCreateContext";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import CreateStep from "types/enums/CreateStep";
import FontClass from "types/enums/FontClass";
import { Maybe } from "types/UtilityTypes";

export default function CreateMedia(): Maybe<JSX.Element> {
  const { file, fileDataUrl, setFile, setStep, step } = useCreateContext();

  if (step !== CreateStep.Media) {
    return null;
  }

  return (
    <CreateBodyContainer>
      <GenericDropzone
        disableHoverStyle
        onDropAccepted={(files) => {
          if (files.length === 0) {
            return;
          }

          setFile(files[0]);
        }}
      >
        <div className={styles.container}>
          {fileDataUrl != null ? (
            <img className={styles.image} src={fileDataUrl} />
          ) : (
            <div className={styles.image} />
          )}
          <ArtName
            className={styles.title}
            colorClass={ColorClass.Primary}
            textAlign="center"
          >
            Upload your media
          </ArtName>
          <Body2
            className={styles.description}
            colorClass={ColorClass.Secondary}
            textAlign="center"
          >
            Drag and drop your file here, or click to upload.
          </Body2>
          <TinyLabel
            className={styles.fileTypes}
            colorClass={ColorClass.Secondary}
            textAlign="center"
            textTransform="uppercase"
          >
            JPG, PNG
          </TinyLabel>
        </div>
      </GenericDropzone>
      <ButtonWithText
        buttonTheme={ButtonTheme.PurpleGradient}
        disabled={file == null}
        fontClass={FontClass.NavLink}
        icon={<ArrowRight20Icon colorValue={ColorValue.White} />}
        onClick={() => setStep(CreateStep.Details)}
      >
        Next
      </ButtonWithText>
    </CreateBodyContainer>
  );
}
