import {
  Environment,
  FetchFunction,
  Network,
  Observable,
  RecordSource,
  Store,
} from "relay-runtime";

import { SubscriptionClient } from "subscriptions-transport-ws";
import LocalStoragePrefix from "types/enums/LocalStoragePrefix";
import getSignature from "utils/local-storage/getSignature";
import getWithPrefix from "utils/local-storage/getWithPrefix";

/**
 * Relay requires developers to configure a "fetch" function that tells Relay how to load
 * the results of GraphQL queries from your server (or other data source). See more at
 * https://relay.dev/docs/en/quick-start-guide#relay-environment.
 */
const fetchRelay: FetchFunction = async (params, variables, _cacheConfig) => {
  const publicKey = getWithPrefix(LocalStoragePrefix.PublicKey, "") ?? "";
  const response = await fetch(process.env.REACT_APP_GRAPHQL_URL as string, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Solana-Sig": getSignature(publicKey) ?? "",
      "X-Solana-Public-Key": publicKey,
    },
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });

  // Get the response as JSON
  const json = await response.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    throw new Error(
      `Error fetching GraphQL query '${
        params.name
      }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        json.errors
      )}`
    );
  }

  // Otherwise, return the full payload.
  return json;
};

// Copied from https://relay.dev/docs/guided-tour/updating-data/graphql-subscriptions/#configuring-the-network-layer
const subscriptionClient = () =>
  new SubscriptionClient(process.env.REACT_APP_WS_GRAPHQL_URL as string, {
    reconnect: true,
  });

// Copied from https://relay.dev/docs/guided-tour/updating-data/graphql-subscriptions/#configuring-the-network-layer
// @ts-ignore
const subscribe = (request, variables) => {
  // @ts-ignore
  const subscribeObservable = subscriptionClient.request({
    query: request.text,
    operationName: request.name,
    variables,
  });
  // Important: Convert subscriptions-transport-ws observable type to Relay's
  // @ts-ignore
  return Observable.from(subscribeObservable);
};

const RelayEnvironment = new Environment({
  // @ts-ignore
  network: Network.create(fetchRelay, subscribe),
  store: new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 10,
  }),
});

export default RelayEnvironment;
