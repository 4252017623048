/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ListNftButton_MetadataAccount = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"ListNftModal_MetadataAccount">;
    readonly " $refType": "ListNftButton_MetadataAccount";
};
export type ListNftButton_MetadataAccount$data = ListNftButton_MetadataAccount;
export type ListNftButton_MetadataAccount$key = {
    readonly " $data"?: ListNftButton_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ListNftButton_MetadataAccount">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListNftButton_MetadataAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ListNftModal_MetadataAccount"
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
(node as any).hash = '867ca9f027a66d77001bc7a3f16dde57';
export default node;
