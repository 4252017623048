import ImageWithArtistPillButton from "components/images/ImageWithArtistPillButton";
import ArtName from "components/text/ArtName";
import styles from "css/auction/ListingCardGeneric.module.css";
import ColorClass from "types/enums/ColorClass";
import { Maybe } from "types/UtilityTypes";

type Props = {
  artistPillButton: Maybe<JSX.Element>;
  image: JSX.Element;
  otherInfo?: JSX.Element;
  title: JSX.Element | string;
};

export default function ListingCardGeneric({
  artistPillButton,
  image,
  otherInfo,
  title,
}: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <ImageWithArtistPillButton
          artistPillButton={artistPillButton}
          image={image}
        />
      </div>
      <div className={styles.info}>
        {typeof title === "string" ? (
          <ArtName className={styles.title} colorClass={ColorClass.Primary}>
            {title}
          </ArtName>
        ) : (
          <div className={styles.title}>{title}</div>
        )}
      </div>
      {otherInfo}
    </div>
  );
}
