import PlainButton from "components/buttons/PlainButton";
import Header from "components/header/Header";
import useSolanaContext from "hooks/useSolanaContext";
import nacl from "tweetnacl";
import logIfNotProd from "utils/logIfNotProd";

export default function LandingPage(): JSX.Element {
  const { anchorWallet } = useSolanaContext();

  return (
    <div>
      <Header />

      <PlainButton
        onClick={async () => {
          const message = "Pls sign";
          const encodedMessage = new TextEncoder().encode(message);
          // @ts-ignore
          const signedMessage = await anchorWallet.signMessage(
            encodedMessage,
            "utf8"
          );
          logIfNotProd("signed message:", signedMessage);
          const verification = nacl.sign.detached.verify(
            encodedMessage,
            signedMessage.signature,
            signedMessage.publicKey.toBytes()
          );
          logIfNotProd("verification", verification);
        }}
      >
        Sign message
      </PlainButton>
    </div>
  );
}
