import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import BN from "bn.js";
import getTradeState from "utils/solana/auction-house/getTradeState";

export default async function getFreeTradeState(
  walletSeller: anchor.web3.PublicKey,
  tokenAccount: anchor.web3.PublicKey,
  tokenMint: anchor.web3.PublicKey
): Promise<[PublicKey, number, BN]> {
  return getTradeState(walletSeller, tokenAccount, tokenMint, 0);
}
