import graphql from "babel-plugin-relay/macro";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { NftPageQuery } from "components/pages/common/nft/__generated__/NftPageQuery.graphql";
import { Suspense, useEffect, useMemo } from "react";
import { loadQuery, useLazyLoadQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import ColorValue from "types/enums/ColorValue";
import styles from "css/pages/common/nft/NftPage.module.css";
import Header2 from "components/text/Header2";
import NftInfo from "components/pages/common/nft/NftInfo";
import NftTags from "components/pages/common/nft/NftTags";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import { NftPageTxsQuery } from "components/pages/common/nft/__generated__/NftPageTxsQuery.graphql";
import RelayEnvironment from "utils/relay/RelayEnvironment";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import SolanaExplorerIcon from "components/icons/SolanaExplorerIcon";

const query = graphql`
  query NftPageQuery($input: MetadataAccountForMintInput!) {
    metadataAccountForMint(input: $input) {
      id

      offchainData {
        image
      }

      tags

      ...NftInfo_MetadataAccount
      ...NftTags_MetadataAccount
    }
  }
`;

export const txsQuery = graphql`
  query NftPageTxsQuery($nftTransactionsInput: NftTransactionsInput!) {
    nftTransactions(input: $nftTransactionsInput) {
      id
      ...NftTransactions_NftTransactionExpress
    }
  }
`;

function Inner({ mint }: { mint: string }): JSX.Element {
  const initialQueryRef = useMemo(
    () =>
      loadQuery<NftPageTxsQuery>(RelayEnvironment, txsQuery, {
        nftTransactionsInput: { mint },
      }),
    [mint]
  );

  const [nftTxsQueryRef, loadNftTxsQuery] = useQueryLoader<NftPageTxsQuery>(
    txsQuery,
    initialQueryRef
  );

  useEffect(() => {
    loadNftTxsQuery({ nftTransactionsInput: { mint } });
  }, [loadNftTxsQuery, mint]);

  const data = useLazyLoadQuery<NftPageQuery>(query, {
    input: { mint },
  });

  if (data.metadataAccountForMint == null) {
    return (
      <div>
        <Header2 textAlign="center">Could not find NFT</Header2>
        <Body1
          className={styles.couldNotFindDescription}
          colorClass={ColorClass.Secondary}
          textAlign="center"
        >
          If you just minted this NFT, please wait a few seconds, then try
          refreshing.
        </Body1>
      </div>
    );
  }

  return (
    <div className={styles.body}>
      <div className={styles.left}>
        <img
          className={styles.image}
          src={data.metadataAccountForMint.offchainData.image}
        />
        {/* TODO: tags should be links? */}
        {data.metadataAccountForMint.tags.length > 0 && (
          <div className={styles.tagsContainer}>
            <NftTags metadataAccount={data.metadataAccountForMint} />
          </div>
        )}
        <div className={styles.links}>
          <TextButton
            buttonTheme={TextButtonTheme.Secondary}
            fontClass={FontClass.NavLink}
            href={`https://explorer.solana.com/address/${mint}?cluster=${process.env.REACT_APP_SOLANA_NETWORK}`}
            icon={<SolanaExplorerIcon colorValue={ColorValue.Secondary} />}
            type="link_external"
          >
            View on Solana Explorer
          </TextButton>
        </div>
      </div>
      <div className={styles.info}>
        {nftTxsQueryRef != null && (
          <NftInfo
            metadataAccount={data.metadataAccountForMint}
            transactionsQueryRef={nftTxsQueryRef}
          />
        )}
      </div>
    </div>
  );
}

export default function NftPage(): JSX.Element {
  const params = useParams();

  return (
    <PageWithHeaderAndFooter>
      <ResponsivePageBody>
        <Suspense
          fallback={<LoadingSpinner colorValue={ColorValue.BrightPurple} />}
        >
          <Inner mint={params.mint as string} />
        </Suspense>
      </ResponsivePageBody>
    </PageWithHeaderAndFooter>
  );
}
