import {
  Context,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Maybe } from "types/UtilityTypes";
import axios from "axios";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

export type ExchangeRatesContextData = {
  lamportsToUsd: (lamports: number) => number;
  solToUsd: (sol: number) => number;
};

export const ExchangeRatesContext: Context<ExchangeRatesContextData> =
  createContext<ExchangeRatesContextData>({
    lamportsToUsd: () => 0,
    solToUsd: () => 0,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function ExchangeRatesContextProvider(
  props: ProviderProps
): JSX.Element {
  const [solRate, setSolRate] = useState<Maybe<number>>(null);
  const [_usdRate, setUsdRate] = useState<Maybe<number>>(null);
  const solToUsd = useCallback(
    (sol: number) => {
      if (solRate == null) {
        return 220;
      }
      const ratio = sol / solRate;
      return Number(ratio.toFixed(2));
    },
    [solRate]
  );
  const lamportsToUsd = useCallback(
    (lamports: number) => solToUsd(lamports / LAMPORTS_PER_SOL),
    [solToUsd]
  );

  useEffect(() => {
    async function run() {
      const prices = await axios.get(
        "https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=SOL,USD"
      );

      if (prices.status === 200) {
        setSolRate(prices.data.SOL);
        setUsdRate(prices.data.USD);
      }
    }

    run();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ExchangeRatesContext.Provider value={{ lamportsToUsd, solToUsd }}>
      {props.children}
    </ExchangeRatesContext.Provider>
  );
}
