import graphql from "babel-plugin-relay/macro";
import { ProfileButtonQuery } from "components/buttons/__generated__/ProfileButtonQuery.graphql";
import ProfileIcon from "components/icons/ProfileIcon";
import styles from "css/buttons/ProfileButton.module.css";
import useSolanaContext from "hooks/useSolanaContext";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import ColorValue from "types/enums/ColorValue";
import { Popover } from "antd";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import LeaveIcon from "components/icons/LeaveIcon";
import WalletAndAddressButton from "components/buttons/WalletAndAddressButton";
import { message } from "components/toast/messages";

const query = graphql`
  query ProfileButtonQuery($id: String!) {
    User_by_pk(id: $id) {
      id

      ProfilePhoto {
        id
        photoUrl
      }
    }
  }
`;

function PopoverContent(): JSX.Element {
  const { anchorWallet, setAnchorWallet } = useSolanaContext();
  return (
    <div className={styles.content}>
      <WalletAndAddressButton />
      <TextButton
        buttonTheme={TextButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        icon={<ProfileIcon colorValue={ColorValue.Primary} />}
        href="/profile"
        type="link_internal"
      >
        Go to profile
      </TextButton>
      <TextButton
        buttonTheme={TextButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        icon={<LeaveIcon colorValue={ColorValue.Primary} />}
        onClick={() => {
          anchorWallet!.disconnect();
          setAnchorWallet(null);
          message({ content: "Wallet disconnected" });
        }}
      >
        Disconnect wallet
      </TextButton>
    </div>
  );
}

function NoPicture(): JSX.Element {
  return (
    <Popover
      placement="bottomRight"
      content={<PopoverContent />}
      trigger="click"
    >
      <div className={styles.container}>
        <ProfileIcon colorValue={ColorValue.White} />
      </div>
    </Popover>
  );
}

function WithPicture(): JSX.Element {
  const { anchorWallet } = useSolanaContext();
  const data = useLazyLoadQuery<ProfileButtonQuery>(query, {
    id: anchorWallet?.publicKey.toString() ?? "",
  });

  const photoUrl = data.User_by_pk?.ProfilePhoto?.photoUrl;
  if (photoUrl != null) {
    return (
      <Popover
        placement="bottomRight"
        content={<PopoverContent />}
        trigger="click"
      >
        <img className={styles.container} src={photoUrl} />
      </Popover>
    );
  }

  return <NoPicture />;
}

export default function ProfileButton(): JSX.Element {
  return (
    <Suspense fallback={<NoPicture />}>
      <WithPicture />
    </Suspense>
  );
}
