/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AccountSetupModalUserMutationVariables = {
    email: string;
    id: string;
    username: string;
};
export type AccountSetupModalUserMutationResponse = {
    readonly insert_User_one: {
        readonly email: string;
        readonly id: string;
        readonly username: string;
    } | null;
};
export type AccountSetupModalUserMutation = {
    readonly response: AccountSetupModalUserMutationResponse;
    readonly variables: AccountSetupModalUserMutationVariables;
};



/*
mutation AccountSetupModalUserMutation(
  $email: String!
  $id: String!
  $username: String!
) {
  insert_User_one(object: {email: $email, id: $id, username: $username}) {
    email
    id
    username
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "username",
            "variableName": "username"
          }
        ],
        "kind": "ObjectValue",
        "name": "object"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "insert_User_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSetupModalUserMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountSetupModalUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "83604025b80d2d28c9f5f2252bba8f62",
    "id": null,
    "metadata": {},
    "name": "AccountSetupModalUserMutation",
    "operationKind": "mutation",
    "text": "mutation AccountSetupModalUserMutation(\n  $email: String!\n  $id: String!\n  $username: String!\n) {\n  insert_User_one(object: {email: $email, id: $id, username: $username}) {\n    email\n    id\n    username\n  }\n}\n"
  }
};
})();
(node as any).hash = '668411a5bd125689047cc171c282c286';
export default node;
