import SearchIcon from "components/icons/SearchIcon";
import styles from "css/header/HeaderDesktopSearch.module.css";
import { useState } from "react";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import HeaderTheme from "types/enums/HeaderTheme";
import joinClasses from "utils/joinClasses";

const THEME_TO_CLASS = {
  [HeaderTheme.Dark]: styles.secondary,
  [HeaderTheme.Light]: styles.white,
};

const THEME_TO_COLOR_VALUE = {
  [HeaderTheme.Dark]: ColorValue.Secondary,
  [HeaderTheme.Light]: ColorValue.White,
};

type Props = {
  headerTheme: HeaderTheme;
};

export default function HeaderDesktopSearch({
  headerTheme,
}: Props): JSX.Element {
  const [searchText, setSearchText] = useState("");

  return (
    <div className={styles.container}>
      <SearchIcon colorValue={THEME_TO_COLOR_VALUE[headerTheme]} />
      <input
        className={joinClasses(
          styles.input,
          FontClass.NavLink,
          THEME_TO_CLASS[headerTheme]
        )}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search for art..."
        value={searchText}
      />
    </div>
  );
}
