import ConnectWalletButton from "components/buttons/ConnectWalletButton";
import PlainButton from "components/buttons/PlainButton";
import { useLazyLoadQuery } from "react-relay";
import nacl from "tweetnacl";
import { Maybe } from "types/UtilityTypes";
import isProd from "utils/isProd";
import { TestingPageQuery } from "__generated__/TestingPageQuery.graphql";
import graphql from "babel-plugin-relay/macro";
import logIfNotProd from "utils/logIfNotProd";

const query = graphql`
  query TestingPageQuery {
    User {
      id
    }
  }
`;

export default function TestingPage(): Maybe<JSX.Element> {
  const _data = useLazyLoadQuery<TestingPageQuery>(query, {});

  if (isProd()) {
    return null;
  }

  return (
    <div>
      <ConnectWalletButton />
      <PlainButton
        onClick={async () => {
          const message = "Pls sign";
          const encodedMessage = new TextEncoder().encode(message);
          // @ts-ignore
          const signedMessage = await window.solana.signMessage(
            encodedMessage,
            "utf8"
          );
          logIfNotProd("signed message", signedMessage);
          const verification = nacl.sign.detached.verify(
            encodedMessage,
            signedMessage.signature,
            signedMessage.publicKey.toBytes()
          );
          logIfNotProd("verification", verification);
        }}
      >
        Sign message
      </PlainButton>
    </div>
  );
}
