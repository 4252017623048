import Popover from "antd/lib/popover";
import ButtonWithText from "components/buttons/ButtonWithText";
import CheckboxButtonWithLabel from "components/buttons/CheckboxButtonWithLabel";
import RadioButtonWithLabel from "components/buttons/RadioButtonWithLabel";
import FilterIcon from "components/icons/FilterIcon";
import PriceInput from "components/input/PriceInput";
import ArtName from "components/text/ArtName";
import NavLink from "components/text/NavLink";
import { message } from "components/toast/messages";
import styles from "css/buttons/FiltersButton.module.css";
import useExploreContext from "hooks/useExploreContext";
import { useState } from "react";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import ExploreAvailability from "types/enums/ExploreAvailability";
import ExploreMarket from "types/enums/ExploreMarket";
import FontClass from "types/enums/FontClass";

function PopoverContentSection({
  children,
  title,
}: {
  children: any;
  title: string;
}): JSX.Element {
  return (
    <div>
      <ArtName colorClass={ColorClass.Primary}>{title}</ArtName>
      <div className={styles.popoverContentSectionContent}>{children}</div>
    </div>
  );
}

function PopoverContent(): JSX.Element {
  const {
    availability,
    highPriceInSol,
    lowPriceInSol,
    primaryMarket,
    secondaryMarket,
    setAvailability,
    setHighPriceInSol,
    setLowPriceInSol,
    setPrimaryMarket,
    setSecondaryMarket,
  } = useExploreContext();
  const [highPrice, setHighPrice] = useState(highPriceInSol?.toString() ?? "");
  const [lowPrice, setLowPrice] = useState(lowPriceInSol?.toString() ?? "");

  const priceSection = (
    <PopoverContentSection title="Price range">
      <div className={styles.priceInputs}>
        <PriceInput
          placeholder="Low price"
          priceInSol={lowPrice}
          setPrice={setLowPrice}
          showUsdPrice={false}
        />
        <PriceInput
          placeholder="High price"
          priceInSol={highPrice}
          setPrice={setHighPrice}
          showUsdPrice={false}
        />
      </div>
      <ButtonWithText
        buttonTheme={ButtonTheme.BrightPurpleOutline}
        className={styles.setPriceButton}
        fontClass={FontClass.NavLink}
        onClick={() => {
          const highPriceToUse =
            highPrice.length === 0 ? null : Number(highPrice);
          const lowPriceToUse = lowPrice.length === 0 ? null : Number(lowPrice);
          setHighPriceInSol(highPriceToUse);
          setLowPriceInSol(lowPriceToUse);
          message({ content: "Price set!", duration: 1 });
        }}
      >
        Set price
      </ButtonWithText>
    </PopoverContentSection>
  );

  const availabilitySection = (
    <PopoverContentSection title="Availability">
      <div className={styles.availability}>
        {Object.values(ExploreAvailability).map((val) => (
          <RadioButtonWithLabel
            key={val}
            isActive={availability === val}
            label={val}
            onClick={() => setAvailability(val)}
          />
        ))}
      </div>
    </PopoverContentSection>
  );

  const marketSection = (
    <PopoverContentSection title="Market">
      <div className={styles.market}>
        <CheckboxButtonWithLabel
          isActive={primaryMarket}
          label={ExploreMarket.Primary}
          onClick={() => setPrimaryMarket(!primaryMarket)}
        />
        <CheckboxButtonWithLabel
          isActive={secondaryMarket}
          label={ExploreMarket.Secondary}
          onClick={() => setSecondaryMarket(!secondaryMarket)}
        />
      </div>
    </PopoverContentSection>
  );

  return (
    <div className={styles.popoverContent}>
      {priceSection}
      {availabilitySection}
      {marketSection}
    </div>
  );
}

export default function FiltersButton(): JSX.Element {
  return (
    <Popover
      content={<PopoverContent />}
      placement="bottomRight"
      trigger="click"
    >
      <div className={styles.button}>
        <FilterIcon colorValue={ColorValue.Primary} />
        <NavLink colorClass={ColorClass.Primary}>Filters</NavLink>
      </div>
    </Popover>
  );
}
