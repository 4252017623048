import { NftStatus_enum } from "components/auction/__generated__/NftOtherInfo_MetadataAccount.graphql";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { Maybe } from "types/UtilityTypes";
import getNftExpressDataId from "utils/relay/getNftExpressDataId";

export default function updateNftRecord<T>(
  store: RecordSourceSelectorProxy<T>,
  mint: string,
  priceInLamports: Maybe<number>,
  status: NftStatus_enum
): void {
  const nftRecord = store.get(getNftExpressDataId(mint));
  nftRecord?.setValue(priceInLamports, "price");
  // TODO: this is a bit annoying... need to update here b/c Nft and NftExpress
  // are different
  nftRecord?.setValue(status, "status");
}
