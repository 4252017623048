/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftTransactions_NftTransactionExpress = ReadonlyArray<{
    readonly " $fragmentRefs": FragmentRefs<"NftTransaction_NftTransactionExpress">;
    readonly " $refType": "NftTransactions_NftTransactionExpress";
}>;
export type NftTransactions_NftTransactionExpress$data = NftTransactions_NftTransactionExpress;
export type NftTransactions_NftTransactionExpress$key = ReadonlyArray<{
    readonly " $data"?: NftTransactions_NftTransactionExpress$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NftTransactions_NftTransactionExpress">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "NftTransactions_NftTransactionExpress",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NftTransaction_NftTransactionExpress"
    }
  ],
  "type": "NftTransactionExpress",
  "abstractKey": null
};
(node as any).hash = 'ad52f533143da0990616b23ad6638ea5';
export default node;
