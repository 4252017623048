import useSolanaContext from "hooks/useSolanaContext";
import HeaderTheme from "types/enums/HeaderTheme";
import styles from "css/header/HeaderDesktop.module.css";
import TextButtonTheme from "types/enums/TextButtonTheme";
import ColorClass from "types/enums/ColorClass";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Header1 from "components/text/Header1";
import joinClasses from "utils/joinClasses";
import HeaderDesktopSearch from "components/header/HeaderDesktopSearch";
import TextButton from "components/buttons/TextButton";
import FontClass from "types/enums/FontClass";
import ActivityButton from "components/buttons/ActivityButton";
import ProfileButton from "components/buttons/ProfileButton";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import ConnectWalletButton from "components/buttons/ConnectWalletButton";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { HeaderDesktopQuery } from "components/header/__generated__/HeaderDesktopQuery.graphql";
import { Suspense, useEffect } from "react";
import { Maybe } from "types/UtilityTypes";
import useUserContext from "hooks/useUserContext";

const query = graphql`
  query HeaderDesktopQuery($id: String!) {
    User_by_pk(id: $id) {
      id
    }
  }
`;

function RightSection({
  buttonTheme,
}: {
  buttonTheme: TextButtonTheme;
}): Maybe<JSX.Element> {
  const { anchorWallet } = useSolanaContext();
  const data = useLazyLoadQuery<HeaderDesktopQuery>(query, {
    id: anchorWallet?.publicKey.toString() ?? "",
  });
  const { setUserId } = useUserContext();

  useEffect(() => {
    if (anchorWallet !== undefined) {
      const id = data.User_by_pk?.id;
      setUserId(id ?? null);
    }
  }, [anchorWallet, data.User_by_pk?.id, setUserId]);

  const connectedRightSection = (
    <>
      <TextButton
        buttonTheme={buttonTheme}
        fontClass={FontClass.NavLink}
        href="/explore"
        type="link_internal"
      >
        Explore
      </TextButton>
      <ActivityButton />
      <ProfileButton />
      <ButtonWithText
        buttonTheme={ButtonTheme.PurpleGradient}
        fontClass={FontClass.NavLink}
        href="/create"
        type="link_internal"
      >
        Create
      </ButtonWithText>
    </>
  );

  const disconnectedRightSection = (
    <>
      <TextButton
        buttonTheme={buttonTheme}
        fontClass={FontClass.NavLink}
        href="/explore"
        type="link_internal"
      >
        Explore
      </TextButton>
      <TextButton
        buttonTheme={buttonTheme}
        fontClass={FontClass.NavLink}
        href="/about"
        type="link_internal"
      >
        About
      </TextButton>
      <ConnectWalletButton />
    </>
  );

  if (anchorWallet === undefined) {
    return null;
  }

  return anchorWallet?.publicKey == null || data.User_by_pk == null
    ? disconnectedRightSection
    : connectedRightSection;
}

type Props = {
  headerTheme: HeaderTheme;
};

export default function HeaderDesktop({ headerTheme }: Props): JSX.Element {
  const buttonTheme =
    headerTheme === HeaderTheme.Light
      ? TextButtonTheme.White
      : TextButtonTheme.Primary;
  const fontColor =
    headerTheme === HeaderTheme.Light ? ColorClass.White : ColorClass.Primary;

  return (
    <ResponsiveContainer>
      <Header1 className={styles.header} textAlign="center">
        <div className={styles.left}>
          <a href="/" style={{ textDecoration: "none" }}>
            <h3 className={joinClasses(styles.logo, fontColor)}>formf(n)</h3>
          </a>
          <HeaderDesktopSearch headerTheme={headerTheme} />
        </div>
        <div className={styles.right}>
          <Suspense fallback={<div />}>
            <RightSection buttonTheme={buttonTheme} />
          </Suspense>
        </div>
      </Header1>
    </ResponsiveContainer>
  );
}
