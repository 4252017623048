import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import {
  AUCTION_HOUSE,
  AUCTION_HOUSE_PROGRAM_ID,
} from "constants/SolanaConstants";

export default async function findAuctionHouseBuyerEscrow(
  auctionHouse: anchor.web3.PublicKey,
  wallet: anchor.web3.PublicKey
): Promise<[PublicKey, number]> {
  return anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(AUCTION_HOUSE), auctionHouse.toBuffer(), wallet.toBuffer()],
    AUCTION_HOUSE_PROGRAM_ID
  );
}
