import {
  Context,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import * as anchor from "@project-serum/anchor";

import {
  AnchorWallet,
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  getPhantomWallet,
  getSolflareWallet,
  Wallet,
} from "@solana/wallet-adapter-wallets";

import emptyFunction from "utils/emptyFunction";
import getRpcHostFromNetwork from "utils/solana/misc/getRpcHostFromNetwork";
import { Maybe, MaybeUndef } from "types/UtilityTypes";
import { PublicKey } from "@solana/web3.js";
import setWithPrefix from "utils/local-storage/setWithPrefix";
import LocalStoragePrefix from "types/enums/LocalStoragePrefix";
import Network from "types/enums/Network";
import loadAuctionHouseProgramWithWallet from "utils/solana/programs/loadAuctionHouseProgramWithWallet";
import AuctionHouseProgram from "types/AuctionHouseProgram";

const defaultNetwork = process.env.REACT_APP_SOLANA_NETWORK as Network;
const defaultRpcHost = getRpcHostFromNetwork(defaultNetwork);
const defaultConnection = new anchor.web3.Connection(defaultRpcHost);
const txTimeout = 30000; // milliseconds (confirm this works for your project)

export type MyAnchorWallet = AnchorWallet & {
  disconnect: () => void;
  signMessage: (
    data: Uint8Array,
    dataType: "utf8" | "hex"
  ) => Promise<{ publicKey: PublicKey; signature: Uint8Array }>;
  wallet: Wallet;
};

export type SolanaContextData = {
  anchorWallet: MaybeUndef<MyAnchorWallet>;
  auctionHouseProgram: Maybe<AuctionHouseProgram>;
  connection: anchor.web3.Connection;
  network: Network;
  txTimeout: number;

  setAnchorWallet: (val: MaybeUndef<MyAnchorWallet>) => void;
  setNetwork: (val: Network) => void;
};

export const SolanaContext: Context<SolanaContextData> =
  createContext<SolanaContextData>({
    anchorWallet: null,
    auctionHouseProgram: null,
    connection: defaultConnection,
    network: defaultNetwork,
    txTimeout,

    setAnchorWallet: emptyFunction,
    setNetwork: emptyFunction,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function SolanaContextProvider(props: ProviderProps): JSX.Element {
  const [anchorWallet, setAnchorWalletOriginal] =
    useState<MaybeUndef<MyAnchorWallet>>(undefined);
  const [auctionHouseProgram, setAuctionHouseProgram] =
    useState<Maybe<AuctionHouseProgram>>(null);
  const [network, setNetwork] = useState(defaultNetwork);
  const endpoint = useMemo(() => getRpcHostFromNetwork(network), [network]);
  const connection = useMemo(
    () => new anchor.web3.Connection(getRpcHostFromNetwork(network)),
    [network]
  );

  const setAnchorWallet = useCallback(
    (val: MaybeUndef<MyAnchorWallet>) => {
      async function run(wallet: AnchorWallet) {
        const program = await loadAuctionHouseProgramWithWallet(
          wallet,
          network,
          getRpcHostFromNetwork(network)
        );

        setAuctionHouseProgram(program);
      }

      setAnchorWalletOriginal(val);
      if (val != null) {
        setWithPrefix(
          LocalStoragePrefix.PublicKey,
          "",
          val.publicKey.toString()
        );

        run(val);
      }
    },
    [network]
  );

  useEffect(() => {
    let tries = 0;

    async function run() {
      if (window.solana == null && tries < 10) {
        tries++;
        setTimeout(() => run(), 100);
        return;
      }

      try {
        // Will either automatically connect to Phantom, or do nothing.
        const { publicKey } = await window.solana.connect({
          onlyIfTrusted: true,
        });
        setAnchorWallet({
          disconnect: window.solana.disconnect,
          publicKey,
          signMessage: window.solana.signMessage,
          signTransaction: window.solana.signTransaction,
          signAllTransactions: window.solana.signAllTransactions,
          wallet: getPhantomWallet(),
        });
      } catch (e) {
        // Do nothing
        setAnchorWallet(null);
      }
    }

    run();
  }, [setAnchorWallet]);

  const wallets = useMemo(
    () => [
      // If we add the sollet wallets, the "change network" feature + useAnchorWallet causes a bunch of re-renders
      getPhantomWallet(),
      // getSolletWallet({ network }),
      getSolflareWallet(),
      // getSlopeWallet(),
      // getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <SolanaContext.Provider
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          value={{
            anchorWallet,
            auctionHouseProgram,
            connection,
            network,
            txTimeout,

            setAnchorWallet,
            setNetwork,
          }}
        >
          {props.children}
        </SolanaContext.Provider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
