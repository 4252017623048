import ArtistPillButton from "components/buttons/ArtistPillButton";
import { ViewerArtistPillButtonQuery } from "components/buttons/__generated__/ViewerArtistPillButtonQuery.graphql";
import useSolanaContext from "hooks/useSolanaContext";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import styles from "css/buttons/ArtistPillButton.module.css";
import graphql from "babel-plugin-relay/macro";

const query = graphql`
  query ViewerArtistPillButtonQuery($id: String!) {
    User_by_pk(id: $id) {
      id
      username

      ProfilePhoto {
        id
        photoUrl
      }
    }
  }
`;

function Inner() {
  const { anchorWallet } = useSolanaContext();
  const data = useLazyLoadQuery<ViewerArtistPillButtonQuery>(query, {
    id: anchorWallet?.publicKey.toString() ?? "",
  });

  return (
    <ArtistPillButton
      name={data.User_by_pk!.username}
      src={data.User_by_pk!.ProfilePhoto?.photoUrl}
    />
  );
}

export default function ViewerArtistPillButton(): JSX.Element {
  return (
    <Suspense fallback={<div className={styles.button} />}>
      <Inner />
    </Suspense>
  );
}
