import ListingCardBidInfo from "components/auction/ListingCardBidInfo";
import ListingCardGeneric from "components/auction/ListingCardGeneric";
import ImageCloudflare from "components/images/ImageCloudflare";
import styles from "css/auction/ListingCardLanding.module.css";
import { Dayjs } from "dayjs";

type Props = {
  artistPillButton: JSX.Element;
  endDate: Dayjs;
  imageSrc: string;
  price: number;
  title: string;
};

export default function ListingCardLanding({
  artistPillButton,
  endDate,
  imageSrc,
  price,
  title,
}: Props): JSX.Element {
  const image = <ImageCloudflare className={styles.image} src={imageSrc} />;

  return (
    <ListingCardGeneric
      artistPillButton={artistPillButton}
      image={image}
      otherInfo={<ListingCardBidInfo endDate={endDate} price={price} />}
      title={title}
    />
  );
}
