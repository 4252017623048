import { Cluster } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { Program, web3 } from "@project-serum/anchor";
import { AUCTION_HOUSE_PROGRAM_ID } from "constants/SolanaConstants";
import logIfNotProd from "utils/logIfNotProd";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { AuctionHouse } from "types/AuctionHouse";

export default async function loadAuctionHouseProgramWithWallet(
  wallet: AnchorWallet,
  env: Cluster,
  customRpcUrl?: string
) {
  if (customRpcUrl) {
    logIfNotProd("USING CUSTOM URL", customRpcUrl);
  }

  // @ts-ignore
  const solConnection = new anchor.web3.Connection(
    // @ts-ignore
    customRpcUrl || web3.clusterApiUrl(env)
  );
  const provider = new anchor.Provider(solConnection, wallet, {
    preflightCommitment: "recent",
  });
  const idl = await anchor.Program.fetchIdl(AUCTION_HOUSE_PROGRAM_ID, provider);

  return new anchor.Program(
    idl!,
    AUCTION_HOUSE_PROGRAM_ID,
    provider
  ) as Program<AuctionHouse>;
}
