/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftStatus_enum = "Auction" | "Listed" | "Owned" | "%future added value";
export type ChangePriceModalMutationVariables = {
    creator: string;
    lister: string;
    mint: string;
    price: unknown;
    txid: string;
};
export type ChangePriceModalMutationResponse = {
    readonly update_Nft_by_pk: {
        readonly id: string;
        readonly creatorId: string;
        readonly mint: string;
        readonly priceInLamports: unknown | null;
        readonly status: NftStatus_enum | null;
    } | null;
    readonly insertNftTransaction: {
        readonly " $fragmentRefs": FragmentRefs<"NftTransaction_NftTransactionExpress">;
    };
};
export type ChangePriceModalMutation = {
    readonly response: ChangePriceModalMutationResponse;
    readonly variables: ChangePriceModalMutationVariables;
};



/*
mutation ChangePriceModalMutation(
  $creator: String!
  $lister: String!
  $mint: String!
  $price: bigint!
  $txid: String!
) {
  update_Nft_by_pk(pk_columns: {id: $mint}, _set: {priceInLamports: $price, status: Listed}) {
    id
    creatorId
    mint
    priceInLamports
    status
  }
  insertNftTransaction(input: {creatorId: $creator, fromUserId: $lister, toUserId: $lister, mint: $mint, price: $price, txid: $txid, type: Listed}) {
    ...NftTransaction_NftTransactionExpress
  }
}

fragment NftTransaction_NftTransactionExpress on NftTransactionExpress {
  id
  fromAddress
  mint
  priceInLamports
  timeCreated
  toAddress
  txid
  type
  From {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
  To {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "creator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lister"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mint"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "price"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "txid"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mint",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceInLamports",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "priceInLamports",
          "variableName": "price"
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": "Listed"
        }
      ],
      "kind": "ObjectValue",
      "name": "_set"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "mint"
        }
      ],
      "kind": "ObjectValue",
      "name": "pk_columns"
    }
  ],
  "concreteType": "Nft",
  "kind": "LinkedField",
  "name": "update_Nft_by_pk",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorId",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "creatorId",
        "variableName": "creator"
      },
      {
        "kind": "Variable",
        "name": "fromUserId",
        "variableName": "lister"
      },
      {
        "kind": "Variable",
        "name": "mint",
        "variableName": "mint"
      },
      {
        "kind": "Variable",
        "name": "price",
        "variableName": "price"
      },
      {
        "kind": "Variable",
        "name": "toUserId",
        "variableName": "lister"
      },
      {
        "kind": "Variable",
        "name": "txid",
        "variableName": "txid"
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": "Listed"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v6 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Photo",
    "kind": "LinkedField",
    "name": "ProfilePhoto",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePriceModalMutation",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "NftTransactionExpress",
        "kind": "LinkedField",
        "name": "insertNftTransaction",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NftTransaction_NftTransactionExpress"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePriceModalMutation",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "NftTransactionExpress",
        "kind": "LinkedField",
        "name": "insertNftTransaction",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromAddress",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "txid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "From",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "To",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c8b44dae5c5a868582b40885c5efc117",
    "id": null,
    "metadata": {},
    "name": "ChangePriceModalMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePriceModalMutation(\n  $creator: String!\n  $lister: String!\n  $mint: String!\n  $price: bigint!\n  $txid: String!\n) {\n  update_Nft_by_pk(pk_columns: {id: $mint}, _set: {priceInLamports: $price, status: Listed}) {\n    id\n    creatorId\n    mint\n    priceInLamports\n    status\n  }\n  insertNftTransaction(input: {creatorId: $creator, fromUserId: $lister, toUserId: $lister, mint: $mint, price: $price, txid: $txid, type: Listed}) {\n    ...NftTransaction_NftTransactionExpress\n  }\n}\n\nfragment NftTransaction_NftTransactionExpress on NftTransactionExpress {\n  id\n  fromAddress\n  mint\n  priceInLamports\n  timeCreated\n  toAddress\n  txid\n  type\n  From {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n  To {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e930b6055d0c55149eaacdb76f53364e';
export default node;
