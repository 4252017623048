import Header from "components/header/Header";
import LandingPreFooter from "components/pages/landing/pre/LandingPreFooter";
import styles from "css/containers/PageWithHeaderAndFooter.module.css";

type Props = {
  children: any;
};

export default function PageWithHeaderAndFooter({
  children,
}: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.body}>{children}</div>
      <LandingPreFooter />
    </div>
  );
}
