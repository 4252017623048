import styles from "css/buttons/ListNftButton.module.css";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import { useState } from "react";
import ListNftModal from "components/modal/ListNftModal";
import graphql from "babel-plugin-relay/macro";
import { ListNftButton_MetadataAccount$key } from "components/buttons/__generated__/ListNftButton_MetadataAccount.graphql";
import { useFragment } from "react-relay";

const fragment = graphql`
  fragment ListNftButton_MetadataAccount on MetadataAccount {
    id
    ...ListNftModal_MetadataAccount
  }
`;

type Props = {
  metadataAccount: ListNftButton_MetadataAccount$key;
};

export default function ListNftButton({ metadataAccount }: Props): JSX.Element {
  const [isShown, setIsShown] = useState(false);
  const metadataAccountData = useFragment(fragment, metadataAccount);

  return (
    <>
      <ListNftModal
        isShown={isShown}
        metadataAccount={metadataAccountData}
        onHide={() => setIsShown(false)}
      />
      <ButtonWithText
        buttonTheme={ButtonTheme.PurpleGradient}
        className={styles.button}
        fontClass={FontClass.NavLink}
        onClick={() => setIsShown(true)}
      >
        List for auction
      </ButtonWithText>
    </>
  );
}
