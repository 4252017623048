/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftStatus_enum = "Auction" | "Listed" | "Owned" | "%future added value";
export type NftInfo_MetadataAccount = {
    readonly id: string;
    readonly mint: unknown;
    readonly nft: {
        readonly id: string;
        readonly creatorId: string;
        readonly isOffPlatform: boolean;
        readonly price: unknown | null;
        readonly Creator: {
            readonly id: string;
            readonly username: string;
            readonly ProfilePhoto: {
                readonly id: unknown;
                readonly photoUrl: string;
            } | null;
        } | null;
        readonly Owner: {
            readonly id: string;
            readonly username: string;
            readonly ProfilePhoto: {
                readonly id: unknown;
                readonly photoUrl: string;
            } | null;
        } | null;
        readonly ownerId: string;
        readonly status: NftStatus_enum;
    };
    readonly data: {
        readonly name: string;
    };
    readonly offchainData: {
        readonly description: string | null;
        readonly image: string;
    };
    readonly " $fragmentRefs": FragmentRefs<"NftEllipsisShadowButton_MetadataAccount" | "NftPrice_MetadataAccount" | "NftActionButton_MetadataAccount">;
    readonly " $refType": "NftInfo_MetadataAccount";
};
export type NftInfo_MetadataAccount$data = NftInfo_MetadataAccount;
export type NftInfo_MetadataAccount$key = {
    readonly " $data"?: NftInfo_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NftInfo_MetadataAccount">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Photo",
    "kind": "LinkedField",
    "name": "ProfilePhoto",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NftInfo_MetadataAccount",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NftExpress",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOffPlatform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "Creator",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "Owner",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetadataAccountData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetadataOffchain",
      "kind": "LinkedField",
      "name": "offchainData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NftEllipsisShadowButton_MetadataAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NftPrice_MetadataAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NftActionButton_MetadataAccount"
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
})();
(node as any).hash = 'f0972bd73d5f3b9a876b6acd561b2f18';
export default node;
