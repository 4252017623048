/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TestingPageQueryVariables = {};
export type TestingPageQueryResponse = {
    readonly User: ReadonlyArray<{
        readonly id: string;
    }>;
};
export type TestingPageQuery = {
    readonly response: TestingPageQueryResponse;
    readonly variables: TestingPageQueryVariables;
};



/*
query TestingPageQuery {
  User {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "User",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TestingPageQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TestingPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "73a25a0424a8da500fc72e3b570c3efb",
    "id": null,
    "metadata": {},
    "name": "TestingPageQuery",
    "operationKind": "query",
    "text": "query TestingPageQuery {\n  User {\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ad2fd04e45856d2a8ea29053086fe0e3';
export default node;
