import { PublicKey } from "@solana/web3.js";

export const CANDY_MACHINE = "candy_machine";
export const AUCTION_HOUSE = "auction_house";
export const FEE_PAYER = "fee_payer";
export const TREASURY = "treasury";
export const MAX_NAME_LENGTH = 32;
export const MAX_URI_LENGTH = 200;
export const MAX_SYMBOL_LENGTH = 10;
export const MAX_CREATOR_LEN = 32 + 1 + 1;
export const ARWEAVE_PAYMENT_WALLET = new PublicKey(
  "6FKvsq4ydWFci6nGq9ckbjYMtnmaqAoatz5c9XWjiDuS"
);
export const CANDY_MACHINE_PROGRAM_ID = new PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);
export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);
export const FAIR_LAUNCH_PROGRAM_ID = new PublicKey(
  "faircnAB9k59Y4TXmLabBULeuTLgV7TkGMGNkjnA15j"
);
// export const AUCTION_HOUSE_PROGRAM_ID = new PublicKey(
//   "hausS13jsjafwWwGqZTUQRmWyvyxn9EQpqMwV1PBBmk"
// );

// This is the custom progrma
export const AUCTION_HOUSE_PROGRAM_ID = new PublicKey(
  "2x1UiAEX5s81T8Y5LfKsrnFm4nEswmGiKooNUY3zbMeJ"
);
export const WRAPPED_SOL_MINT = new PublicKey(
  "So11111111111111111111111111111111111111112"
);

export const CONFIG_ARRAY_START =
  32 + // authority
  4 +
  6 + // uuid + u32 len
  4 +
  10 + // u32 len + symbol
  2 + // seller fee basis points
  1 +
  4 +
  5 * 34 + // optional + u32 len + actual vec
  8 + // max supply
  1 + // is mutable
  1 + // retain authority
  4; // max number of lines;
export const CONFIG_LINE_SIZE = 4 + 32 + 4 + 200;

export const CACHE_PATH = "./.cache";

export const DEFAULT_TIMEOUT = 15000;

export const EXTENSION_PNG = ".png";
export const EXTENSION_JSON = ".json";

export const AUCTION_HOUSE_FORMFN = new PublicKey(
  "6krn1FQYZfyZrqhVKphDE2oFKrrKyPPZcYF8gR7bq5ff"
);
export const AUCTION_HOUSE_AUTHORITY = new PublicKey(
  "HSF7CpC5JwthGi6rDymX6hXUvVKDKDthKSb5gME15EWx"
);
export const AUCTION_HOUSE_TREASURY_MINT = WRAPPED_SOL_MINT;
