import useSolanaContext from "hooks/useSolanaContext";
import { useEffect, useState } from "react";
import { MaybeUndef } from "types/UtilityTypes";

export default function useWalletBalance(): MaybeUndef<number> {
  const { anchorWallet, connection } = useSolanaContext();
  const [balance, setBalance] = useState<MaybeUndef<number>>(undefined);

  useEffect(() => {
    async function run() {
      const publicKey = anchorWallet?.publicKey;
      if (publicKey != null) {
        const accountInfo = await connection.getAccountInfo(publicKey);
        setBalance(accountInfo?.lamports ?? null);
      }
    }

    run();
  }, [anchorWallet?.publicKey, connection]);

  return balance;
}
