import { Context, createContext, useState } from "react";

import { MaybeUndef } from "types/UtilityTypes";
import emptyFunction from "utils/emptyFunction";

export type UserContextData = {
  userId: MaybeUndef<string>;

  setUserId: (val: MaybeUndef<string>) => void;
};

export const UserContext: Context<UserContextData> =
  createContext<UserContextData>({
    userId: null,

    setUserId: emptyFunction,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function UserContextProvider(props: ProviderProps): JSX.Element {
  const [userId, setUserId] = useState<MaybeUndef<string>>(undefined);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UserContext.Provider value={{ userId, setUserId }}>
      {props.children}
    </UserContext.Provider>
  );
}
