import CopyAddressButton from "components/buttons/CopyAddressButton";
import Body1 from "components/text/Body1";
import TinyLabel from "components/text/TinyLabel";
import SOL_SYMBOL from "constants/SolSymbol";
import styles from "css/solana/WalletBalance.module.css";
import useExchangeRatesContext from "hooks/useExchangeRatesContext";
import useSolanaContext from "hooks/useSolanaContext";
import useWalletBalance from "hooks/useWalletBalance";
import ColorClass from "types/enums/ColorClass";
import formatLamports from "utils/formatLamports";

export default function WalletBalance(): JSX.Element {
  const { anchorWallet } = useSolanaContext();
  const balance = useWalletBalance();
  const { lamportsToUsd } = useExchangeRatesContext();

  return (
    <div className={styles.container}>
      <div className={styles.row1}>
        <TinyLabel colorClass={ColorClass.Primary} textTransform="uppercase">
          Your Wallet Balance
        </TinyLabel>
        <CopyAddressButton address={anchorWallet!.publicKey.toString()} />
      </div>
      <div className={styles.row2}>
        <Body1 colorClass={ColorClass.Primary}>
          {balance == null ? (
            <>&nbsp;</>
          ) : (
            `${formatLamports(balance)} ${SOL_SYMBOL} (~$${lamportsToUsd(
              balance
            )} USD)`
          )}
        </Body1>
      </div>
    </div>
  );
}
