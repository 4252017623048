/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftEllipsisShadowButton_MetadataAccount = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"EditTagsModal_MetadataAccount" | "CancelListingModal_MetadataAccount" | "ChangePriceModal_MetadataAccount">;
    readonly " $refType": "NftEllipsisShadowButton_MetadataAccount";
};
export type NftEllipsisShadowButton_MetadataAccount$data = NftEllipsisShadowButton_MetadataAccount;
export type NftEllipsisShadowButton_MetadataAccount$key = {
    readonly " $data"?: NftEllipsisShadowButton_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NftEllipsisShadowButton_MetadataAccount">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NftEllipsisShadowButton_MetadataAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditTagsModal_MetadataAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelListingModal_MetadataAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangePriceModal_MetadataAccount"
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
(node as any).hash = 'f7ef85907af5000da0a946511f52907a';
export default node;
