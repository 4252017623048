import graphql from "babel-plugin-relay/macro";
import ActivityTransaction from "components/pages/activity/ActivityTransaction";
import { ActivityTransactionsQuery } from "components/pages/activity/__generated__/ActivityTransactionsQuery.graphql";
import { useLazyLoadQuery } from "react-relay";
import styles from "css/pages/activity/ActivityTransactions.module.css";
import useSolanaContext from "hooks/useSolanaContext";

const query = graphql`
  query ActivityTransactionsQuery($input: ActivityTransactionsInput!) {
    activityTransactions(input: $input) {
      id
      ...ActivityTransaction_ActivityTransaction
    }
  }
`;

export default function ActivityTransactions(): JSX.Element {
  const { anchorWallet } = useSolanaContext();

  const data = useLazyLoadQuery<ActivityTransactionsQuery>(query, {
    input: {
      creator: anchorWallet?.publicKey.toString(),
      from: anchorWallet?.publicKey.toString(),
      to: anchorWallet?.publicKey.toString(),
    },
  });

  return (
    <div className={styles.transactions}>
      {data.activityTransactions.map((activityTransaction) => (
        <ActivityTransaction
          key={activityTransaction.id as string}
          activityTransaction={activityTransaction}
        />
      ))}
    </div>
  );
}
