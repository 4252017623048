import { Popover } from "antd";
import EllipsisShadowButton from "components/buttons/EllipsisShadowButton";
import TextButton from "components/buttons/TextButton";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";
import emptyFunction from "utils/emptyFunction";
import styles from "css/buttons/NftEllipsisShadowButton.module.css";
import TagIcon from "components/icons/TagIcon";
import ColorValue from "types/enums/ColorValue";
import MoneyIcon from "components/icons/MoneyIcon";
import CornerUpLeftIcon from "components/icons/CornerUpLeftIcon";
import { useState } from "react";
import EditTagsModal from "components/modal/EditTagsModal";
import graphql from "babel-plugin-relay/macro";
import { NftEllipsisShadowButton_MetadataAccount$key } from "components/buttons/__generated__/NftEllipsisShadowButton_MetadataAccount.graphql";
import { useFragment } from "react-relay";
import CancelListingModal from "components/modal/CancelListingModal";
import ChangePriceModal from "components/modal/ChangePriceModal";

type PopoverContentProps = {
  onClickChangePrice: () => void;
  onClickEditTags: () => void;
  onClickUnlist: () => void;
};

function PopoverContent({
  onClickChangePrice,
  onClickEditTags,
  onClickUnlist,
}: PopoverContentProps): JSX.Element {
  return (
    <div className={styles.popoverContent}>
      <TextButton
        buttonTheme={TextButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        icon={<TagIcon colorValue={ColorValue.Primary} />}
        onClick={onClickEditTags}
      >
        Edit tags
      </TextButton>
      <TextButton
        buttonTheme={TextButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        icon={<MoneyIcon colorValue={ColorValue.Primary} />}
        onClick={onClickChangePrice}
      >
        Change reserve price
      </TextButton>
      <TextButton
        buttonTheme={TextButtonTheme.Primary}
        fontClass={FontClass.NavLink}
        icon={<CornerUpLeftIcon colorValue={ColorValue.Primary} />}
        onClick={onClickUnlist}
      >
        Unlist NFT
      </TextButton>
    </div>
  );
}

const fragment = graphql`
  fragment NftEllipsisShadowButton_MetadataAccount on MetadataAccount {
    id

    ...EditTagsModal_MetadataAccount
    ...CancelListingModal_MetadataAccount
    ...ChangePriceModal_MetadataAccount
  }
`;

type Props = {
  metadataAccount: NftEllipsisShadowButton_MetadataAccount$key;
};

export default function NftEllipsisShadowButton({
  metadataAccount,
}: Props): JSX.Element {
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const [isEditTagsModalShown, setIsEditTagsModalShown] = useState(false);
  const [isCancelListingModalShown, setIsCancelListingModalShown] =
    useState(false);
  const [isChangePriceModalShown, setIsChangePriceModalShown] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <EditTagsModal
        isShown={isEditTagsModalShown}
        metadataAccount={metadataAccountData}
        onHide={() => setIsEditTagsModalShown(false)}
      />
      <CancelListingModal
        isShown={isCancelListingModalShown}
        metadataAccount={metadataAccountData}
        onHide={() => setIsCancelListingModalShown(false)}
      />
      <ChangePriceModal
        isShown={isChangePriceModalShown}
        metadataAccount={metadataAccountData}
        onHide={() => setIsChangePriceModalShown(false)}
      />
      <Popover
        placement="leftTop"
        content={
          <PopoverContent
            onClickChangePrice={() => {
              setVisible(false);
              setIsChangePriceModalShown(true);
            }}
            onClickEditTags={() => {
              setVisible(false);
              setIsEditTagsModalShown(true);
            }}
            onClickUnlist={() => {
              setVisible(false);
              setIsCancelListingModalShown(true);
            }}
          />
        }
        trigger="click"
        visible={visible}
        onVisibleChange={setVisible}
      >
        <EllipsisShadowButton onClick={emptyFunction} />
      </Popover>
    </>
  );
}
