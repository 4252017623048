import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ExploreControlBar from "components/pages/explore/ExploreControlBar";
import ExploreGrid from "components/pages/explore/ExploreGrid";
import Header2 from "components/text/Header2";
import Subheader from "components/text/Subheader";
import { ExploreContextProvider } from "context/ExploreContext";
import styles from "css/pages/explore/ExplorePage.module.css";
import { Suspense } from "react";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";

export default function ExplorePage(): JSX.Element {
  return (
    <ExploreContextProvider>
      <PageWithHeaderAndFooter>
        <ResponsivePageBody>
          <div className={styles.body}>
            <Header2>Explore amazing 1/1 art</Header2>
            <Subheader
              className={styles.subheader}
              colorClass={ColorClass.Secondary}
            >
              Collections are coming soon!
            </Subheader>
            <div className={styles.controlBar}>
              <ExploreControlBar />
            </div>
            <div className={styles.exploreGrid}>
              <Suspense
                fallback={
                  <LoadingSpinner colorValue={ColorValue.BrightPurple} />
                }
              >
                <ExploreGrid />
              </Suspense>
            </div>
          </div>
        </ResponsivePageBody>
      </PageWithHeaderAndFooter>
    </ExploreContextProvider>
  );
}
