import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "css/containers/ResponsivePageBody.module.css";
import joinClasses from "utils/joinClasses";

type Props = {
  children: any;
  className?: string;
};

export default function ResponsivePageBody({
  children,
  className,
}: Props): JSX.Element {
  return (
    <ResponsiveContainer className={joinClasses(styles.container, className)}>
      {children}
    </ResponsiveContainer>
  );
}
