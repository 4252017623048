/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ExploreAvailability = "All" | "Available" | "Sold" | "%future added value";
export type ExploreMarket = "Primary" | "Secondary" | "%future added value";
export type ExploreSortOrder = "HighestPrice" | "LowestPrice" | "Newest" | "Oldest" | "%future added value";
export type MetadataAccountsForExploreInput = {
    availability: ExploreAvailability;
    highPriceLamports?: unknown | null | undefined;
    lowPriceLamports?: unknown | null | undefined;
    market: Array<ExploreMarket>;
    sortOrder: ExploreSortOrder;
};
export type ExploreGridQueryVariables = {
    input: MetadataAccountsForExploreInput;
};
export type ExploreGridQueryResponse = {
    readonly metadataAccountsForExplore: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"ListingCardForMetadata_MetadataAccount">;
    }>;
};
export type ExploreGridQuery = {
    readonly response: ExploreGridQueryResponse;
    readonly variables: ExploreGridQueryVariables;
};



/*
query ExploreGridQuery(
  $input: MetadataAccountsForExploreInput!
) {
  metadataAccountsForExplore(input: $input) {
    id
    ...ListingCardForMetadata_MetadataAccount
  }
}

fragment ArtistPillButtonForUser_User on User {
  id
  username
  ProfilePhoto {
    id
    photoUrl
  }
}

fragment ListingCardForMetadata_MetadataAccount on MetadataAccount {
  id
  mint
  data {
    name
  }
  offchainData {
    image
  }
  nft {
    creatorId
    Creator {
      username
      ...ArtistPillButtonForUser_User
    }
  }
  ...NftOtherInfo_MetadataAccount
}

fragment NftOtherInfo_MetadataAccount on MetadataAccount {
  id
  nft {
    id
    isOffPlatform
    price
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExploreGridQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MetadataAccount",
        "kind": "LinkedField",
        "name": "metadataAccountsForExplore",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ListingCardForMetadata_MetadataAccount"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExploreGridQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MetadataAccount",
        "kind": "LinkedField",
        "name": "metadataAccountsForExplore",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mint",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MetadataAccountData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MetadataOffchain",
            "kind": "LinkedField",
            "name": "offchainData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NftExpress",
            "kind": "LinkedField",
            "name": "nft",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "Creator",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Photo",
                    "kind": "LinkedField",
                    "name": "ProfilePhoto",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photoUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOffPlatform",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "edaf0a65f8f694483854293d0fffa947",
    "id": null,
    "metadata": {},
    "name": "ExploreGridQuery",
    "operationKind": "query",
    "text": "query ExploreGridQuery(\n  $input: MetadataAccountsForExploreInput!\n) {\n  metadataAccountsForExplore(input: $input) {\n    id\n    ...ListingCardForMetadata_MetadataAccount\n  }\n}\n\nfragment ArtistPillButtonForUser_User on User {\n  id\n  username\n  ProfilePhoto {\n    id\n    photoUrl\n  }\n}\n\nfragment ListingCardForMetadata_MetadataAccount on MetadataAccount {\n  id\n  mint\n  data {\n    name\n  }\n  offchainData {\n    image\n  }\n  nft {\n    creatorId\n    Creator {\n      username\n      ...ArtistPillButtonForUser_User\n    }\n  }\n  ...NftOtherInfo_MetadataAccount\n}\n\nfragment NftOtherInfo_MetadataAccount on MetadataAccount {\n  id\n  nft {\n    id\n    isOffPlatform\n    price\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = '232f212065027dbe53bab4b54909b1a2';
export default node;
