import styles from "css/header/Header.module.css";
import HeaderDesktop from "components/header/HeaderDesktop";
import HeaderMobile from "components/header/HeaderMobile";
import HeaderTheme from "types/enums/HeaderTheme";

type Props = {
  headerTheme?: HeaderTheme;
};

export default function Header({
  headerTheme = HeaderTheme.Dark,
}: Props): JSX.Element {
  return (
    <>
      <div className={styles.mobileContainer}>
        <HeaderMobile headerTheme={headerTheme} />
      </div>
      <div className={styles.desktopContainer}>
        <HeaderDesktop headerTheme={headerTheme} />
      </div>
    </>
  );
}
