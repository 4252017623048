/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ChangePriceModal_MetadataAccount = {
    readonly id: string;
    readonly mint: unknown;
    readonly nft: {
        readonly creatorId: string;
    };
    readonly " $refType": "ChangePriceModal_MetadataAccount";
};
export type ChangePriceModal_MetadataAccount$data = ChangePriceModal_MetadataAccount;
export type ChangePriceModal_MetadataAccount$key = {
    readonly " $data"?: ChangePriceModal_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ChangePriceModal_MetadataAccount">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangePriceModal_MetadataAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NftExpress",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
(node as any).hash = '5d5dda26b6778e9b95c3487f8fb2ba35';
export default node;
