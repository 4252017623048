import PlainButton from "components/buttons/PlainButton";
import Copy20Icon from "components/icons/Copy20Icon";
import NavLink from "components/text/NavLink";
import { message } from "components/toast/messages";
import styles from "css/buttons/WalletAndAddressButton.module.css";
import useSolanaContext from "hooks/useSolanaContext";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import shortenAddress from "utils/shortenAddress";

export default function WalletAndAddressButton(): JSX.Element {
  const { anchorWallet } = useSolanaContext();
  const address = anchorWallet!.publicKey.toString();

  return (
    <PlainButton
      className={styles.button}
      onClick={() => {
        // See https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
        navigator.clipboard.writeText(address);
        message({ content: "Copied to clipboard" });
      }}
      type="button"
    >
      <img className={styles.icon} src={anchorWallet?.wallet.icon} />
      <NavLink colorClass={ColorClass.Primary}>
        {shortenAddress(address)}
      </NavLink>
      <Copy20Icon colorValue={ColorValue.Secondary} />
    </PlainButton>
  );
}
