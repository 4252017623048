import "@pathofdev/react-tag-input/build/index.css";
import "antd/dist/antd.css";
import "css/global/Global.css";
import "css/global/GlobalAntd.css";
import "css/global/ReactTagInput.css";

// Colors
import "css/global/colors/ColorVariables.css";
import "css/global/colors/BackgroundColorClasses.css";
import "css/global/colors/ColorClasses.css";

// Fonts
import "css/global/fonts/TanNimbus.css";
import "css/global/fonts/FontClasses.css";
import "css/global/fonts/FontVariables.css";

// Shadows
import "css/global/shadows/ShadowVariables.css";

import Routes from "routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { ExchangeRatesContextProvider } from "context/ExchangeRatesContext";
import { SolanaContextProvider } from "context/SolanaContext";
import { RelayEnvironmentProvider } from "react-relay";
import RelayEnvironment from "utils/relay/RelayEnvironment";
import { Suspense } from "react";
import "utils/firebase/firebaseApp";
import { UserContextProvider } from "context/UserContext";

function App() {
  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <UserContextProvider>
        <SolanaContextProvider>
          <BrowserRouter>
            <ExchangeRatesContextProvider>
              <Suspense fallback={<div />}>
                <Routes />
              </Suspense>
            </ExchangeRatesContextProvider>
          </BrowserRouter>
        </SolanaContextProvider>
      </UserContextProvider>
    </RelayEnvironmentProvider>
  );
}

export default App;
