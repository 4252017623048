import { web3 } from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";
import {
  AUCTION_HOUSE_AUTHORITY,
  AUCTION_HOUSE_FORMFN,
  AUCTION_HOUSE_TREASURY_MINT,
} from "constants/SolanaConstants";
import AuctionHouseProgram from "types/AuctionHouseProgram";
import logIfNotProd from "utils/logIfNotProd";
import getTradeState from "utils/solana/auction-house/getTradeState";
import findAuctionHouseBuyerEscrow from "utils/solana/pdas/findAuctionHouseBuyerEscrow";
import findAuctionHouseFeeAccount from "utils/solana/pdas/findAuctionHouseFeeAccount";
import findTokenMetadata from "utils/solana/pdas/findTokenMetadata";

export default async function auctionHouseBuy(
  program: AuctionHouseProgram,
  walletBuyer: PublicKey,
  tokenAccount: PublicKey,
  tokenMint: PublicKey,
  priceInLamports: number
) {
  const [tradeState, tradeBump, buyPriceAdjusted] = await getTradeState(
    walletBuyer,
    tokenAccount,
    tokenMint,
    priceInLamports
  );
  const [escrowPaymentAccount, escrowBump] = await findAuctionHouseBuyerEscrow(
    AUCTION_HOUSE_FORMFN,
    walletBuyer
  );
  const [feeAccount] = await findAuctionHouseFeeAccount(AUCTION_HOUSE_FORMFN);
  const [metadata] = await findTokenMetadata(tokenMint);

  logIfNotProd("calling program.rpc.buy...");
  const txid = await program.rpc.buy(
    tradeBump,
    escrowBump,
    buyPriceAdjusted,
    new BN(1),
    {
      accounts: {
        wallet: walletBuyer,
        paymentAccount: walletBuyer,
        transferAuthority: web3.SystemProgram.programId,
        metadata,
        tokenAccount,
        escrowPaymentAccount,
        treasuryMint: AUCTION_HOUSE_TREASURY_MINT,
        authority: AUCTION_HOUSE_AUTHORITY,
        auctionHouse: AUCTION_HOUSE_FORMFN,
        auctionHouseFeeAccount: feeAccount,
        buyerTradeState: tradeState,
        tokenMint,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: web3.SystemProgram.programId,
        rent: web3.SYSVAR_RENT_PUBKEY,
      },
      signers: [],
    }
  );
  logIfNotProd("finished calling program.rpc.buy!");

  return txid;
}
