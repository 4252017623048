import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import Header3 from "components/text/Header3";

type Props = {
  children: any;
};

export default function MessagePage({ children }: Props): JSX.Element {
  return (
    <PageWithHeaderAndFooter>
      <ResponsivePageBody>
        <Header3 textAlign="center">{children}</Header3>
      </ResponsivePageBody>
    </PageWithHeaderAndFooter>
  );
}
