import graphql from "babel-plugin-relay/macro";
import NftLabelAndContent from "components/pages/common/nft/NftLabelAndContent";
import { NftPrice_MetadataAccount$key } from "components/pages/common/nft/__generated__/NftPrice_MetadataAccount.graphql";
import Price from "components/text/Price";
import SOL_SYMBOL from "constants/SolSymbol";
import { useFragment } from "react-relay";
import ColorClass from "types/enums/ColorClass";
import formatLamports from "utils/formatLamports";

const fragment = graphql`
  fragment NftPrice_MetadataAccount on MetadataAccount {
    id

    nft {
      id
      price
      status
    }
  }
`;

type Props = {
  metadataAccount: NftPrice_MetadataAccount$key;
};

export default function NftPrice({ metadataAccount }: Props): JSX.Element {
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const { price, status } = metadataAccountData.nft;

  if (price == null || status == null) {
    return (
      <NftLabelAndContent label="Reserve Price">
        <Price colorClass={ColorClass.Primary}>---</Price>
      </NftLabelAndContent>
    );
  }

  const priceFormatted = formatLamports(Number(price));
  const priceElem = (
    <Price colorClass={ColorClass.Primary}>
      {priceFormatted} {SOL_SYMBOL}
    </Price>
  );

  if (status === "Owned") {
    // TODO: need to account for unlisting
    return (
      <NftLabelAndContent label="Last Sold For">{priceElem}</NftLabelAndContent>
    );
  }
  if (status === "Listed") {
    return (
      <NftLabelAndContent label="Listed For">{priceElem}</NftLabelAndContent>
    );
  }
  if (status === "Auction") {
    return (
      <NftLabelAndContent label="Current Bid">{priceElem}</NftLabelAndContent>
    );
  }

  // Should not reach
  return (
    <NftLabelAndContent label="Reserve Price">
      <Price colorClass={ColorClass.Primary}>---</Price>
    </NftLabelAndContent>
  );
}
