import CongratsCard from "components/auction/CongratsCard";
import LandingPreHowGeneric from "components/pages/landing/pre/LandingPreHowGeneric";
import ARTIST_PILL_BUTTON_EXAMPLE from "constants/ArtistPillButtonExample";
import styles from "css/pages/landing/pre/LandingPreHow3.module.css";
import confetti from "canvas-confetti";
import { useEffect } from "react";

type Props = {
  isHidden: boolean;
  onPrev: () => void;
};

export default function LandingPreHow3({
  isHidden,
  onPrev,
}: Props): JSX.Element {
  useEffect(() => {
    if (!isHidden) {
      confetti({
        particleCount: 200,
        spread: 80,
      });
    }
  }, [isHidden]);

  return (
    <LandingPreHowGeneric
      description="After the auction is over, the collector who placed the winning bid will receive the NFT, which will appear in their profile under the “Collected” tab. Artists keep 90% of the final sale price."
      isHidden={isHidden}
      onPrev={onPrev}
      title="3. The art is transferred to its new home"
    >
      <div className={styles.body}>
        <CongratsCard
          artistPillButton={ARTIST_PILL_BUTTON_EXAMPLE}
          collector="cococo"
          // TODO: specify width
          imageSrc="images/landing/strawberries.png"
          price={1.2}
          title="Strawberry Afternoon"
        />
      </div>
    </LandingPreHowGeneric>
  );
}
