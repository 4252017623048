import styles from "css/input/InputWithLabel.module.css";

type Props = {
  input: JSX.Element;
  label: JSX.Element;
};

export default function InputWithLabel({ input, label }: Props): JSX.Element {
  return (
    <div className={styles.container}>
      {label}
      {input}
    </div>
  );
}
