import ResponsivePageBody from "components/containers/ResponsivePageBody";
import Header2 from "components/text/Header2";
import useCreateContext from "hooks/useCreateContext";
import ColorClass from "types/enums/ColorClass";
import CreateStep from "types/enums/CreateStep";
import { Maybe } from "types/UtilityTypes";
import styles from "css/pages/create/CreateListed.module.css";
import CreateListingPreview from "components/pages/create/CreateListingPreview";
import Body1 from "components/text/Body1";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";

export default function CreatedListed(): Maybe<JSX.Element> {
  const { mint, step } = useCreateContext();

  if (step !== CreateStep.Listed) {
    return null;
  }

  return (
    <ResponsivePageBody>
      <div className={styles.container}>
        <Header2 colorClass={ColorClass.Primary} textAlign="center">
          Your NFT was minted successfully!
        </Header2>
        <div className={styles.preview}>
          <CreateListingPreview />
        </div>
        <Body1
          className={styles.description}
          colorClass={ColorClass.Secondary}
          textAlign="center"
        >
          Woohoo, your NFT now lives on the Solana blockchain! You can now list
          it for auction, or just keep it and admire it.
        </Body1>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.listButton}
          fontClass={FontClass.NavLink}
          href={`/profile/${mint}`}
          target="_self"
          type="link_external"
        >
          List it for auction
        </ButtonWithText>
        <TextButton
          buttonTheme={TextButtonTheme.PurpleGradient}
          className={styles.anotherTimeButton}
          fontClass={FontClass.Body1Medium}
          href="/profile"
          target="_self"
          type="link_external"
        >
          Maybe another time
        </TextButton>
      </div>
    </ResponsivePageBody>
  );
}
