import { Route, Routes as RoutesImport } from "react-router-dom";
import LandingPrePage from "components/pages/landing/pre/LandingPrePage";
import LandingProdPage from "components/pages/landing/prod/LandingPage";
import TestingPage from "components/pages/testing/TestingPage";
import ProfilePage from "components/pages/profile/ProfilePage";
import EditProfilePage from "components/pages/profile/edit/EditProfilePage";
import CreatePage from "components/pages/create/CreatePage";
import NftPage from "components/pages/common/nft/NftPage";
import ProfilePageDynamic from "components/pages/profile/ProfilePageDynamic";
import ActivityPage from "components/pages/activity/ActivityPage";
import ExplorePage from "components/pages/explore/ExplorePage";

// TODO: figure out how to set page titles
export default function Routes(): JSX.Element {
  return (
    <RoutesImport>
      <Route path="/" element={<LandingPrePage />} />
      <Route path="/activity" element={<ActivityPage />} />
      <Route path="/create" element={<CreatePage />} />
      <Route path="/explore" element={<ExplorePage />} />
      <Route path="/pre" element={<LandingPrePage />} />
      <Route path="/prod" element={<LandingProdPage />} />

      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/profile/edit" element={<EditProfilePage />} />
      <Route path="/profile/:mint" element={<NftPage />} />

      <Route path="/@/:mint" element={<NftPage />} />
      <Route path="/@:username" element={<ProfilePageDynamic />} />
      <Route path="/@:username/:mint" element={<NftPage />} />

      <Route path="/test" element={<TestingPage />} />
    </RoutesImport>
  );
}
