import styles from "css/pages/common/nft/NftTransactions.module.css";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import NftTransaction from "components/pages/common/nft/NftTransaction";
import intersperseFn from "utils/intersperseFn";
import { NftTransactions_NftTransactionExpress$key } from "components/pages/common/nft/__generated__/NftTransactions_NftTransactionExpress.graphql";

const fragment = graphql`
  fragment NftTransactions_NftTransactionExpress on NftTransactionExpress
  @relay(plural: true) {
    ...NftTransaction_NftTransactionExpress
  }
`;

type Props = {
  nftTransactions: NftTransactions_NftTransactionExpress$key;
};

export default function NftTransactions({
  nftTransactions,
}: Props): JSX.Element {
  const nftTransactionsData = useFragment(fragment, nftTransactions);

  const transactionElems = nftTransactionsData.map((transaction, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <NftTransaction key={index} nftTransaction={transaction} />
  ));

  return (
    <div className={styles.transactions}>
      {intersperseFn(transactionElems, (index) => (
        <div key={`separator${index}`} className={styles.separator} />
      ))}
    </div>
  );
}
