import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";
import {
  AUCTION_HOUSE_AUTHORITY,
  AUCTION_HOUSE_FORMFN,
} from "constants/SolanaConstants";
import AuctionHouseProgram from "types/AuctionHouseProgram";
import logIfNotProd from "utils/logIfNotProd";
import getTradeState from "utils/solana/auction-house/getTradeState";
import findAuctionHouseFeeAccount from "utils/solana/pdas/findAuctionHouseFeeAccount";

// TODO: this is currently broken... for now, wait for this issue to be resolved
// https://github.com/metaplex-foundation/metaplex/issues/1072
export default async function auctionHouseCancel(
  program: AuctionHouseProgram,
  walletSeller: PublicKey,
  tokenAccount: PublicKey,
  tokenMint: PublicKey,
  priceInLamports: number
) {
  const [tradeState, _tradeBump, buyPriceAdjusted] = await getTradeState(
    walletSeller,
    tokenAccount,
    tokenMint,
    priceInLamports
  );
  const [feeAccount] = await findAuctionHouseFeeAccount(AUCTION_HOUSE_FORMFN);

  logIfNotProd("calling program.rpc.cancel...");
  const txid = await program.rpc.cancel(buyPriceAdjusted, new BN(1), {
    accounts: {
      wallet: walletSeller,
      tokenAccount,
      tokenMint,
      authority: AUCTION_HOUSE_AUTHORITY,
      auctionHouse: AUCTION_HOUSE_FORMFN,
      auctionHouseFeeAccount: feeAccount,
      tradeState,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    signers: [],
  });
  logIfNotProd("finished calling program.rpc.cancel!");

  return txid;
}
