import PlainButton from "components/buttons/PlainButton";
import RadioButton from "components/buttons/RadioButton";
import NavLink from "components/text/NavLink";
import styles from "css/buttons/RadioButtonWithLabel.module.css";
import ColorClass from "types/enums/ColorClass";

type Props = {
  isActive: boolean;
  label: string;
  onClick: () => void;
};

export default function RadioButtonWithLabel({
  isActive,
  label,
  onClick,
}: Props): JSX.Element {
  return (
    <PlainButton className={styles.container} onClick={onClick}>
      <RadioButton isActive={isActive} />
      <NavLink colorClass={ColorClass.Primary}>{label}</NavLink>
    </PlainButton>
  );
}
