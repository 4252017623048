/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfilePageForUser_User = {
    readonly id: string;
    readonly bio: string | null;
    readonly discordHandle: string | null;
    readonly instagramName: string | null;
    readonly twitterName: string | null;
    readonly username: string;
    readonly websiteUrl: string | null;
    readonly CoverPhoto: {
        readonly id: unknown;
        readonly photoUrl: string;
    } | null;
    readonly ProfilePhoto: {
        readonly id: unknown;
        readonly photoUrl: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"ProfileNfts_User">;
    readonly " $refType": "ProfilePageForUser_User";
};
export type ProfilePageForUser_User$data = ProfilePageForUser_User;
export type ProfilePageForUser_User$key = {
    readonly " $data"?: ProfilePageForUser_User$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfilePageForUser_User">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "photoUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfilePageForUser_User",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discordHandle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instagramName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "twitterName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "websiteUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Photo",
      "kind": "LinkedField",
      "name": "CoverPhoto",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Photo",
      "kind": "LinkedField",
      "name": "ProfilePhoto",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileNfts_User"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = '836e521ebf7b12ea303e3b921fb43ab8';
export default node;
