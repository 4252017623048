import { PublicKey } from "@solana/web3.js";
import graphql from "babel-plugin-relay/macro";
import ButtonWithText from "components/buttons/ButtonWithText";
import GenericModal from "components/modal/GenericModal";
import { CancelListingModalMutation } from "components/modal/__generated__/CancelListingModalMutation.graphql";
import { CancelListingModal_MetadataAccount$key } from "components/modal/__generated__/CancelListingModal_MetadataAccount.graphql";
import Body1 from "components/text/Body1";
import { notify } from "components/toast/notifications";
import styles from "css/modal/CancelListingModal.module.css";
import useSolanaContext from "hooks/useSolanaContext";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import invariant from "tiny-invariant";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import logIfNotProd from "utils/logIfNotProd";
import addTransaction from "utils/relay/updaters/addNftTransaction";
import updateNftRecord from "utils/relay/updaters/updateNftRecord";
import auctionHouseCancel from "utils/solana/auction-house/auctionHouseCancel";
import findAta from "utils/solana/pdas/findAta";

const mutation = graphql`
  mutation CancelListingModalMutation(
    $creator: String!
    $lister: String!
    $mint: String!
    $txid: String!
  ) {
    update_Nft_by_pk(
      pk_columns: { id: $mint }
      _set: { priceInLamports: null, status: Owned }
    ) {
      id
      creatorId
      mint
      priceInLamports
      status
    }

    insertNftTransaction(
      input: {
        creatorId: $creator
        fromUserId: $lister
        toUserId: $lister
        mint: $mint
        price: null
        txid: $txid
        type: ListingCancelled
      }
    ) {
      ...NftTransaction_NftTransactionExpress
    }
  }
`;

const fragment = graphql`
  fragment CancelListingModal_MetadataAccount on MetadataAccount {
    id
    mint

    nft {
      creatorId
      price
    }
  }
`;

type Props = {
  isShown: boolean;
  metadataAccount: CancelListingModal_MetadataAccount$key;
  onHide: () => void;
};

// TODO: prevent cancellation if there are already bids
export default function CancelListingModal({
  isShown,
  metadataAccount,
  onHide,
}: Props): JSX.Element {
  const [commit] = useMutation<CancelListingModalMutation>(mutation);
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const { anchorWallet, auctionHouseProgram } = useSolanaContext();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <GenericModal isShown={isShown} onHide={onHide} title="Cancel listing">
      <div className={styles.body}>
        <Body1 colorClass={ColorClass.Secondary} textAlign="center">
          This will cancel your current listing. You will need to list the NFT
          again in order to accept bids.
        </Body1>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.submitButton}
          fontClass={FontClass.NavLink}
          showLoadingSpinner={isLoading}
          onClick={async () => {
            invariant(anchorWallet != null);
            invariant(auctionHouseProgram != null);

            setIsLoading(true);
            const mintKey = new PublicKey(metadataAccountData.mint as string);
            const [ata] = await findAta(anchorWallet.publicKey, mintKey);
            try {
              const txid = await auctionHouseCancel(
                auctionHouseProgram,
                anchorWallet.publicKey,
                ata,
                mintKey,
                Number(metadataAccountData.nft!.price!)
              );

              commit({
                variables: {
                  creator: metadataAccountData.nft.creatorId,
                  lister: anchorWallet.publicKey.toString(),
                  mint: mintKey.toString(),
                  txid,
                },
                onCompleted: () => {
                  notify({ message: "Successfully unlisted!", txid });
                  setIsLoading(false);
                  onHide();
                },
                onError: (e) => {
                  logIfNotProd("error unlisting (graphql)", e);
                  notify({
                    message: "An unexpected error occurred while unlisting",
                    type: "error",
                  });
                },
                updater: (store) => {
                  addTransaction(store, mintKey.toString());
                  updateNftRecord(store, mintKey.toString(), null, "Owned");
                },
              });
            } catch (e) {
              logIfNotProd("error unlisting (solana)", e);
              notify({
                message: "An unexpected error occurred while unlisting",
                type: "error",
              });
              setIsLoading(false);
            }
          }}
        >
          Cancel listing
        </ButtonWithText>
      </div>
    </GenericModal>
  );
}
