import graphql from "babel-plugin-relay/macro";
import ListingCardForMetadata from "components/auction/ListingCardForMetadata";
import NftGrid from "components/auction/NftGrid";
import NftGridItem from "components/auction/NftGridItem";
import ProfileTabs from "components/pages/profile/ProfileTabs";
import {
  NftsForAddressQuery,
  NftsForAddressQueryResponse,
} from "components/pages/profile/__generated__/NftsForAddressQuery.graphql";
import { Suspense, useEffect, useMemo, useState } from "react";
import {
  loadQuery,
  PreloadedQuery,
  useFragment,
  useLazyLoadQuery,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import styles from "css/pages/profile/NftsForAddress.module.css";
import ProfileTabType from "types/enums/ProfileTabType";
import { Maybe } from "types/UtilityTypes";
import useUserContext from "hooks/useUserContext";
import { NftsForAddress_User$key } from "components/pages/profile/__generated__/NftsForAddress_User.graphql";
import isOwnProfile from "utils/isOwnProfile";
import RelayEnvironment from "utils/relay/RelayEnvironment";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ColorValue from "types/enums/ColorValue";

const fragment = graphql`
  fragment NftsForAddress_User on User {
    id
  }
`;

const query = graphql`
  query NftsForAddressQuery($input: MetadataAccountsInput!) {
    metadataAccounts(input: $input) {
      id

      nft {
        creatorId
        isOffPlatform
        ownerId
      }

      ...ListingCardForMetadata_MetadataAccount
    }
  }
`;

function metadataAccountsForTab(
  data: NftsForAddressQueryResponse,
  tab: ProfileTabType,
  userId: Maybe<string>
) {
  if (tab === ProfileTabType.Created) {
    return data.metadataAccounts.filter(
      (account) =>
        account.nft.creatorId === userId && !account.nft.isOffPlatform
    );
  }

  if (tab === ProfileTabType.Collected) {
    return data.metadataAccounts.filter(
      (account) =>
        account.nft.creatorId !== userId &&
        account.nft.ownerId === userId &&
        !account.nft.isOffPlatform
    );
  }

  return data.metadataAccounts;
}

function WalletViewer({
  queryRef,
  setNumWalletViewer,
}: {
  queryRef: PreloadedQuery<NftsForAddressQuery>;
  setNumWalletViewer: (val: number) => void;
}) {
  const data = usePreloadedQuery<NftsForAddressQuery>(query, queryRef);
  useEffect(() => {
    setNumWalletViewer(data.metadataAccounts.length);
  }, [data.metadataAccounts.length, setNumWalletViewer]);

  return (
    <NftGrid>
      {data.metadataAccounts.map((metadataAccount) => (
        <NftGridItem key={metadataAccount.id}>
          <ListingCardForMetadata metadataAccount={metadataAccount} />
        </NftGridItem>
      ))}
    </NftGrid>
  );
}

type Props = {
  address: string;
  user: NftsForAddress_User$key;
};

export default function NftsForAddress({ address, user }: Props): JSX.Element {
  const userData = useFragment(fragment, user);
  const [numWalletViewer, setNumWalletViewer] = useState<Maybe<number>>(null);

  const initialQueryRef = useMemo(
    () =>
      loadQuery<NftsForAddressQuery>(RelayEnvironment, query, {
        input: { address, includeOffPlatform: true },
      }),
    [address]
  );

  const [offPlatformQueryRef, loadOffPlatformQuery] =
    useQueryLoader<NftsForAddressQuery>(query, initialQueryRef);

  useEffect(() => {
    loadOffPlatformQuery({ input: { address, includeOffPlatform: true } });
  }, [address, loadOffPlatformQuery]);

  const data = useLazyLoadQuery<NftsForAddressQuery>(query, {
    input: {
      address,
    },
  });
  const [tab, setTab] = useState<ProfileTabType>(ProfileTabType.Created);
  const { userId } = useUserContext();
  const userIdForFilter = isOwnProfile() ? userId ?? null : userData.id;
  const accounts = metadataAccountsForTab(data, tab, userIdForFilter);

  const onPlatform = (
    <NftGrid>
      {accounts.map((metadataAccount) => (
        <NftGridItem key={metadataAccount.id}>
          <ListingCardForMetadata metadataAccount={metadataAccount} />
        </NftGridItem>
      ))}
    </NftGrid>
  );

  const offPlatform = offPlatformQueryRef != null && (
    <Suspense
      fallback={<LoadingSpinner colorValue={ColorValue.BrightPurple} />}
    >
      <WalletViewer
        queryRef={offPlatformQueryRef}
        setNumWalletViewer={setNumWalletViewer}
      />
    </Suspense>
  );

  return (
    <>
      <ProfileTabs
        numCreated={
          metadataAccountsForTab(data, ProfileTabType.Created, userIdForFilter)
            .length
        }
        numCollected={
          metadataAccountsForTab(
            data,
            ProfileTabType.Collected,
            userIdForFilter
          ).length
        }
        numWalletViewer={numWalletViewer}
        tab={tab}
        setTab={setTab}
      />
      <div className={styles.gridContainer}>
        {tab === ProfileTabType.WalletViewer ? offPlatform : onPlatform}
      </div>
    </>
  );
}
