import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ActivityTransactions from "components/pages/activity/ActivityTransactions";
import Header2 from "components/text/Header2";
import styles from "css/pages/activity/ActivityPage.module.css";
import { Suspense } from "react";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";

export default function ActivityPage(): JSX.Element {
  return (
    <DisconnectedPageContainer>
      <PageWithHeaderAndFooter>
        <ResponsivePageBody>
          <div className={styles.body}>
            <Header2 colorClass={ColorClass.Primary}>Activity</Header2>
            <Suspense
              fallback={<LoadingSpinner colorValue={ColorValue.BrightPurple} />}
            >
              <ActivityTransactions />
            </Suspense>
          </div>
        </ResponsivePageBody>
      </PageWithHeaderAndFooter>
    </DisconnectedPageContainer>
  );
}
