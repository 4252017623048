/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftStatus_enum = "Auction" | "Listed" | "Owned" | "%future added value";
export type BidModalMutationVariables = {
    creator: string;
    bidder: string;
    mint: string;
    price: unknown;
    txid: string;
};
export type BidModalMutationResponse = {
    readonly update_Nft_by_pk: {
        readonly id: string;
        readonly creatorId: string;
        readonly mint: string;
        readonly priceInLamports: unknown | null;
        readonly status: NftStatus_enum | null;
    } | null;
    readonly insertNftTransaction: {
        readonly " $fragmentRefs": FragmentRefs<"NftTransaction_NftTransactionExpress">;
    };
};
export type BidModalMutation = {
    readonly response: BidModalMutationResponse;
    readonly variables: BidModalMutationVariables;
};



/*
mutation BidModalMutation(
  $creator: String!
  $bidder: String!
  $mint: String!
  $price: bigint!
  $txid: String!
) {
  update_Nft_by_pk(pk_columns: {id: $mint}, _set: {priceInLamports: $price, status: Auction}) {
    id
    creatorId
    mint
    priceInLamports
    status
  }
  insertNftTransaction(input: {creatorId: $creator, fromUserId: $bidder, toUserId: $bidder, mint: $mint, price: $price, txid: $txid, type: Bid}) {
    ...NftTransaction_NftTransactionExpress
  }
}

fragment NftTransaction_NftTransactionExpress on NftTransactionExpress {
  id
  fromAddress
  mint
  priceInLamports
  timeCreated
  toAddress
  txid
  type
  From {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
  To {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bidder"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creator"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mint"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "price"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "txid"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mint",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceInLamports",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "priceInLamports",
          "variableName": "price"
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": "Auction"
        }
      ],
      "kind": "ObjectValue",
      "name": "_set"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "mint"
        }
      ],
      "kind": "ObjectValue",
      "name": "pk_columns"
    }
  ],
  "concreteType": "Nft",
  "kind": "LinkedField",
  "name": "update_Nft_by_pk",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorId",
      "storageKey": null
    },
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "creatorId",
        "variableName": "creator"
      },
      {
        "kind": "Variable",
        "name": "fromUserId",
        "variableName": "bidder"
      },
      {
        "kind": "Variable",
        "name": "mint",
        "variableName": "mint"
      },
      {
        "kind": "Variable",
        "name": "price",
        "variableName": "price"
      },
      {
        "kind": "Variable",
        "name": "toUserId",
        "variableName": "bidder"
      },
      {
        "kind": "Variable",
        "name": "txid",
        "variableName": "txid"
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": "Bid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v10 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Photo",
    "kind": "LinkedField",
    "name": "ProfilePhoto",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BidModalMutation",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "NftTransactionExpress",
        "kind": "LinkedField",
        "name": "insertNftTransaction",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NftTransaction_NftTransactionExpress"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "BidModalMutation",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "NftTransactionExpress",
        "kind": "LinkedField",
        "name": "insertNftTransaction",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromAddress",
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "txid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "From",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "To",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0eace85ad802ece16e6b978e5db29563",
    "id": null,
    "metadata": {},
    "name": "BidModalMutation",
    "operationKind": "mutation",
    "text": "mutation BidModalMutation(\n  $creator: String!\n  $bidder: String!\n  $mint: String!\n  $price: bigint!\n  $txid: String!\n) {\n  update_Nft_by_pk(pk_columns: {id: $mint}, _set: {priceInLamports: $price, status: Auction}) {\n    id\n    creatorId\n    mint\n    priceInLamports\n    status\n  }\n  insertNftTransaction(input: {creatorId: $creator, fromUserId: $bidder, toUserId: $bidder, mint: $mint, price: $price, txid: $txid, type: Bid}) {\n    ...NftTransaction_NftTransactionExpress\n  }\n}\n\nfragment NftTransaction_NftTransactionExpress on NftTransactionExpress {\n  id\n  fromAddress\n  mint\n  priceInLamports\n  timeCreated\n  toAddress\n  txid\n  type\n  From {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n  To {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '84cbfc149692b675bb4998d97cc4b794';
export default node;
