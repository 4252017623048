import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import CreateDetails from "components/pages/create/CreateDetails";
import CreateListed from "components/pages/create/CreateListed";
import CreateMedia from "components/pages/create/CreateMedia";
import CreateMint from "components/pages/create/CreateMint";
import { CreateContextProvider } from "context/CreateContext";

export default function CreatePage(): JSX.Element {
  return (
    <DisconnectedPageContainer>
      <CreateContextProvider>
        <PageWithHeaderAndFooter>
          <CreateMedia />
          <CreateDetails />
          <CreateMint />
          <CreateListed />
        </PageWithHeaderAndFooter>
      </CreateContextProvider>
    </DisconnectedPageContainer>
  );
}
