/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActivityTransactionType = "Bid" | "Listed" | "ListingCancelled" | "Minted" | "Sold" | "%future added value";
export type ActivityTransaction_ActivityTransaction = {
    readonly id: unknown;
    readonly price: unknown | null;
    readonly timeCreated: unknown;
    readonly txid: string;
    readonly type: ActivityTransactionType;
    readonly metadataAccount: {
        readonly id: string;
        readonly mint: unknown;
        readonly data: {
            readonly name: string;
        };
        readonly offchainData: {
            readonly description: string | null;
            readonly image: string;
        };
    };
    readonly From: {
        readonly id: string;
        readonly username: string;
        readonly ProfilePhoto: {
            readonly id: unknown;
            readonly photoUrl: string;
        } | null;
    };
    readonly To: {
        readonly id: string;
        readonly username: string;
        readonly ProfilePhoto: {
            readonly id: unknown;
            readonly photoUrl: string;
        } | null;
    };
    readonly " $refType": "ActivityTransaction_ActivityTransaction";
};
export type ActivityTransaction_ActivityTransaction$data = ActivityTransaction_ActivityTransaction;
export type ActivityTransaction_ActivityTransaction$key = {
    readonly " $data"?: ActivityTransaction_ActivityTransaction$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActivityTransaction_ActivityTransaction">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Photo",
    "kind": "LinkedField",
    "name": "ProfilePhoto",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityTransaction_ActivityTransaction",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeCreated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "txid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetadataAccount",
      "kind": "LinkedField",
      "name": "metadataAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MetadataAccountData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MetadataOffchain",
          "kind": "LinkedField",
          "name": "offchainData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "From",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "To",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ActivityTransaction",
  "abstractKey": null
};
})();
(node as any).hash = 'f72428b09662d30b323a75b656f60c28';
export default node;
