import graphql from "babel-plugin-relay/macro";
import NftLabelAndContent from "components/pages/common/nft/NftLabelAndContent";
import { useFragment } from "react-relay";
import { Maybe } from "types/UtilityTypes";
import styles from "css/pages/common/nft/NftTags.module.css";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import { NftTags_MetadataAccount$key } from "components/pages/common/nft/__generated__/NftTags_MetadataAccount.graphql";

const fragment = graphql`
  fragment NftTags_MetadataAccount on MetadataAccount {
    tags
  }
`;

type Props = {
  metadataAccount: NftTags_MetadataAccount$key;
};

export default function NftTags({
  metadataAccount,
}: Props): Maybe<JSX.Element> {
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const { tags } = metadataAccountData;

  if (tags.length === 0) {
    return null;
  }

  return (
    <NftLabelAndContent className={styles.tagsContainer} label="Tags">
      <div className={styles.tags}>
        {tags.map((tag) => (
          <Body1 key={tag} colorClass={ColorClass.Primary}>
            #{tag}
          </Body1>
        ))}
      </div>
    </NftLabelAndContent>
  );
}
