import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NftActionButton_MetadataAccount$key } from "components/buttons/__generated__/NftActionButton_MetadataAccount.graphql";
import ListNftButton from "components/buttons/ListNftButton";
import useSolanaContext from "hooks/useSolanaContext";
import { Maybe } from "types/UtilityTypes";
import BidButton from "components/buttons/BidButton";
import useUserContext from "hooks/useUserContext";

const fragment = graphql`
  fragment NftActionButton_MetadataAccount on MetadataAccount {
    id

    nft {
      id
      isOffPlatform
      ownerId
      status
    }

    ...BidButton_MetadataAccount
    ...ListNftButton_MetadataAccount
  }
`;

type Props = {
  metadataAccount: NftActionButton_MetadataAccount$key;
};

export default function NftActionButton({
  metadataAccount,
}: Props): Maybe<JSX.Element> {
  const { anchorWallet } = useSolanaContext();
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const { nft } = metadataAccountData;
  const { userId } = useUserContext();

  const { ownerId, status } = nft;
  const isOwner = anchorWallet?.publicKey.toString() === ownerId;

  if (anchorWallet == null || userId == null || nft.isOffPlatform) {
    return null;
  }

  if (isOwner) {
    if (status === "Owned") {
      return <ListNftButton metadataAccount={metadataAccountData} />;
    }
  } else {
    if (status === "Listed" || status === "Auction") {
      return <BidButton metadataAccount={metadataAccountData} />;
    }
    if (status === "Owned") {
      return null;
    }
  }

  // TODO: implement rest of logic
  return null;
}
