// import TextButton from "components/buttons/TextButton";
// import ArrowRightIcon from "components/icons/ArrowRightIcon";
import LandingPreMadeForGeneric from "components/pages/landing/pre/LandingPreMadeForGeneric";
import CDN_CGI from "constants/CdnCgi";
// import ColorValue from "types/enums/ColorValue";
// import FontClass from "types/enums/FontClass";
// import TextButtonTheme from "types/enums/TextButtonTheme";

export default function LandingPreMadeForCollectors(): JSX.Element {
  const description1 =
    "We made FormFn because we wanted a thoughtfully-designed marketplace for independent creators on Solana—amazing artists deserve an amazing showcase. Other NFT marketplaces on Solana are entirely focused on collections—with FormFn, we want to make the 1/1 browsing experience amazing, and help you find the artists who will define the future of NFTs.";
  // const description2 =
  //   "We’ll open FormFn to collectors soon. If you’d like to keep in touch with us and hear the latest news on FormFn, please join our email newsletter and we will be in touch!";
  const description2 =
    "We’ll open FormFn to collectors soon—keep an eye out for updates 👀";
  const src = `${CDN_CGI}/width=1500px,f=auto/images/landing/collector-screen.jpg`;
  const title1 = "Why collect art on FormFn?";
  const title2 = "Joining FormFn";

  return (
    <LandingPreMadeForGeneric
      // ctaButton={
      //   <TextButton
      //     buttonTheme={TextButtonTheme.PurpleGradient}
      //     fontClass={FontClass.NavLink}
      //     icon={<ArrowRightIcon colorValue={ColorValue.BrightPurple} />}
      //     iconPosition="right"
      //   >
      //     Join our email newsletter
      //   </TextButton>
      // }
      description1={description1}
      description2={description2}
      src={src}
      title1={title1}
      title2={title2}
    />
  );
}
