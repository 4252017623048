import styles from "css/pages/common/nft/NftTransaction.module.css";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import Body2 from "components/text/Body2";
import formatTransactionTimestamp from "utils/dates/formTransactionTimestamp";
import Body1Medium from "components/text/Body1Medium";
import Price from "components/text/Price";
import SOL_SYMBOL from "constants/SolSymbol";
import useExchangeRatesContext from "hooks/useExchangeRatesContext";
import formatLamports from "utils/formatLamports";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import ColorValue from "types/enums/ColorValue";
import Profile20Icon from "components/icons/Profile20Icon";
import { Link } from "react-router-dom";
import {
  NftTransaction_NftTransactionExpress,
  NftTransaction_NftTransactionExpress$key,
} from "components/pages/common/nft/__generated__/NftTransaction_NftTransactionExpress.graphql";
import shortenAddress from "utils/shortenAddress";

const TX_DESCRIPTION: { [key: string]: string } = {
  Bid: "Bid by",
  Listed: "Listed by",
  ListingCancelled: "Unlisted by",
  Minted: "Minted by",
  Sold: "Auction won by",
  Transferred: "Transferred by",
};

function getUserForTx(
  nftTransactionData: NftTransaction_NftTransactionExpress
) {
  if (["Minted", "Listed"].includes(nftTransactionData.type)) {
    return {
      user: nftTransactionData.From,
      userAddress: nftTransactionData.fromAddress,
    };
  }

  // TODO: implement rest
  return {
    user: nftTransactionData.From,
    userAddress: nftTransactionData.fromAddress,
  };
}

function ProfileLink({
  user,
  userAddress,
}: {
  user: NftTransaction_NftTransactionExpress["From"];
  userAddress: string;
}) {
  const src = user?.ProfilePhoto?.photoUrl;
  const profileLinkInner = (
    <>
      {src == null || src === "" ? (
        <div className={styles.image}>
          <Profile20Icon colorValue={ColorValue.White} />
        </div>
      ) : (
        <img className={styles.image} src={src} />
      )}
      <Body1Medium colorClass={ColorClass.Primary}>
        {user == null ? shortenAddress(userAddress) : `@${user.username}`}
      </Body1Medium>
    </>
  );

  return user == null ? (
    <div className={styles.profileLink}>{profileLinkInner}</div>
  ) : (
    <Link className={styles.profileLink} to={`/@${user.username}`}>
      {profileLinkInner}
    </Link>
  );
}

function TxLink({ children, txid }: { children: any; txid: string }) {
  return (
    <a
      href={`https://explorer.solana.com/tx/${txid}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

function Description({
  nftTransactionData,
}: {
  nftTransactionData: NftTransaction_NftTransactionExpress;
}) {
  const { user, userAddress } = getUserForTx(nftTransactionData);

  if (["Sold", "Transferred"].includes(nftTransactionData.type)) {
    const txLink = (
      <TxLink txid={nftTransactionData.txid}>
        <Body1 colorClass={ColorClass.Primary}>
          {nftTransactionData.type} by
        </Body1>
      </TxLink>
    );

    const profileLinkFrom = (
      <ProfileLink
        user={nftTransactionData.From}
        userAddress={nftTransactionData.fromAddress}
      />
    );

    const profileLinkTo = (
      <ProfileLink
        user={nftTransactionData.To}
        userAddress={nftTransactionData.toAddress}
      />
    );

    return (
      <Body1 className={styles.description} colorClass={ColorClass.Primary}>
        {txLink}
        {profileLinkFrom}
        to
        {profileLinkTo}
      </Body1>
    );
  }

  const txLink = (
    <TxLink txid={nftTransactionData.txid}>
      <Body1 colorClass={ColorClass.Primary}>
        {TX_DESCRIPTION[nftTransactionData.type]}
      </Body1>
    </TxLink>
  );

  return (
    <div className={styles.description}>
      {txLink}
      <ProfileLink user={user} userAddress={userAddress} />
    </div>
  );
}

function PriceSection({
  nftTransactionData,
}: {
  nftTransactionData: NftTransaction_NftTransactionExpress;
}) {
  const { priceInLamports } = nftTransactionData;
  const { solToUsd } = useExchangeRatesContext();
  if (priceInLamports == null) {
    return null;
  }

  return (
    <div className={styles.price}>
      <Price colorClass={ColorClass.Primary}>
        {formatLamports(Number(priceInLamports))} {SOL_SYMBOL}
      </Price>
      <Body2 colorClass={ColorClass.Secondary}>
        ${solToUsd(Number(priceInLamports) / LAMPORTS_PER_SOL)} USD
      </Body2>
    </div>
  );
}

const fragment = graphql`
  fragment NftTransaction_NftTransactionExpress on NftTransactionExpress {
    id
    fromAddress
    mint
    priceInLamports
    timeCreated
    toAddress
    txid
    type

    From {
      id
      username

      ProfilePhoto {
        id
        photoUrl
      }
    }
    To {
      id
      username

      ProfilePhoto {
        id
        photoUrl
      }
    }
  }
`;

type Props = {
  nftTransaction: NftTransaction_NftTransactionExpress$key;
};

export default function NftTransaction({ nftTransaction }: Props): JSX.Element {
  const nftTransactionData = useFragment(fragment, nftTransaction);

  return (
    <div className={styles.transaction}>
      <div className={styles.left}>
        <Description nftTransactionData={nftTransactionData} />
        <Body2 colorClass={ColorClass.Secondary}>
          {formatTransactionTimestamp(nftTransactionData.timeCreated as string)}
        </Body2>
      </div>
      <PriceSection nftTransactionData={nftTransactionData} />
    </div>
  );
}
