import SIGN_AUTH_MESSAGE from "constants/SignAuthMessage";
import { MyAnchorWallet } from "context/SolanaContext";
import LocalStoragePrefix from "types/enums/LocalStoragePrefix";
import getSignature from "utils/local-storage/getSignature";
import setWithPrefix from "utils/local-storage/setWithPrefix";

export default async function signAuthMessage(anchorWallet: MyAnchorWallet) {
  const existingSignature = getSignature(anchorWallet.publicKey.toString());

  if (existingSignature != null) {
    return existingSignature;
  }

  const encodedMessage = new TextEncoder().encode(SIGN_AUTH_MESSAGE);
  const signature = await anchorWallet.signMessage(encodedMessage, "utf8");
  const hexString = Buffer.from(signature.signature).toString("hex");

  setWithPrefix(
    LocalStoragePrefix.Signature,
    anchorWallet.publicKey.toString(),
    hexString
  );

  return signature;
}
