enum ColorValue {
  BackgroundOverlay = "rgba(0, 0, 0, 0.3)",
  BrightPurple = "#8458ff",
  Dimmer = "rgba(0, 0, 0, 0.36)",
  Error = "#ff7777",
  Green = "#13bc68",
  Ghost = "#c6c6c6",
  LightBlue = "#eaffff",
  LightPink = "#ffdcdc",
  LightPurple = "#D4D2FF",
  LightPurpleHover = "#d8d6ff",
  Navy = "#1d1989",
  NavyHover = "#080561",
  Pink = "#ffc8c8",
  Primary = "#201F2A",
  Purple = "#3530bf",
  Red = "#fd4646",
  RedHover = "#dd1111",
  Secondary = "#9796A5",
  Shader = "#F5F4FE",
  Tertiary = "#DEDDE5",
  White = "#ffffff",
  Yellow = "#ffbb37",
  PhantomPurple = "#6C57EB",
}

export default ColorValue;
