import Header from "components/header/Header";
import styles from "css/header/HeaderAndCoverPhoto.module.css";
import HeaderTheme from "types/enums/HeaderTheme";
import { MaybeUndef } from "types/UtilityTypes";

type Props = {
  src: MaybeUndef<string>;
};

export default function HeaderAndCoverPhoto({ src }: Props): JSX.Element {
  // TODO: actually show a photo instead of just a div
  return (
    <div className={styles.container}>
      {src == null ? (
        <div className={styles.coverPhoto} />
      ) : (
        <img className={styles.coverPhoto} src={src} />
      )}
      <div className={styles.headerGradient} />
      <div className={styles.header}>
        <Header headerTheme={HeaderTheme.Light} />
      </div>
    </div>
  );
}
