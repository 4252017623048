import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import graphql from "babel-plugin-relay/macro";
import ListingCardForMetadata from "components/auction/ListingCardForMetadata";
import ButtonWithText from "components/buttons/ButtonWithText";
import TextButton from "components/buttons/TextButton";
import ImageCloudflare from "components/images/ImageCloudflare";
import PriceInput from "components/input/PriceInput";
import GenericModal from "components/modal/GenericModal";
import { BidModalMutation } from "components/modal/__generated__/BidModalMutation.graphql";
import {
  BidModal_MetadataAccount,
  BidModal_MetadataAccount$key,
} from "components/modal/__generated__/BidModal_MetadataAccount.graphql";
import WalletBalance from "components/solana/WalletBalance";
import ArtName from "components/text/ArtName";
import Body1 from "components/text/Body1";
import Body2 from "components/text/Body2";
import { notify } from "components/toast/notifications";
import SOL_SYMBOL from "constants/SolSymbol";
import styles from "css/modal/BidModal.module.css";
import useExchangeRatesContext from "hooks/useExchangeRatesContext";
import useSolanaContext from "hooks/useSolanaContext";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import invariant from "tiny-invariant";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";
import emptyFunction from "utils/emptyFunction";
import formatLamports from "utils/formatLamports";
import logIfNotProd from "utils/logIfNotProd";
import addTransaction from "utils/relay/updaters/addNftTransaction";
import updateNftRecord from "utils/relay/updaters/updateNftRecord";
import auctionHouseBuy from "utils/solana/auction-house/auctionHouseBuy";
import findAta from "utils/solana/pdas/findAta";

const mutation = graphql`
  mutation BidModalMutation(
    $creator: String!
    $bidder: String!
    $mint: String!
    $price: bigint!
    $txid: String!
  ) {
    update_Nft_by_pk(
      pk_columns: { id: $mint }
      _set: { priceInLamports: $price, status: Auction }
    ) {
      id
      creatorId
      mint
      priceInLamports
      status
    }

    insertNftTransaction(
      input: {
        creatorId: $creator
        fromUserId: $bidder
        toUserId: $bidder
        mint: $mint
        price: $price
        txid: $txid
        type: Bid
      }
    ) {
      ...NftTransaction_NftTransactionExpress
    }
  }
`;

// Must bid at least 0.1 SOL more than current bid
const MIN_DIFF_LAMPORTS = 1e8;

function BidSuccess({ priceInSol }: { priceInSol: string }): JSX.Element {
  return (
    <div className={styles.bidSuccess}>
      <Body1 colorClass={ColorClass.Secondary} textAlign="center">
        You successfully placed a bid on Strawberry Afternoon for {priceInSol}{" "}
        ◎. We&apos;ll notify you through email if you win the auction, or if
        someone places a higher bid on this piece.
      </Body1>
      {/* TODO: specify size */}
      <ImageCloudflare
        className={styles.bidSuccessImage}
        src="/images/bid-success.png"
      />
    </div>
  );
}

function Right({
  metadataAccountData,
  priceInSol,
  onHide,
  onSuccess,
  setPriceInSol,
}: {
  metadataAccountData: BidModal_MetadataAccount;
  priceInSol: string;
  onHide: () => void;
  onSuccess: () => void;
  setPriceInSol: (val: string) => void;
}): JSX.Element {
  const { lamportsToUsd } = useExchangeRatesContext();
  const { anchorWallet, auctionHouseProgram } = useSolanaContext();
  const [commit] = useMutation<BidModalMutation>(mutation);
  const solPriceLamports = Number(metadataAccountData.nft!.price);
  const minDiffLamports =
    metadataAccountData.nft.status === "Listed" ? 0 : MIN_DIFF_LAMPORTS;
  const solPriceSol = formatLamports(solPriceLamports + minDiffLamports);
  const usdPrice = lamportsToUsd(solPriceLamports + minDiffLamports);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.right}>
      <ArtName colorClass={ColorClass.Primary}>Bid</ArtName>
      <Body2 className={styles.description} colorClass={ColorClass.Primary}>
        You must bid at least {solPriceSol} {SOL_SYMBOL} (~${usdPrice} USD).
      </Body2>
      <div className={styles.priceInput}>
        <PriceInput
          priceInSol={priceInSol}
          setPrice={(val) => {
            if (!isLoading) {
              setPriceInSol(val);
            }
          }}
        />
      </div>
      <Body2 className={styles.noWithdraw} colorClass={ColorClass.Secondary}>
        Bids placed during an auction cannot be withdrawn.
        <TextButton
          buttonTheme={TextButtonTheme.PurpleGradient}
          display="inline"
          fontClass={FontClass.Body2}
          // TODO: implement!
          onClick={emptyFunction}
        >
          Learn more
        </TextButton>
        .
      </Body2>
      <div className={styles.walletBalance}>
        <WalletBalance />
      </div>
      <div className={styles.buttons}>
        <TextButton
          buttonTheme={TextButtonTheme.PurpleGradient}
          fontClass={FontClass.Body1Medium}
          onClick={onHide}
        >
          Nevermind
        </TextButton>
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.bidButton}
          disabled={
            Number(priceInSol) * LAMPORTS_PER_SOL - solPriceLamports <
            minDiffLamports
          }
          fontClass={FontClass.NavLink}
          showLoadingSpinner={isLoading}
          onClick={async () => {
            invariant(anchorWallet != null);
            invariant(auctionHouseProgram != null);

            setIsLoading(true);
            const mintKey = new PublicKey(metadataAccountData.mint as string);
            const [ata] = await findAta(
              // Need to use tokenAccount of seller (owner of NFT), not of the buyer
              new PublicKey(metadataAccountData.nft!.ownerId),
              mintKey
            );
            const priceInLamports = Number(priceInSol) * LAMPORTS_PER_SOL;

            try {
              const txid = await auctionHouseBuy(
                auctionHouseProgram,
                anchorWallet.publicKey,
                ata,
                mintKey,
                priceInLamports
              );

              commit({
                variables: {
                  // TODO: should check more carefully. Only NFTs created on our platform should
                  // be able to be listed tho...
                  creator: metadataAccountData.nft.creatorId,
                  bidder: anchorWallet.publicKey.toString(),
                  mint: mintKey.toString(),
                  price: priceInLamports,
                  txid,
                },
                onCompleted: () => {
                  notify({ message: "Successfully bidding!", txid });
                  setIsLoading(false);
                  onSuccess();
                },
                onError: (e) => {
                  logIfNotProd("error bidding (graphql)", e);
                  notify({
                    message: "An unexpected error occurred while bidding",
                    type: "error",
                  });
                },
                updater: (store) => {
                  addTransaction(store, mintKey.toString());
                  updateNftRecord(
                    store,
                    mintKey.toString(),
                    priceInLamports,
                    "Auction"
                  );
                },
              });
            } catch (e) {
              logIfNotProd("error bidding (solana)", e);
              notify({
                message: "An unexpected error occurred while bidding",
                type: "error",
              });
              setIsLoading(false);
            }
          }}
        >
          Submit bid
        </ButtonWithText>
      </div>
    </div>
  );
}

const fragment = graphql`
  fragment BidModal_MetadataAccount on MetadataAccount {
    id
    mint

    nft {
      creatorId
      price
      ownerId
      status

      Creator {
        id
      }
      Owner {
        id
      }
    }

    ...ListingCardForMetadata_MetadataAccount
  }
`;

type Props = {
  isShown: boolean;
  metadataAccount: BidModal_MetadataAccount$key;
  onHide: () => void;
};

export default function BidModal({
  isShown,
  metadataAccount,
  onHide,
}: Props): JSX.Element {
  const metadataAccountData = useFragment(fragment, metadataAccount);
  const [isSuccess, setIsSuccess] = useState(false);
  const [priceInSol, setPriceInSol] = useState("");

  const onHideAndSetSuccess = () => {
    setIsSuccess(false);
    setPriceInSol("");
    onHide();
  };

  const body = (
    <div className={styles.body}>
      <ListingCardForMetadata metadataAccount={metadataAccountData} />
      <Right
        metadataAccountData={metadataAccountData}
        priceInSol={priceInSol}
        onHide={onHideAndSetSuccess}
        onSuccess={() => setIsSuccess(true)}
        setPriceInSol={setPriceInSol}
      />
    </div>
  );

  return (
    <GenericModal
      isShown={isShown}
      onHide={onHideAndSetSuccess}
      title={isSuccess ? "Your bid was successful!" : "Place a bid"}
    >
      {isSuccess ? <BidSuccess priceInSol={priceInSol} /> : body}
    </GenericModal>
  );
}
