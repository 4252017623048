import * as yup from "yup";
import styles from "css/pages/profile/edit/EditProfileForm.module.css";
import graphql from "babel-plugin-relay/macro";
import { EditProfileForm_User$key } from "components/pages/profile/edit/__generated__/EditProfileForm_User.graphql";
import { useFragment, useMutation } from "react-relay";
import isValidUsername from "utils/validation/isValidUsername";
import isValidEmail from "utils/validation/isValidEmail";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputWithLabel from "components/input/InputWithLabel";
import FormTextInput from "components/input/FormTextInput";
import InputLabel from "components/input/InputLabel";
import {
  MAX_BIO_LENGTH,
  MAX_EMAIL_LENGTH,
  MAX_USERNAME_LENGTH,
} from "constants/MaxLengths";
import WEBSITE_URL from "constants/WebsiteUrl";
import { EMAIL_SUB_LABEL, USERNAME_SUB_LABEL } from "constants/InputSubLabels";
import FormUnderlinedTextInput from "components/input/FormUnderlinedTextInput";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import { useEffect, useState } from "react";
import useErrorMessage from "hooks/useErrorMessage";
import ErrorMessage from "components/text/ErrorMessage";
import ErrorMessageMsg from "types/enums/ErrorMessageMsg";
import { EditProfileFormUserMutation } from "components/pages/profile/edit/__generated__/EditProfileFormUserMutation.graphql";
import undefIfEmptyString from "utils/undefIfEmptyString";
import { useNavigate } from "react-router-dom";
import AddPhotoInput from "components/input/AddPhotoInput";
import {
  EditProfileFormPhotosMutation,
  Photo_insert_input,
} from "components/pages/profile/edit/__generated__/EditProfileFormPhotosMutation.graphql";
import { Maybe } from "types/UtilityTypes";
import uploadProfilePhoto from "utils/firebase/uploadProfilePhoto";
import { v4 } from "uuid";
import uploadCoverPhoto from "utils/firebase/uploadCoverPhoto";
import logIfNotProd from "utils/logIfNotProd";

// Three scenarios:
// 1) No change to photos—just update user
// 2) New photo—create photo, update user
// 3) Updated photo—update photo, no need to update user

const mutation = graphql`
  mutation EditProfileFormUserMutation(
    $bio: String
    $coverPhotoId: uuid
    $email: String!
    $id: String!
    $instagramName: String
    $profilePhotoId: uuid
    $twitterName: String
    $username: String!
    $websiteUrl: String
  ) {
    update_User_by_pk(
      _set: {
        bio: $bio
        coverPhotoId: $coverPhotoId
        email: $email
        instagramName: $instagramName
        profilePhotoId: $profilePhotoId
        twitterName: $twitterName
        username: $username
        websiteUrl: $websiteUrl
      }
      pk_columns: { id: $id }
    ) {
      ...EditProfileForm_User
    }
  }
`;

const createPhotosMutation = graphql`
  mutation EditProfileFormPhotosMutation($objects: [Photo_insert_input!]!) {
    insert_Photo(objects: $objects) {
      returning {
        id
        photoUrl
      }
    }
  }
`;

const schema = yup.object().shape({
  bio: yup.string().optional(),
  discordHandle: yup.string().optional(),
  email: yup
    .string()
    .required()
    .test(
      "isEmail",
      "Not a valid email",
      (val) => val != null && isValidEmail(val)
    ),
  instagramName: yup.string().optional(),
  twitterName: yup.string().optional(),
  username: yup
    .string()
    .required()
    .test(
      "isUsername",
      "Not a valid username",
      (val) => val != null && isValidUsername(val)
    ),
  websiteUrl: yup.string().optional(),
});

type FormData = {
  bio: string;
  coverPhotoFile: Maybe<File>;
  discordHandle: string;
  email: string;
  instagramName: string;
  profilePhotoFile: Maybe<File>;
  twitterName: string;
  username: string;
  websiteUrl: string;
};

const fragment = graphql`
  fragment EditProfileForm_User on User {
    id

    bio
    discordHandle
    email
    instagramName
    twitterName
    username
    websiteUrl

    CoverPhoto {
      id
      photoUrl
    }

    ProfilePhoto {
      id
      photoUrl
    }
  }
`;

type Props = {
  user: EditProfileForm_User$key;
};

export default function EditProfileForm({ user }: Props): JSX.Element {
  const userData = useFragment(fragment, user);
  const [commitUser] = useMutation<EditProfileFormUserMutation>(mutation);
  const [commitPhotos] =
    useMutation<EditProfileFormPhotosMutation>(createPhotosMutation);
  // We only want the error message to be shown after the user tries to submit
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useErrorMessage();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      bio: userData.bio ?? "",
      coverPhotoFile: null,
      discordHandle: userData.discordHandle ?? "",
      email: userData.email ?? "",
      instagramName: userData.instagramName ?? "",
      profilePhotoFile: null,
      twitterName: userData.twitterName ?? "",
      username: userData.username,
      websiteUrl: userData.websiteUrl ?? "",
    },
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!showErrors) {
      return;
    }

    setErrorMessage(isValid ? null : ErrorMessageMsg.InvalidInputs);
  }, [isValid, setErrorMessage, showErrors]);

  const onError = async () => {
    setShowErrors(true);
    if (errors != null) {
      setErrorMessage(ErrorMessageMsg.InvalidInputs);
    }
    logIfNotProd("error", errors);
  };

  const onSubmit = async (values: FormData) => {
    logIfNotProd("submit");
    setShowErrors(true);
    setIsLoading(true);
    const commitUserInner = (
      profilePhotoId: Maybe<string>,
      coverPhotoId: Maybe<string>,
      delayNavigation: boolean
    ) =>
      commitUser({
        variables: {
          id: userData.id,
          bio: undefIfEmptyString(values.bio),
          coverPhotoId,
          email: values.email,
          instagramName: undefIfEmptyString(values.instagramName),
          profilePhotoId,
          twitterName: undefIfEmptyString(values.twitterName),
          username: values.username,
          websiteUrl:
            values.websiteUrl.length === 0
              ? undefined
              : `https://${values.websiteUrl}`,
        },
        onCompleted: () => {
          setTimeout(
            () => {
              setIsLoading(false);
              navigate("/profile");
            },
            delayNavigation ? 1000 : 0
          );
        },
        onError: () => {
          setErrorMessage(ErrorMessageMsg.UnexpectedError);
          setIsLoading(false);
        },
      });

    const didProfilePhotoChange = values.profilePhotoFile != null;
    const didCoverPhotoChange = values.coverPhotoFile != null;

    const objects: Array<Photo_insert_input> = [];
    let coverPhotoId: Maybe<string> = (userData.CoverPhoto?.id as any) ?? null;
    let profilePhotoId: Maybe<string> =
      (userData.ProfilePhoto?.id as any) ?? null;

    if (values.profilePhotoFile != null) {
      profilePhotoId = v4();
      const profilePhotoUrl = await uploadProfilePhoto(
        userData.id,
        values.profilePhotoFile
      );
      objects.push({
        id: profilePhotoId,
        photoUrl: profilePhotoUrl,
        userId: userData.id,
      });
    }

    if (values.coverPhotoFile != null) {
      coverPhotoId = v4();
      const coverPhotoUrl = await uploadCoverPhoto(
        userData.id,
        values.coverPhotoFile
      );
      objects.push({
        id: coverPhotoId,
        photoUrl: coverPhotoUrl,
        userId: userData.id,
      });
    }

    if (didProfilePhotoChange || didCoverPhotoChange) {
      commitPhotos({
        variables: {
          objects,
        },
        onCompleted: () => {
          commitUserInner(profilePhotoId, coverPhotoId, false);
        },
        onError: () => {
          setIsLoading(false);
          setErrorMessage(ErrorMessageMsg.UnexpectedError);
        },
      });
    } else {
      commitUserInner(profilePhotoId, coverPhotoId, true);
    }
  };

  const socialLinks = (
    <div className={styles.socialLinks}>
      <FormUnderlinedTextInput
        className={styles.twitterInput}
        label="Twitter"
        permaPlaceholder="https://twitter.com/"
        registerResult={register("twitterName")}
      />
      <FormUnderlinedTextInput
        className={styles.instagramInput}
        label="Instagram"
        permaPlaceholder="https://instagram.com/"
        registerResult={register("instagramName")}
      />
      <FormUnderlinedTextInput
        label="Discord"
        placeholder="Include #Code"
        registerResult={register("discordHandle")}
      />
      <FormUnderlinedTextInput
        className={styles.websiteInput}
        label="Website or Portfolio"
        permaPlaceholder="https://"
        registerResult={register("websiteUrl")}
      />
    </div>
  );

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
        <InputWithLabel
          input={
            <FormTextInput
              className={styles.usernameInput}
              hasError={errors.username != null}
              maxLength={MAX_USERNAME_LENGTH}
              permaPlaceholder={WEBSITE_URL}
              registerResult={register("username")}
            />
          }
          label={
            <InputLabel
              label="Username"
              required
              subLabel={USERNAME_SUB_LABEL}
            />
          }
        />
        <InputWithLabel
          input={
            <FormTextInput
              hasError={errors.email != null}
              maxLength={MAX_EMAIL_LENGTH}
              maxLengthIndicator={false}
              registerResult={register("email")}
            />
          }
          label={
            <InputLabel label="Email" required subLabel={EMAIL_SUB_LABEL} />
          }
        />
        <InputWithLabel
          input={
            <FormTextInput
              maxLength={MAX_BIO_LENGTH}
              placeholder="Add a short bio about yourself"
              registerResult={register("bio")}
            />
          }
          label={<InputLabel label="Bio" />}
        />
        <div className={styles.photoInput}>
          <InputLabel
            label="Profile Photo"
            subLabel="Recommended size: 500x500, 5MB max size."
          />
          <AddPhotoInput
            file={watch("profilePhotoFile")}
            photoUrl={userData.ProfilePhoto?.photoUrl}
            setFile={(file) => setValue("profilePhotoFile", file)}
          />
        </div>
        <div className={styles.photoInput}>
          <InputLabel
            label="Cover Photo"
            subLabel="Recommended size: 1440x260, 5MB max size."
          />
          <AddPhotoInput
            file={watch("coverPhotoFile")}
            photoUrl={userData.CoverPhoto?.photoUrl}
            setFile={(file) => setValue("coverPhotoFile", file)}
          />
        </div>
        <InputWithLabel
          input={socialLinks}
          label={<InputLabel label="Social Links" />}
        />
        <ButtonWithText
          buttonTheme={ButtonTheme.PurpleGradient}
          className={styles.saveButton}
          fontClass={FontClass.NavLink}
          showLoadingSpinner={isLoading}
          type="submit"
        >
          Save Profile
        </ButtonWithText>
      </form>
      {errorMessage != null && (
        <ErrorMessage fontClass={FontClass.Body1}>{errorMessage}</ErrorMessage>
      )}
    </>
  );
}
