/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MetadataAccountsInput = {
    address: unknown;
    includeOffPlatform?: boolean | null | undefined;
};
export type NftsForAddressQueryVariables = {
    input: MetadataAccountsInput;
};
export type NftsForAddressQueryResponse = {
    readonly metadataAccounts: ReadonlyArray<{
        readonly id: string;
        readonly nft: {
            readonly creatorId: string;
            readonly isOffPlatform: boolean;
            readonly ownerId: string;
        };
        readonly " $fragmentRefs": FragmentRefs<"ListingCardForMetadata_MetadataAccount">;
    }>;
};
export type NftsForAddressQuery = {
    readonly response: NftsForAddressQueryResponse;
    readonly variables: NftsForAddressQueryVariables;
};



/*
query NftsForAddressQuery(
  $input: MetadataAccountsInput!
) {
  metadataAccounts(input: $input) {
    id
    nft {
      creatorId
      isOffPlatform
      ownerId
    }
    ...ListingCardForMetadata_MetadataAccount
  }
}

fragment ArtistPillButtonForUser_User on User {
  id
  username
  ProfilePhoto {
    id
    photoUrl
  }
}

fragment ListingCardForMetadata_MetadataAccount on MetadataAccount {
  id
  mint
  data {
    name
  }
  offchainData {
    image
  }
  nft {
    creatorId
    Creator {
      username
      ...ArtistPillButtonForUser_User
    }
  }
  ...NftOtherInfo_MetadataAccount
}

fragment NftOtherInfo_MetadataAccount on MetadataAccount {
  id
  nft {
    id
    isOffPlatform
    price
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOffPlatform",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownerId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NftsForAddressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MetadataAccount",
        "kind": "LinkedField",
        "name": "metadataAccounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NftExpress",
            "kind": "LinkedField",
            "name": "nft",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ListingCardForMetadata_MetadataAccount"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NftsForAddressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MetadataAccount",
        "kind": "LinkedField",
        "name": "metadataAccounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NftExpress",
            "kind": "LinkedField",
            "name": "nft",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "Creator",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Photo",
                    "kind": "LinkedField",
                    "name": "ProfilePhoto",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photoUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mint",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MetadataAccountData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MetadataOffchain",
            "kind": "LinkedField",
            "name": "offchainData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8408b9f3e2e452913f47ff98baa7cb0",
    "id": null,
    "metadata": {},
    "name": "NftsForAddressQuery",
    "operationKind": "query",
    "text": "query NftsForAddressQuery(\n  $input: MetadataAccountsInput!\n) {\n  metadataAccounts(input: $input) {\n    id\n    nft {\n      creatorId\n      isOffPlatform\n      ownerId\n    }\n    ...ListingCardForMetadata_MetadataAccount\n  }\n}\n\nfragment ArtistPillButtonForUser_User on User {\n  id\n  username\n  ProfilePhoto {\n    id\n    photoUrl\n  }\n}\n\nfragment ListingCardForMetadata_MetadataAccount on MetadataAccount {\n  id\n  mint\n  data {\n    name\n  }\n  offchainData {\n    image\n  }\n  nft {\n    creatorId\n    Creator {\n      username\n      ...ArtistPillButtonForUser_User\n    }\n  }\n  ...NftOtherInfo_MetadataAccount\n}\n\nfragment NftOtherInfo_MetadataAccount on MetadataAccount {\n  id\n  nft {\n    id\n    isOffPlatform\n    price\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = '44fb61779302a1cc3dbe97234ad5d81f';
export default node;
