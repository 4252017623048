import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAw2L0Nz3V-ahQRcGyYdF1VmUOhzr7X__4",
  authDomain: "formfn-ed6b4.firebaseapp.com",
  projectId: "formfn-ed6b4",
  storageBucket: "formfn-ed6b4.appspot.com",
  messagingSenderId: "1018018966353",
  appId: "1:1018018966353:web:9dd65ffbcc65d2e9cb4170",
  measurementId: "G-GY1YL32E9C",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
