import ListingCardGeneric from "components/auction/ListingCardGeneric";
import ListingCardImage from "components/auction/ListingCardImage";
import ViewerArtistPillButton from "components/buttons/ViewerArtistPillButton";
import ArtName from "components/text/ArtName";
import useCreateContext from "hooks/useCreateContext";
import ColorClass from "types/enums/ColorClass";
import styles from "css/pages/create/CreateListingPreview.module.css";

export default function CreateListingPreview(): JSX.Element {
  const { fileDataUrl, title } = useCreateContext();

  const image = <ListingCardImage src={fileDataUrl} />;

  const titleElem = (
    <ArtName
      className={styles.title}
      colorClass={title.length > 0 ? ColorClass.Primary : ColorClass.Ghost}
    >
      {title.length > 0 ? title : "Title will go here"}
    </ArtName>
  );

  return (
    <ListingCardGeneric
      artistPillButton={<ViewerArtistPillButton />}
      image={image}
      title={titleElem}
    />
  );
}
