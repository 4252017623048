/* eslint-disable react/jsx-no-constructed-context-values */
import { Context, createContext, useCallback, useState } from "react";

import CreateStep from "types/enums/CreateStep";
import { Maybe } from "types/UtilityTypes";
import emptyFunction from "utils/emptyFunction";

export type CreateContextData = {
  description: string;
  file: Maybe<File>;
  fileDataUrl: Maybe<string>;
  mint: Maybe<string>;
  step: CreateStep;
  title: string;

  setDescription: (val: string) => void;
  setFile: (val: File) => void;
  setMint: (val: string) => void;
  setStep: (val: CreateStep) => void;
  setTitle: (val: string) => void;
};

export const CreateContext: Context<CreateContextData> =
  createContext<CreateContextData>({
    description: "",
    file: null,
    fileDataUrl: null,
    mint: null,
    step: CreateStep.Details,
    title: "",

    setDescription: emptyFunction,
    setFile: emptyFunction,
    setMint: emptyFunction,
    setStep: emptyFunction,
    setTitle: emptyFunction,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function CreateContextProvider(props: ProviderProps): JSX.Element {
  const [description, setDescription] = useState("");
  const [file, setFile] = useState<Maybe<File>>(null);
  const [fileDataUrl, setFileDataUrl] = useState<Maybe<string>>(null);
  const [mint, setMint] = useState<Maybe<string>>(null);
  const [step, setStep] = useState<CreateStep>(CreateStep.Media);
  const [title, setTitle] = useState("");

  const setFileNew = useCallback(
    (fileInner: Maybe<File>) => {
      setFile(fileInner);
      if (fileInner != null) {
        setFileDataUrl(URL.createObjectURL(fileInner));
      } else {
        setFileDataUrl(null);
      }
    },
    [setFile]
  );

  return (
    <CreateContext.Provider
      value={{
        description,
        file,
        fileDataUrl,
        mint,
        step,
        title,
        setDescription,
        setFile: setFileNew,
        setMint,
        setStep,
        setTitle,
      }}
    >
      {props.children}
    </CreateContext.Provider>
  );
}
