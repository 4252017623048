import PlainButton from "components/buttons/PlainButton";
import ChevronLeftIcon from "components/icons/ChevronLeftIcon";
import ColorValue from "types/enums/ColorValue";
import styles from "css/buttons/CircleArrowButton.module.css";
import ChevronRightIcon from "components/icons/ChevronRightIcon";

type Props = {
  direction: "left" | "right";
  onClick: () => void;
};

export default function CircleArrowButton({
  direction,
  onClick,
}: Props): JSX.Element {
  return (
    <PlainButton className={styles.button} onClick={onClick}>
      {direction === "left" ? (
        <ChevronLeftIcon colorValue={ColorValue.White} />
      ) : (
        <ChevronRightIcon colorValue={ColorValue.White} />
      )}
    </PlainButton>
  );
}
