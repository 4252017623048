import graphql from "babel-plugin-relay/macro";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import LoadingSpinner from "components/loading/LoadingSpinner";
import MessagePage from "components/pages/common/MessagePage";
import ProfilePageForUser from "components/pages/profile/ProfilePageForUser";
import { ProfilePageDynamicQuery } from "components/pages/profile/__generated__/ProfilePageDynamicQuery.graphql";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import ColorValue from "types/enums/ColorValue";

const query = graphql`
  query ProfilePageDynamicQuery($username: String!) {
    User(where: { username: { _eq: $username } }) {
      id

      ...ProfilePageForUser_User
    }
  }
`;

function Inner(): JSX.Element {
  const params = useParams();
  const data = useLazyLoadQuery<ProfilePageDynamicQuery>(query, {
    username: params.username ?? "",
  });

  if (data.User.length === 0) {
    return <MessagePage>Profile does not exist</MessagePage>;
  }

  return <ProfilePageForUser user={data.User[0]} />;
}

export default function ProfilePageDynamic(): JSX.Element {
  return (
    <Suspense
      fallback={
        <PageWithHeaderAndFooter>
          <ResponsivePageBody>
            <LoadingSpinner colorValue={ColorValue.BrightPurple} />
          </ResponsivePageBody>
        </PageWithHeaderAndFooter>
      }
    >
      <Inner />
    </Suspense>
  );
}
