import Popover from "antd/lib/popover";
import TextButton from "components/buttons/TextButton";
import ChevronDown24Icon from "components/icons/ChevronDown24Icon";
import NavLink from "components/text/NavLink";
import styles from "css/buttons/SortButton.module.css";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import ExploreSortOrder from "types/enums/ExploreSortOrder";
import TextButtonTheme from "types/enums/TextButtonTheme";
import useExploreContext from "hooks/useExploreContext";
import { useState } from "react";

const HUMAN_READABLE = {
  [ExploreSortOrder.Newest]: "Newest",
  [ExploreSortOrder.Oldest]: "Oldest",
  [ExploreSortOrder.HighestPrice]: "Highest price",
  [ExploreSortOrder.LowestPrice]: "Lowest price",
};

function PopoverContent({
  setVisible,
}: {
  setVisible: (val: boolean) => void;
}): JSX.Element {
  const { setSortOrder } = useExploreContext();

  return (
    <div className={styles.popoverContent}>
      {Object.values(ExploreSortOrder).map((sortOrder) => (
        <TextButton
          key={sortOrder}
          buttonTheme={TextButtonTheme.Primary}
          fontClass={FontClass.NavLink}
          onClick={() => {
            setSortOrder(sortOrder);
            setVisible(false);
          }}
        >
          {sortOrder}
        </TextButton>
      ))}
    </div>
  );
}

export default function SortButton(): JSX.Element {
  const { sortOrder } = useExploreContext();
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      placement="bottomRight"
      content={<PopoverContent setVisible={setVisible} />}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
    >
      <div className={styles.button}>
        <NavLink colorClass={ColorClass.Primary}>
          Sort by: {HUMAN_READABLE[sortOrder]}
        </NavLink>
        <ChevronDown24Icon colorValue={ColorValue.Primary} />
      </div>
    </Popover>
  );
}
