import styles from "css/pages/activity/ActivityTransaction.module.css";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import Body2 from "components/text/Body2";
import formatTransactionTimestamp from "utils/dates/formTransactionTimestamp";
import formatLamports from "utils/formatLamports";
import { Link } from "react-router-dom";
import {
  ActivityTransaction_ActivityTransaction,
  ActivityTransaction_ActivityTransaction$key,
} from "components/pages/activity/__generated__/ActivityTransaction_ActivityTransaction.graphql";
import ColorValue from "types/enums/ColorValue";
import ProfileIcon from "components/icons/ProfileIcon";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";

function getUserForTx(
  activityTransactionData: ActivityTransaction_ActivityTransaction
) {
  if (["Minted", "Listed"].includes(activityTransactionData.type)) {
    return activityTransactionData.From;
  }

  // TODO: implement rest
  return activityTransactionData.From;
}

function Description({
  activityTransactionData,
}: {
  activityTransactionData: ActivityTransaction_ActivityTransaction;
}) {
  const user = getUserForTx(activityTransactionData);
  const username = (
    <TextButton
      buttonTheme={TextButtonTheme.PurpleGradient}
      display="inline"
      href={`/@${user.username}`}
      type="link_internal"
    >
      @{user.username}
    </TextButton>
  );
  const nftLink = (
    <TextButton
      buttonTheme={TextButtonTheme.Primary}
      display="inline"
      href={`/@${user.username}/${activityTransactionData.metadataAccount.mint}`}
      type="link_internal"
    >
      {activityTransactionData.metadataAccount.data.name}
    </TextButton>
  );

  switch (activityTransactionData.type) {
    case "Bid":
      return (
        <Body1 colorClass={ColorClass.Primary}>
          {username} placed a bid on {nftLink} for{" "}
          {formatLamports(Number(activityTransactionData.price))} ◎
        </Body1>
      );
    case "Listed":
      return (
        <Body1 colorClass={ColorClass.Primary}>
          {username} listed {nftLink} for{" "}
          {formatLamports(Number(activityTransactionData.price))} ◎
        </Body1>
      );
    case "ListingCancelled":
      return (
        <Body1 colorClass={ColorClass.Primary}>
          {username} unlisted {nftLink}
        </Body1>
      );
    case "Minted":
      return (
        <Body1 colorClass={ColorClass.Primary}>
          {username} minted {nftLink}
        </Body1>
      );
    // TODO: implement this
    case "Sold":
      return <Body1 colorClass={ColorClass.Primary}>TODO</Body1>;
    default:
      break;
  }

  return <div>hi</div>;
}

function NftPhoto({
  activityTransactionData,
}: {
  activityTransactionData: ActivityTransaction_ActivityTransaction;
}) {
  const user = getUserForTx(activityTransactionData);
  const link = `/@${user.username}/${activityTransactionData.metadataAccount.mint}`;

  return (
    <Link to={link}>
      <img
        className={styles.nftPhoto}
        src={activityTransactionData.metadataAccount.offchainData.image}
      />
    </Link>
  );
}

function ProfilePhoto({
  activityTransactionData,
}: {
  activityTransactionData: ActivityTransaction_ActivityTransaction;
}) {
  const user = getUserForTx(activityTransactionData);
  const src = user.ProfilePhoto?.photoUrl;
  const userLink = `/@${user.username}`;

  if (src == null || src === "") {
    return (
      <Link to={userLink}>
        <div className={styles.profilePhoto}>
          <ProfileIcon colorValue={ColorValue.White} />
        </div>
      </Link>
    );
  }

  return (
    <Link to={userLink}>
      <img className={styles.profilePhoto} src={src} />
    </Link>
  );
}

const fragment = graphql`
  fragment ActivityTransaction_ActivityTransaction on ActivityTransaction {
    id
    price
    timeCreated
    txid
    type

    metadataAccount {
      id
      mint
      data {
        name
      }
      offchainData {
        description
        image
      }
    }

    From {
      id
      username

      ProfilePhoto {
        id
        photoUrl
      }
    }
    To {
      id
      username

      ProfilePhoto {
        id
        photoUrl
      }
    }
  }
`;

type Props = {
  activityTransaction: ActivityTransaction_ActivityTransaction$key;
};

export default function ActivityTransaction({
  activityTransaction,
}: Props): JSX.Element {
  const activityTransactionData = useFragment(fragment, activityTransaction);

  return (
    <div className={styles.transaction}>
      <div className={styles.left}>
        <ProfilePhoto activityTransactionData={activityTransactionData} />
        <Body2
          className={styles.descriptionAndTimestamp}
          colorClass={ColorClass.Secondary}
        >
          <Description activityTransactionData={activityTransactionData} />
          {formatTransactionTimestamp(
            activityTransactionData.timeCreated as string
          )}
        </Body2>
      </div>
      <NftPhoto activityTransactionData={activityTransactionData} />
    </div>
  );
}
