import TextButton from "components/buttons/TextButton";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import ArrowLeft20Icon from "components/icons/ArrowLeft20Icon";
import CreateProgressBar from "components/pages/create/CreateProgressBar";
import styles from "css/pages/create/CreateBodyContainer.module.css";
import useCreateContext from "hooks/useCreateContext";
import ColorValue from "types/enums/ColorValue";
import CreateStep from "types/enums/CreateStep";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";

type Props = {
  children: any;
};

export default function CreateBodyContainer({ children }: Props): JSX.Element {
  const { step, setStep } = useCreateContext();

  const backButton =
    step === CreateStep.Media ? null : (
      <TextButton
        buttonTheme={TextButtonTheme.PurpleGradient}
        fontClass={FontClass.Body2}
        icon={<ArrowLeft20Icon colorValue={ColorValue.BrightPurple} />}
        iconPosition="left"
        onClick={() =>
          setStep(
            step === CreateStep.Details ? CreateStep.Media : CreateStep.Details
          )
        }
      >
        Back to {step === CreateStep.Details ? "media" : "details"}
      </TextButton>
    );

  return (
    <ResponsiveContainer className={styles.container}>
      <div className={styles.progressBarContainer}>
        {backButton}
        <div className={styles.progressBar}>
          <CreateProgressBar />
        </div>
        <div style={{ visibility: "hidden" }}>{backButton}</div>
      </div>
      {children}
    </ResponsiveContainer>
  );
}
