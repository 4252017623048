import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import joinClasses from "utils/joinClasses";

export type Props = {
  children:
    | number
    | string
    | JSX.Element
    | Array<JSX.Element | string | number | boolean>;
  className?: string;
  colorClass?: ColorClass;
  display?: string;
  fontClass: FontClass;
  style?: { [key: string]: any };
  textAlign?: "center" | "left" | "right";
  textTransform?: "none" | "uppercase";
};

export default function BodyText({
  children,
  className,
  colorClass,
  display,
  fontClass,
  style,
  textAlign,
  textTransform,
}: Props): JSX.Element {
  const classNameJoined = joinClasses(fontClass, className, colorClass);

  const styleToUse = {
    ...(style ?? {}),
    ...(textAlign != null ? { textAlign } : {}),
    ...(textTransform != null ? { textTransform } : {}),
    ...(display != null ? { display } : {}),
  };

  return (
    <div className={classNameJoined} style={styleToUse}>
      {children}
    </div>
  );
}
