import TextInput from "components/input/TextInput";
import Body1 from "components/text/Body1";
import useExchangeRatesContext from "hooks/useExchangeRatesContext";
import ColorClass from "types/enums/ColorClass";
import styles from "css/input/PriceInput.module.css";
import isNumber from "utils/isNumber";
import SOL_SYMBOL from "constants/SolSymbol";

type Props = {
  placeholder?: string;
  priceInSol: string;
  setPrice: (val: string) => void;
  showUsdPrice?: boolean;
};

export default function PriceInput({
  placeholder = "Enter your price (in SOL)",
  priceInSol,
  setPrice,
  showUsdPrice = true,
}: Props): JSX.Element {
  const { solToUsd } = useExchangeRatesContext();

  const usdPrice = (
    <Body1 colorClass={ColorClass.Secondary}>
      {priceInSol.length === 0
        ? "~ USD"
        : `~$${solToUsd(Number(priceInSol))} USD`}
    </Body1>
  );

  return (
    <TextInput
      buttonInner={showUsdPrice ? usdPrice : undefined}
      className={styles.priceInput}
      value={priceInSol}
      onChange={(val) => {
        if (val !== "." && !isNumber(val)) {
          return;
        }

        setPrice(val);
      }}
      permaPlaceholder={
        <Body1 colorClass={ColorClass.Primary}>{SOL_SYMBOL}</Body1>
      }
      placeholder={placeholder}
    />
  );
}
