/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftStatus_enum = "Auction" | "Listed" | "Owned" | "%future added value";
export type CreateMintMutationVariables = {
    creatorId: string;
    id: string;
    mint: string;
    txid: string;
};
export type CreateMintMutationResponse = {
    readonly insert_Nft_one: {
        readonly id: string;
        readonly creatorId: string;
        readonly mint: string;
        readonly status: NftStatus_enum | null;
    } | null;
    readonly insertNftTransaction: {
        readonly " $fragmentRefs": FragmentRefs<"NftTransaction_NftTransactionExpress">;
    };
};
export type CreateMintMutation = {
    readonly response: CreateMintMutationResponse;
    readonly variables: CreateMintMutationVariables;
};



/*
mutation CreateMintMutation(
  $creatorId: String!
  $id: String!
  $mint: String!
  $txid: String!
) {
  insert_Nft_one(object: {creatorId: $creatorId, id: $id, mint: $mint, ownerId: $creatorId, status: Owned}) {
    id
    creatorId
    mint
    status
  }
  insertNftTransaction(input: {creatorId: $creatorId, fromUserId: $creatorId, toUserId: $creatorId, mint: $mint, txid: $txid, type: Minted}) {
    ...NftTransaction_NftTransactionExpress
  }
}

fragment NftTransaction_NftTransactionExpress on NftTransactionExpress {
  id
  fromAddress
  mint
  priceInLamports
  timeCreated
  toAddress
  txid
  type
  From {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
  To {
    id
    username
    ProfilePhoto {
      id
      photoUrl
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "creatorId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mint"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "txid"
  }
],
v1 = {
  "kind": "Variable",
  "name": "creatorId",
  "variableName": "creatorId"
},
v2 = {
  "kind": "Variable",
  "name": "mint",
  "variableName": "mint"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mint",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "fields": [
        (v1/*: any*/),
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        },
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "ownerId",
          "variableName": "creatorId"
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": "Owned"
        }
      ],
      "kind": "ObjectValue",
      "name": "object"
    }
  ],
  "concreteType": "Nft",
  "kind": "LinkedField",
  "name": "insert_Nft_one",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorId",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "fields": [
      (v1/*: any*/),
      {
        "kind": "Variable",
        "name": "fromUserId",
        "variableName": "creatorId"
      },
      (v2/*: any*/),
      {
        "kind": "Variable",
        "name": "toUserId",
        "variableName": "creatorId"
      },
      {
        "kind": "Variable",
        "name": "txid",
        "variableName": "txid"
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": "Minted"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Photo",
    "kind": "LinkedField",
    "name": "ProfilePhoto",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMintMutation",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "NftTransactionExpress",
        "kind": "LinkedField",
        "name": "insertNftTransaction",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NftTransaction_NftTransactionExpress"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMintMutation",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "NftTransactionExpress",
        "kind": "LinkedField",
        "name": "insertNftTransaction",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromAddress",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priceInLamports",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "txid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "From",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "To",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a91bf1b9fe529e10ef605547dfd4d737",
    "id": null,
    "metadata": {},
    "name": "CreateMintMutation",
    "operationKind": "mutation",
    "text": "mutation CreateMintMutation(\n  $creatorId: String!\n  $id: String!\n  $mint: String!\n  $txid: String!\n) {\n  insert_Nft_one(object: {creatorId: $creatorId, id: $id, mint: $mint, ownerId: $creatorId, status: Owned}) {\n    id\n    creatorId\n    mint\n    status\n  }\n  insertNftTransaction(input: {creatorId: $creatorId, fromUserId: $creatorId, toUserId: $creatorId, mint: $mint, txid: $txid, type: Minted}) {\n    ...NftTransaction_NftTransactionExpress\n  }\n}\n\nfragment NftTransaction_NftTransactionExpress on NftTransactionExpress {\n  id\n  fromAddress\n  mint\n  priceInLamports\n  timeCreated\n  toAddress\n  txid\n  type\n  From {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n  To {\n    id\n    username\n    ProfilePhoto {\n      id\n      photoUrl\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bb7b348b1e5efdd8f053d22ed896885b';
export default node;
