import CheckboxButton from "components/buttons/CheckboxButton";
import PlainButton from "components/buttons/PlainButton";
import NavLink from "components/text/NavLink";
import styles from "css/buttons/CheckboxButtonWithLabel.module.css";
import ColorClass from "types/enums/ColorClass";

type Props = {
  isActive: boolean;
  label: string;
  onClick: () => void;
};

export default function CheckboxButtonWithLabel({
  isActive,
  label,
  onClick,
}: Props): JSX.Element {
  return (
    <PlainButton className={styles.container} onClick={onClick}>
      <CheckboxButton isActive={isActive} />
      <NavLink colorClass={ColorClass.Primary}>{label}</NavLink>
    </PlainButton>
  );
}
