import graphql from "babel-plugin-relay/macro";
import ResponsivePageBody from "components/containers/ResponsivePageBody";
import LoadingSpinner from "components/loading/LoadingSpinner";
import EditProfileForm from "components/pages/profile/edit/EditProfileForm";
import { EditProfilePageQuery } from "components/pages/profile/edit/__generated__/EditProfilePageQuery.graphql";
import Header2 from "components/text/Header2";
import useSolanaContext from "hooks/useSolanaContext";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import ColorValue from "types/enums/ColorValue";
import { Maybe } from "types/UtilityTypes";
import styles from "css/pages/profile/edit/EditProfilePage.module.css";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import DisconnectedPageContainer from "components/containers/DisconnectedPageContainer";

const query = graphql`
  query EditProfilePageQuery($id: String!) {
    User_by_pk(id: $id) {
      id
      ...EditProfileForm_User
    }
  }
`;

function EditProfileContent(): JSX.Element {
  const { anchorWallet } = useSolanaContext();
  const data = useLazyLoadQuery<EditProfilePageQuery>(query, {
    id: anchorWallet!.publicKey.toString(),
  });

  return <EditProfileForm user={data.User_by_pk!} />;
}

export default function EditProfilePage(): Maybe<JSX.Element> {
  return (
    <DisconnectedPageContainer>
      <PageWithHeaderAndFooter>
        <ResponsivePageBody>
          <Header2 textAlign="center">Edit Profile</Header2>
          <div className={styles.content}>
            <Suspense
              fallback={
                <LoadingSpinner
                  colorValue={ColorValue.BrightPurple}
                  height={32}
                  width={32}
                />
              }
            >
              <EditProfileContent />
            </Suspense>
          </div>
        </ResponsivePageBody>
      </PageWithHeaderAndFooter>
    </DisconnectedPageContainer>
  );
}
