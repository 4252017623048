/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NftStatus_enum = "Auction" | "Listed" | "Owned" | "%future added value";
export type BidModal_MetadataAccount = {
    readonly id: string;
    readonly mint: unknown;
    readonly nft: {
        readonly creatorId: string;
        readonly price: unknown | null;
        readonly ownerId: string;
        readonly status: NftStatus_enum;
        readonly Creator: {
            readonly id: string;
        } | null;
        readonly Owner: {
            readonly id: string;
        } | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"ListingCardForMetadata_MetadataAccount">;
    readonly " $refType": "BidModal_MetadataAccount";
};
export type BidModal_MetadataAccount$data = BidModal_MetadataAccount;
export type BidModal_MetadataAccount$key = {
    readonly " $data"?: BidModal_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BidModal_MetadataAccount">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BidModal_MetadataAccount",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NftExpress",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "Creator",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "Owner",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ListingCardForMetadata_MetadataAccount"
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
})();
(node as any).hash = 'a4a62c38da7b569dfc140cb44c31610a';
export default node;
