import BellIcon from "components/icons/BellIcon";
import styles from "css/buttons/ActivityButton.module.css";
import { Link } from "react-router-dom";
import ColorValue from "types/enums/ColorValue";

// TODO: make this actually do something (it should be a button)
export default function ActivityButton(): JSX.Element {
  return (
    <Link className={styles.container} to="/activity">
      <BellIcon colorValue={ColorValue.Primary} />
    </Link>
  );
}
