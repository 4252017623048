import { useWallet } from "@solana/wallet-adapter-react";
import GenericModal from "components/modal/GenericModal";
import Header3 from "components/text/Header3";
import ColorClass from "types/enums/ColorClass";
import styles from "css/modal/ConnectWalletModal.module.css";
import { Wallet, WalletName } from "@solana/wallet-adapter-wallets";
import PlainButton from "components/buttons/PlainButton";
import Price from "components/text/Price";
import joinClasses from "utils/joinClasses";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import Body1 from "components/text/Body1";
import { useState } from "react";
import { Maybe } from "types/UtilityTypes";
import FontClass from "types/enums/FontClass";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ColorValue from "types/enums/ColorValue";
import { message } from "components/toast/messages";
import useSolanaContext from "hooks/useSolanaContext";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import signAuthMessage from "utils/solana/misc/signAuthMessage";

declare global {
  interface Window {
    solana: any;
    solflare: any;
  }
}

const WALLET_LINKS: { [key: string]: string } = {
  [WalletName.Phantom]: "https://phantom.app/download",
  [WalletName.Solflare]: "https://solflare.com/#devices-available",
};

const WALLET_WINDOW_OBJECT_KEY: { [key: string]: string } = {
  [WalletName.Phantom]: "solana",
  [WalletName.Solflare]: "solflare",
};

const WALLET_STYLES: { [key: string]: string } = {
  [WalletName.Phantom]: styles.walletPhantom,
  [WalletName.Solflare]: styles.walletSolflare,
};

function WalletButton({
  onClick,
  wallet,
}: {
  onClick: () => void;
  wallet: Wallet;
}): JSX.Element {
  return (
    <PlainButton
      className={joinClasses(styles.wallet, WALLET_STYLES[wallet.name])}
      onClick={onClick}
    >
      <img className={styles.icon} src={wallet.icon} />
      <Price colorClass={ColorClass.White}>{wallet.name}</Price>
    </PlainButton>
  );
}

function Loading(): JSX.Element {
  return (
    <div>
      <Header3
        className={styles.pleaseConnect}
        colorClass={ColorClass.Primary}
        textAlign="center"
      >
        Please connect your wallet to continue.
      </Header3>
      <Body1
        className={styles.loadingDescription}
        colorClass={ColorClass.Secondary}
        textAlign="center"
      >
        FormFn uses your wallet as your account—just follow these steps to start
        using FormFn:
        <ol className={styles.ol}>
          <li>
            Press &ldquo;Connect&rdquo; on your browser&apos;s wallet extension
          </li>
          <li>Approve the signature request</li>
        </ol>
      </Body1>
      <LoadingSpinner
        className={styles.loadingSpinner}
        colorValue={ColorValue.BrightPurple}
        height={48}
        width={48}
      />
    </div>
  );
}

function NeedToInstall({
  onBack,
  wallet,
}: {
  onBack: () => void;
  wallet: Wallet;
}): JSX.Element {
  return (
    <div className={styles.needInstall}>
      <Header3
        className={styles.pleaseConnect}
        colorClass={ColorClass.Primary}
        textAlign="center"
      >
        You need to install {wallet.name}
      </Header3>
      <Body1
        className={styles.loadingDescription}
        colorClass={ColorClass.Secondary}
        textAlign="center"
      >
        You need to install {wallet.name} before using it to connect to FormFn.
        Go{" "}
        <TextButton
          buttonTheme={TextButtonTheme.Secondary}
          display="inline"
          fontClass={FontClass.Body1}
          href={WALLET_LINKS[wallet.name]}
          textDecoration="underline"
          type="link_external"
        >
          here
        </TextButton>{" "}
        to install it.
      </Body1>
      <ButtonWithText
        buttonTheme={ButtonTheme.PurpleGradient}
        className={styles.backButton}
        fontClass={FontClass.NavLink}
        onClick={onBack}
      >
        Back
      </ButtonWithText>
    </div>
  );
}

type Props = {
  isShown: boolean;
  onHide: () => void;
};

export default function ConnectWalletModal({
  isShown,
  onHide,
}: Props): JSX.Element {
  const { wallets } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [isInstallNeeded, setIsInstallNeeded] = useState<Maybe<Wallet>>(null);
  const { setAnchorWallet } = useSolanaContext();

  const onHideAndStopLoading = () => {
    setIsLoading(false);
    setIsInstallNeeded(null);
    onHide();
  };

  const signIn = (
    <>
      <Header3 colorClass={ColorClass.Primary}>
        Welcome! Sign in with your wallet.
      </Header3>
      <div className={styles.wallets}>
        {wallets.map((wallet) => (
          <WalletButton
            key={wallet.name}
            onClick={async () => {
              // @ts-ignore
              if (window[WALLET_WINDOW_OBJECT_KEY[wallet.name]] == null) {
                setIsInstallNeeded(wallet);
                return;
              }

              const timeout = setTimeout(() => setIsLoading(true), 100);

              const catchFn = () => {
                clearTimeout(timeout);
                setIsLoading(false);
              };

              const cleanupFn = () => {
                clearTimeout(timeout);
                message({ content: "Wallet connected" });
                onHideAndStopLoading();
              };

              if (wallet.name === WalletName.Phantom) {
                try {
                  const { publicKey } = await window.solana.connect();
                  const anchorWallet = {
                    disconnect: () => {
                      window.solana.disconnect();
                      // TODO: may be better UX not to remove this
                      // removeWithPrefix(
                      //   LocalStoragePrefix.Signature,
                      //   publicKey.toString()
                      // );
                    },
                    publicKey,
                    signMessage: window.solana.signMessage,
                    signTransaction: window.solana.signTransaction,
                    signAllTransactions: window.solana.signAllTransactions,
                    wallet,
                  };
                  await signAuthMessage(anchorWallet);
                  // Make sure to set this after signAuthMessage finishes, otherwise modal will disappear
                  setAnchorWallet(anchorWallet);
                  cleanupFn();
                } catch {
                  catchFn();
                }
              } else if (wallet.name === WalletName.Solflare) {
                try {
                  await window.solflare.connect();
                  const { publicKey } = window.solflare;
                  const anchorWallet = {
                    disconnect: () => {
                      // TODO: may be better UX not to remove this
                      // removeWithPrefix(
                      //   LocalStoragePrefix.Signature,
                      //   publicKey.toString()
                      // );
                      window.solflare.disconnect();
                    },
                    publicKey,
                    signMessage: window.solflare.signMessage,
                    signTransaction: window.solflare.signTransaction,
                    signAllTransactions: window.solflare.signAllTransactions,
                    wallet,
                  };
                  await signAuthMessage(anchorWallet);
                  // Make sure to set this after signAuthMessage finishes, otherwise modal will disappear
                  setAnchorWallet(anchorWallet);
                  cleanupFn();
                } catch {
                  catchFn();
                }
              }
            }}
            wallet={wallet}
          />
        ))}
      </div>
      {/* TODO: add onClick */}
      <TextButton
        buttonTheme={TextButtonTheme.Secondary}
        className={styles.firstTimeButton}
        textDecoration="underline"
      >
        First time using a wallet?
      </TextButton>
    </>
  );

  return (
    <GenericModal isShown={isShown} onHide={onHideAndStopLoading}>
      {isInstallNeeded != null ? (
        <NeedToInstall
          onBack={() => setIsInstallNeeded(null)}
          wallet={isInstallNeeded}
        />
      ) : isLoading ? (
        <Loading />
      ) : (
        signIn
      )}
    </GenericModal>
  );
}
