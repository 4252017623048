import FontClass from "types/enums/FontClass";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import styles from "css/pages/landing/pre/LandingPreFooter.module.css";
import GlobalClass from "types/enums/GlobalClass";
import FORM_LINK from "constants/FormLink";

type Props = {
  buttonTheme?: TextButtonTheme;
};

export default function LandingPreFooter({
  buttonTheme = TextButtonTheme.White,
}: Props): JSX.Element {
  const _aboutButton = (
    <TextButton
      buttonTheme={buttonTheme}
      fontClass={FontClass.NavLink}
      href="/about"
      type="link_internal"
    >
      About
    </TextButton>
  );

  const contactButton = (
    <TextButton
      buttonTheme={buttonTheme}
      fontClass={FontClass.NavLink}
      href="mailto:matt@formfn.xyz"
      type="link_external"
    >
      Contact Us
    </TextButton>
  );

  const joinButton = (
    <TextButton
      buttonTheme={buttonTheme}
      fontClass={FontClass.NavLink}
      href={FORM_LINK}
      type="link_external"
    >
      Join as an Artist
    </TextButton>
  );

  return (
    <div className={styles.containerOuter}>
      <ResponsiveContainer className={styles.container}>
        <a
          className={GlobalClass.HideText}
          href="/"
          style={{ textAlign: "center" }}
        >
          <h3 className={styles.logo}>formf(n)</h3>
        </a>
        <div className={styles.navButtons}>
          {/* {aboutButton} */}
          {contactButton}
          {joinButton}
        </div>
        {/* <div className={styles.privacyAndTerms}>
          <TextButton
            buttonTheme={buttonTheme}
            display="inline"
            fontClass={FontClass.Body2}
            href="https://app.termly.io/document/privacy-policy/7e8c729d-7453-482c-addb-685d9daba853"
            type="link_external"
          >
            Privacy
          </TextButton>
          &nbsp;
          <Body2 colorClass={colorClass} display="inline">
            •
          </Body2>
          &nbsp;
          <TextButton
            buttonTheme={buttonTheme}
            display="inline"
            fontClass={FontClass.Body2}
            href="https://app.termly.io/document/terms-of-use-for-online-marketplace/bb04b945-de1d-4688-8a93-733a43997522"
            type="link_external"
          >
            Terms
          </TextButton>
        </div> */}
      </ResponsiveContainer>
    </div>
  );
}
