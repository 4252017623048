import styles from "css/pages/create/CreateProgressBar.module.css";
import joinClasses from "utils/joinClasses";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import ColorClass from "types/enums/ColorClass";
import CreateStep from "types/enums/CreateStep";
import PlainButton from "components/buttons/PlainButton";
import TinyLabel from "components/text/TinyLabel";
import useCreateContext from "hooks/useCreateContext";

function getCreateStepIndex(step: CreateStep): number {
  return Object.values(CreateStep).indexOf(step);
}

function Circle({ isFilled }: { isFilled: boolean }): JSX.Element {
  return (
    <div
      className={joinClasses(
        styles.circle,
        isFilled
          ? BackgroundColorClass.BrightPurple
          : BackgroundColorClass.Ghost
      )}
    />
  );
}

function Item({
  isFilled,
  name,
  onClick,
}: {
  isFilled: boolean;
  name: string;
  onClick: () => void;
}): JSX.Element {
  return (
    <PlainButton onClick={onClick}>
      <div className={styles.item}>
        <TinyLabel
          colorClass={isFilled ? ColorClass.BrightPurple : ColorClass.Secondary}
          textAlign="center"
          textTransform="uppercase"
        >
          {name}
        </TinyLabel>
        <Circle isFilled={isFilled} />
      </div>
    </PlainButton>
  );
}

export default function CreateProgressBar(): JSX.Element {
  const { step: currentStep, setStep } = useCreateContext();

  const items = Object.values(CreateStep)
    .filter((step) => step !== CreateStep.Listed)
    .map((step) => (
      <Item
        isFilled={getCreateStepIndex(currentStep) >= getCreateStepIndex(step)}
        key={step}
        name={step}
        onClick={() => setStep(step)}
      />
    ));

  return (
    <div className={styles.container}>
      {items}
      <div className={styles.line} />
    </div>
  );
}
