import ImageCloudflare from "components/images/ImageCloudflare";
import Body1 from "components/text/Body1";
import Body1Medium from "components/text/Body1Medium";
import Body2 from "components/text/Body2";
import Price from "components/text/Price";
import CDN_CGI from "constants/CdnCgi";
import styles from "css/auction/BidCard.module.css";
import { Dayjs } from "dayjs";
import useExchangeRatesContext from "hooks/useExchangeRatesContext";
import ColorClass from "types/enums/ColorClass";

function BidLine({
  artistName,
  artistSrc,
  isListing,
}: {
  artistName: string;
  artistSrc: string;
  isListing: boolean;
}) {
  return (
    <Body1 className={styles.bidLine}>
      {isListing ? "Listed by " : "Bid from "}
      <ImageCloudflare
        className={styles.image}
        src={`${CDN_CGI}/width=128,f=auto/${artistSrc}`}
      />{" "}
      @<Body1Medium display="inline">{artistName}</Body1Medium>
    </Body1>
  );
}

type Props = {
  artistName: string;
  artistSrc: string;
  date: Dayjs;
  isListing?: boolean;
  price: number;
};

export default function BidCard({
  artistName,
  artistSrc,
  date,
  isListing = false,
  price,
}: Props): JSX.Element {
  const { solToUsd } = useExchangeRatesContext();

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <BidLine
          artistName={artistName}
          artistSrc={artistSrc}
          isListing={isListing}
        />
        <Body2 colorClass={ColorClass.Secondary}>
          {date.format("MMM D, YYYY [at] h:mma")}
        </Body2>
      </div>
      <div className={styles.right}>
        <Price colorClass={ColorClass.Primary}>{price} ◎</Price>
        <Body2 colorClass={ColorClass.Secondary}>${solToUsd(price)} USD</Body2>
      </div>
    </div>
  );
}
