/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditTagsModal_MetadataAccount = {
    readonly id: string;
    readonly mint: unknown;
    readonly tags: ReadonlyArray<string>;
    readonly " $refType": "EditTagsModal_MetadataAccount";
};
export type EditTagsModal_MetadataAccount$data = EditTagsModal_MetadataAccount;
export type EditTagsModal_MetadataAccount$key = {
    readonly " $data"?: EditTagsModal_MetadataAccount$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditTagsModal_MetadataAccount">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTagsModal_MetadataAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    }
  ],
  "type": "MetadataAccount",
  "abstractKey": null
};
(node as any).hash = '293119847846f4fa100d7b32bd5edd5d';
export default node;
