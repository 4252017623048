import { RecordSourceSelectorProxy } from "relay-runtime";

export default function addNftTransaction<T>(
  store: RecordSourceSelectorProxy<T>,
  mint: string
): void {
  const args = {
    input: { mint },
  };
  const records = store.getRoot().getLinkedRecords("nftTransactions", args);
  const payload = store.getRootField("insertNftTransaction");

  if (records != null && payload != null) {
    store
      .getRoot()
      .setLinkedRecords([payload, ...records], "nftTransactions", args);
  }
}
