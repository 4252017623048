import * as anchor from "@project-serum/anchor";
import { BN } from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import {
  AUCTION_HOUSE_FORMFN,
  AUCTION_HOUSE_TREASURY_MINT,
} from "constants/SolanaConstants";
import findAuctionHouseTradeState from "utils/solana/pdas/findAuctionHouseTradeState";

export default async function getTradeState(
  wallet: anchor.web3.PublicKey,
  tokenAccount: anchor.web3.PublicKey,
  tokenMint: anchor.web3.PublicKey,
  priceInLamports: number
): Promise<[PublicKey, number, BN]> {
  const priceAdjusted = new BN(priceInLamports);

  const [tradeState, tradeStateBump] = await findAuctionHouseTradeState(
    AUCTION_HOUSE_FORMFN,
    wallet,
    tokenAccount,
    AUCTION_HOUSE_TREASURY_MINT,
    tokenMint,
    // TODO: eventually, may want to support transferring more than 1 token
    new BN(1),
    priceAdjusted
  );

  return [tradeState, tradeStateBump, priceAdjusted];
}
