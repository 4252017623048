import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import graphql from "babel-plugin-relay/macro";
import ListingCardForMetadata from "components/auction/ListingCardForMetadata";
import NftGrid from "components/auction/NftGrid";
import NftGridItem from "components/auction/NftGridItem";
import { ExploreGridQuery } from "components/pages/explore/__generated__/ExploreGridQuery.graphql";
import useExploreContext from "hooks/useExploreContext";
import { useLazyLoadQuery } from "react-relay";
import ExploreMarket from "types/enums/ExploreMarket";
import filterNulls from "utils/filterNulls";

const query = graphql`
  query ExploreGridQuery($input: MetadataAccountsForExploreInput!) {
    metadataAccountsForExplore(input: $input) {
      id

      ...ListingCardForMetadata_MetadataAccount
    }
  }
`;

export default function ExploreGrid(): JSX.Element {
  const {
    availability,
    highPriceInSol,
    lowPriceInSol,
    primaryMarket,
    secondaryMarket,
    sortOrder,
  } = useExploreContext();

  const data = useLazyLoadQuery<ExploreGridQuery>(query, {
    input: {
      availability,
      highPriceLamports:
        highPriceInSol == null ? null : highPriceInSol * LAMPORTS_PER_SOL,
      lowPriceLamports:
        lowPriceInSol == null ? null : lowPriceInSol * LAMPORTS_PER_SOL,
      market: filterNulls([
        primaryMarket ? ExploreMarket.Primary : null,
        secondaryMarket ? ExploreMarket.Secondary : null,
      ]),
      sortOrder,
    },
  });

  return (
    <NftGrid>
      {data.metadataAccountsForExplore.map((metadataAccount) => (
        <NftGridItem key={metadataAccount.id}>
          <ListingCardForMetadata metadataAccount={metadataAccount} />
        </NftGridItem>
      ))}
    </NftGrid>
  );
}
