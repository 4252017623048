/* eslint-disable no-await-in-loop */
import { programs } from "@metaplex/js";
import { MintLayout, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, Keypair } from "@solana/web3.js";
import BN from "bn.js";
import { notify } from "components/toast/notifications";
import { MaybeUndef } from "types/UtilityTypes";
import logIfNotProd from "utils/logIfNotProd";
import arweaveUpload from "utils/solana/metaplex/arweaveUpload";
import sendTransactionWithWallet from "utils/solana/misc/sendTransactionWithWallet";
import findAta from "utils/solana/pdas/findAta";

type MetadataType = {
  animationUrl?: string;
  attributes?: Array<{ trait_type: string; value: string }>;
  collection?: {
    family: string;
    name: string;
  };
  creators: Array<{
    address: string;
    share: number;
  }>;
  description: string;
  externalUrl?: string;
  image: string;
  name: string;
  properties?: any;
  sellerFeeBasisPoints: number;
  symbol: string;
};

export default async function mintMasterEdition(
  connection: Connection,
  wallet: AnchorWallet,
  image: File,
  metadata: MetadataType,
  maxSupply: MaybeUndef<number>
) {
  const metadataContent = {
    animation_url: metadata.animationUrl,
    attributes: metadata.attributes,
    collection: metadata.collection,
    description: metadata.description,
    external_url: metadata.externalUrl,
    image: metadata.image,
    name: metadata.name,
    properties: {
      ...metadata.properties,
      creators: metadata.creators.map(
        ({ address, share }) =>
          // TODO: idk what verified does
          new programs.metadata.Creator({ address, share, verified: true })
      ),
    },
    seller_fee_basis_points: metadata.sellerFeeBasisPoints,
    symbol: metadata.symbol,
  };

  const metadataFile = new File(
    [JSON.stringify(metadataContent)],
    "metadata.json"
  );

  let arweaveLink = "";
  try {
    arweaveLink = await arweaveUpload(
      wallet,
      connection,
      WalletAdapterNetwork.Devnet,
      image,
      metadataFile
    );
  } catch {
    return null;
  }

  const mintRent = await connection.getMinimumBalanceForRentExemption(
    MintLayout.span
  );

  const mintAccount = Keypair.generate();
  logIfNotProd("mintAccount", mintAccount.publicKey.toString());
  const createMintTx = new programs.CreateMint(
    { feePayer: wallet.publicKey },
    {
      lamports: mintRent,
      newAccountPubkey: mintAccount.publicKey,
      owner: wallet.publicKey,
    }
  );

  const [recipientKey] = await findAta(wallet.publicKey, mintAccount.publicKey);

  const createAssociatedTokenAccountTx =
    new programs.CreateAssociatedTokenAccount(
      { feePayer: wallet.publicKey },
      {
        associatedTokenAddress: recipientKey,
        splTokenMintAddress: mintAccount.publicKey,
      }
    );

  const metadataPda = await programs.metadata.Metadata.getPDA(
    mintAccount.publicKey
  );
  logIfNotProd("metadataPda", metadataPda.toString());
  const createMetadataTx = new programs.metadata.CreateMetadata(
    { feePayer: wallet.publicKey },
    {
      metadata: metadataPda,
      metadataData: new programs.metadata.MetadataDataData({
        name: metadata.name,
        symbol: metadata.symbol,
        uri: arweaveLink,
        sellerFeeBasisPoints: metadata.sellerFeeBasisPoints,
        creators: metadataContent.properties.creators,
      }),
      updateAuthority: wallet.publicKey,
      mint: mintAccount.publicKey,
      mintAuthority: wallet.publicKey,
    }
  );

  createMetadataTx.add(
    Token.createMintToInstruction(
      TOKEN_PROGRAM_ID,
      mintAccount.publicKey,
      recipientKey,
      wallet.publicKey,
      [],
      1
    )
  );

  const editionPda = await programs.metadata.MasterEdition.getPDA(
    mintAccount.publicKey
  );
  const masterEditionTx = new programs.metadata.CreateMasterEdition(
    { feePayer: wallet.publicKey },
    {
      edition: editionPda,
      metadata: metadataPda,
      updateAuthority: wallet.publicKey,
      maxSupply: maxSupply == null ? undefined : new BN(maxSupply),
      mint: mintAccount.publicKey,
      mintAuthority: wallet.publicKey,
    }
  );

  const combinedTx = programs.Transaction.fromCombined([
    createMintTx,
    createAssociatedTokenAccountTx,
    createMetadataTx,
    masterEditionTx,
  ]);

  logIfNotProd("sending create tx");

  const mintNftTxid = await sendTransactionWithWallet({
    connection,
    wallet,
    txs: [combinedTx],
    signers: [mintAccount],
    options: {
      preflightCommitment: "finalized",
    },
  });
  if (mintNftTxid == null) {
    return null;
  }
  logIfNotProd("mint txid", mintNftTxid);
  await connection.getParsedConfirmedTransaction(mintNftTxid, "finalized");

  notify({ message: "Minted NFT", txid: mintNftTxid });

  return {
    metadataAccount: metadataPda,
    mintAccount: mintAccount.publicKey,
    mintNftTxid,
  };
}
